import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Stack,
  IconButton,
  Breakpoint,
} from '@mui/material';
import { useRef } from 'react';
import Iconify from 'src/components/iconify';

interface Props {
  onClose: () => void;
  open: boolean;
  zIndex?: number;
  title: string;
  children?: React.ReactNode;
  maxWidth?: Breakpoint;
  minHeight?: number;
}

export default function FullPageDialog({ onClose, title,zIndex, open, children, maxWidth, minHeight }: Props) {
  const handleClose = () => {
    onClose();
  };

  const dialogRootRef = useRef();

  return (
    <Dialog
      fullWidth
      sx = {{zIndex: zIndex ?? 1500}}
      maxWidth={maxWidth ?? false}
      open={open}
      onClose={() => {
        handleClose();
      }}
     
      ref={dialogRootRef as any}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span> {title}</span>
          <IconButton onClick={onClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent  sx = {{overflow: "hidden"}}>
        {children}
      </DialogContent>
    </Dialog>
  );
}
