import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem, Stack, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import FormProvider, { RHSelect } from 'src/components/hook-form';
import PreviousActionsSelect from 'src/components/previous-actions/PreviousActionsSelect';
import { setActionValidated, updateSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import { useForm } from 'react-hook-form';

export default function AiFlowExtractorActionSetup() {
  const [selectedExtractor, setSelectedExtractor] = useState<string>('');
  const [selectedDataType, setSelectedDataType] = useState<string>('Json');
  const [selectedType, setSelectedType] = useState<string>('Text');
  const [extractInput, setExtractInput] = useState<string>('');
  const dispatch = useDispatch();
  const [selectedSource, setSelectedSource] = useState<string>('Text');
  const [fileTypes, setFileTypes] = useState([
    { label: 'Html', value: 'Html' },
    { label: 'Word', value: 'Word' },
    { label: 'Pdf', value: 'Pdf' },
    { label: 'Json', value: 'Json' },
    { label: 'Excel', value: 'Xlsx' },
  ]);

  const defaultValues = {
    selectedSource: '',
  };

  const AiFlowExtractorSchema: any = Yup.object().shape({
    selectedSource: Yup.string().required('Required'),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowExtractorSchema),
    mode: 'all',
    defaultValues,
  });

  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);

  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);

  const [selectedExtractOptions, setSelectedExtractOptions] = useState<any[]>([]);

  const [jsonExtractionOptions, setJsonExtractionOptions] = useState<any[]>([
    { label: 'JSON Property Value', value: 'json_property_value' },
  ]);

  const [contentExtractionOptions, setContentExtractionOptions] = useState<any[]>([
    { label: 'Links from HTML', value: 'links_html' },
    { label: 'Images from HTML', value: 'images_html' },
    { label: 'Images from PDF', value: 'images_pdf' },
  ]);

  const [textExtractionOptions, setTextExtractionOptions] = useState<any[]>([
    { label: 'Extract Pattern', value: 'extract_pattern' },
    { label: 'Extract URL', value: 'extract_url' },
    { label: 'Extract Email', value: 'extract_email' },
    { label: 'Extract Number', value: 'extract_number' },
  ]);

  const setSelectedExtractOptionsByType = useCallback(
    (type: string) => {
      if (type === 'Text') {
        setSelectedExtractOptions(textExtractionOptions);
      } else if (type === 'Content') {
        setSelectedExtractOptions(contentExtractionOptions);
      } else if (type === 'Json') {
        setSelectedExtractOptions(jsonExtractionOptions);
      }
    },
    [contentExtractionOptions, textExtractionOptions, jsonExtractionOptions]
  );

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.inputData?.actionInputData?.functionalInputData?.extractorInput) {
        setSelectedSource(
          selectedItem.inputData?.actionInputData?.functionalInputData?.extractorInput
            ?.source as string
        );
        setExtractInput(
          selectedItem.inputData?.actionInputData?.functionalInputData?.extractorInput
            ?.extractInput as string
        );
        setSelectedType(
          selectedItem.inputData?.actionInputData?.functionalInputData?.extractorInput
            ?.type as string
        );
        setSelectedExtractOptionsByType(
          selectedItem.inputData?.actionInputData?.functionalInputData?.extractorInput
            ?.type as string
        );
        methods.setValue(
          'selectedSource',
          selectedItem.inputData?.actionInputData?.functionalInputData?.extractorInput
            ?.source as string
        );
        methods.trigger();
        setSelectedExtractor(
          selectedItem.inputData?.actionInputData?.functionalInputData?.extractorInput
            ?.extract as string
        );
      }
    }
  }, [selectedItem, methods, setSelectedExtractOptionsByType]);

  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3} sx={{ mt: 2, mx: 1 }}>
          <PreviousActionsSelect
            selectedSourceInput={selectedSource}
            onPreviousActionsSelect={(source: string) => {
              setSelectedSource(source);
              setValue('selectedSource', source);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      extractorInput: {
                        source,
                        extractType: selectedExtractor,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
          />
          <RHSelect
            key="type"
            name="type"
            size="small"
            label="Select Type"
            zIndex={3400}
            value={selectedType}
            sx={{ px: 1 }}
            onChange={(event) => {
              setSelectedType(event.target.value);
              setSelectedExtractOptionsByType(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      extractorInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.extractorInput,
                        type: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="Content">
              <span>Content</span>
            </MenuItem>
            <MenuItem value="Text">
              <span>Text</span>
            </MenuItem>
            <MenuItem value="Json">
              <span>Json</span>
            </MenuItem>
          </RHSelect>
          {/* {selectedType === 'Data' && (
            <RHSelect
              key="selectDataType"
              name="selectDataType"
              size="small"
              label="Select Data Type"
              zIndex={3400}
              value={selectedDataType}
              sx={{ px: 1 }}
              onChange={(event) => {
                setSelectedDataType(event.target.value);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      functionalInputData: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                        extractorInput: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData
                            ?.extractorInput,
                          dataType: event.target.value,
                        },
                      },
                    },
                  },
                };
                dispatch(updateSelectedAiFlowItem(updatedItem));
              }}
              InputLabelProps={{ shrink: true }}
            >
              {fileTypes.map((fileType) => (
                <MenuItem value={fileType.value}>
                  <span>{fileType.label}</span>
                </MenuItem>
              ))}
            </RHSelect>
          )} */}
          <RHSelect
            key="extract"
            name="extract"
            size="small"
            variant="standard"
            value={selectedExtractor}
            onChange={(e) => {
              setSelectedExtractor(e.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      extractorInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.extractorInput,
                        extract: e.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
            label="Extract"
            helperText="Select the type of extractor"
            zIndex={2500}
            InputLabelProps={{ shrink: true }}
            sx={{ px: 2 }}
          >
            {selectedExtractOptions.map((extractOption) => (
              <MenuItem value={extractOption.value}>
                <span>{extractOption.label}</span>
              </MenuItem>
            ))}
          </RHSelect>
          {selectedExtractor === 'json_property_value' && (
            <TextField
              onChange={(e) => {
                setExtractInput(e.target.value);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      functionalInputData: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                        extractorInput: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData?.extractorInput,
                          extractInput: e.target.value,
                        },
                      },
                    },
                  },
                };
                dispatch(updateSelectedAiFlowItem(updatedItem));
              }}
              value={extractInput}
              sx={{ px: 2 }}
              key="jsonProperty"
              name="jsonProperty"
              size="small"
              variant="standard"
              label="Json Property"
              helperText="Enter the property name"
              InputLabelProps={{ shrink: true }}
            />
          )}
        </Stack>
      </FormProvider>
    </>
  );
}
