import { Chip, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { FolderLogic } from 'src/api';
import { RHFAutocomplete, RHSelect } from 'src/components/hook-form';
import { RHAutocomplete } from 'src/components/hook-form/RHFAutocomplete';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  minWidth?: number;
  disabled?: boolean;
  onDimensionsSelect: (dimensions: string[]) => void;
  dimensionsInput?: string[];
}

export default function GoogleAnalyticsDimensionsSelect({
  maxWidth,
  dimensionsInput,
  disabled,
  minWidth,
  onDimensionsSelect,
}: Props) {
  const [selectedDimensions, setSelectedDimensions] = useState<string[]>([]);
  const [allDimensions, setAllDimensions] = useState<any[]>([
    {
      name: 'sessionDefaultChannelGroup',
      description:
        'Default channel grouping for the sessions (e.g., Organic Search, Direct, Referral).',
    },
    // Remove 'daysSinceLastSession' (not a standard dimension)
    { name: 'browser', description: 'Browser used by the user.' },
    { name: 'date', description: 'The date of the event, formatted as YYYYMMDD.' }, 
    { name: 'landingPage', description: 'The first page viewed in a session.' },
    { name: 'newVsReturning', description: 'Whether the user is new or returning.' }, // Corrected
    { name: 'operatingSystem', description: 'Operating system used by the user.' },
    { name: 'deviceCategory', description: 'Category of user device (desktop, mobile, tablet).' },
    { name: 'country', description: 'Country of the user.' },
    { name: 'city', description: 'City of the user.' },
    // Correct firstUser dimensions
    {
      name: 'firstUserSource', // Example: add specific source
      description: 'Initial source of the traffic (e.g., google).',
    },
    {
      name: 'pageReferrer', 
      description: `The full referring URL including the hostname and path.`,
    },
    {
      name: 'firstUserMedium', // Example: add specific medium
      description: 'Initial marketing medium (e.g., organic, cpc, referral).',
    },
    { name: 'firstUserCampaign', description: 'Initial campaign name.' },
    {
      name: 'sourcePlatform', // Corrected name
      description: 'Platform from which the session originated (e.g., web, android, ios).',
    },
    {
      name: 'sourceMedium', // Corrected name
      description: 'The source (e.g., "google") and medium (e.g., "organic") of the traffic.',
    },
    {
      name: 'pagePathPlusQueryString', // Corrected name
      description: 'Full URL of the page viewed, including query string.',
    },
    {
      name: 'pagePath',
      description: 'URL of the page viewed.',
    },
    { name: 'pageTitle', description: 'Title of the page viewed.' },
    { name: 'eventName', description: 'The name of the tracked event.' },
  ]);

  useEffect(() => {
    if (dimensionsInput) {
      // dimensionsInput includes only the name, so we need to find the whole object
      const dimensions = allDimensions.filter((dimension) =>
        dimensionsInput.includes(dimension.name)
      );
      setSelectedDimensions(dimensions);
    }
  }, [dimensionsInput, allDimensions]);
  return (
    <>
      <RHAutocomplete
        disabled={disabled}
        zIndex={5000}
        name="dimensions"
        size="small"
        label="Dimensions"
        onChange={(event, value) => {
          //  const names = value.map((val) => val.name);
          setSelectedDimensions(value);
          onDimensionsSelect(value);
        }}
        placeholder="+ Dimensions"
        multiple
        value={selectedDimensions}
        disableCloseOnSelect
        options={allDimensions.map((option) => option)}
        getOptionLabel={(option) => option.description + option.name}
        renderOption={(props, option) => (
          <li {...props} key={option.name}>
            {option.description} ({option.name})
          </li>
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option.name}
              label={option.description}
              size="small"
              color="info"
              variant="soft"
            />
          ))
        }
      />
    </>
  );
}
