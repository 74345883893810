import { Navigate } from 'react-router-dom';
// routes
import { PATH_MARKETING } from '../routes/paths';
// components
import LoadingScreen from '../components/loading-screen';
import { useSelector } from 'src/redux/store';
//

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  
  const isAuthenticated = useSelector((state) => state.auth.authenticated);
  const isInitizalized = useSelector((state) => state.auth.initzialized);
  if (isAuthenticated) {
    return <Navigate to={PATH_MARKETING.root} />;
  }

  if (!isInitizalized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
