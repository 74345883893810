import { memo } from 'react';
import { Handle, Position, NodeProps, BuiltInNode } from '@xyflow/react';
import cx from 'classnames';

import styles from './NodeTypes.module.css';
import useNodeClickHandler from '../hooks/useNodeClick';
import AiFlowAction from '../../item-cards/AiFlowAction';
import { AiFlowItemLogic } from 'src/api';

interface WorkflowNodeProps extends NodeProps<BuiltInNode> {
  item: AiFlowItemLogic;
  data: any;
}

const WorkflowNode = ({ id, data }: WorkflowNodeProps) => {
  // see the hook implementation for details of the click handler
  // calling onClick adds a child node to this node
  console.log('WorkflowNode', id, data);
  const onClick = useNodeClickHandler(id);

  return (
    <>
      {!data?.item && (
        <button
          type="button"
          onClick={onClick}
          className={cx(styles.node, styles.workflow)}
          title="click to add a node"
        >
          {data.label}
          <Handle
            className={styles.handle}
            type="target"
            position={Position.Top}
            isConnectable={false}
          />
          <Handle
            className={styles.handle}
            type="source"
            position={Position.Bottom}
            isConnectable={false}
          />
        </button>
      )}
      {data.item && (
        <AiFlowAction key={1} index={1} item={data.item}>
          <Handle
            className={styles.handle}
            type="target"
            position={Position.Top}
            isConnectable={false}
          />
          <Handle
            className={styles.handle}
            type="source"
            position={Position.Bottom}
            isConnectable={false}
          />
        </AiFlowAction>
      )}
    </>
  );
};

export default memo(WorkflowNode);
