// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card, { CardProps } from '@mui/material/Card';
import Typography from '@mui/material/Typography';
// assets
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useState } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import { CreditPackage } from 'src/@types/credit';

// ----------------------------------------------------------------------

type Props = CardProps & {
  creditPackage: CreditPackage;
  index: number;
};

export default function VertsCard({ creditPackage, sx, ...other }: Props) {
  return (
    <Card
      sx={{
        '&:hover': {
          border: '1px solid',
          borderColor: 'primary.main',
        },
        borderWidth: 2,
        border: '1px solid',
        p: 2,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Stack spacing={3}>
        <Typography variant="h3" align="center">
          {creditPackage.name}
        </Typography>
        <Typography variant="h4" align="center">
          {creditPackage.price}$
        </Typography>
        <Button variant="outlined" color="primary" fullWidth>
          Buy
        </Button>
      </Stack>
    </Card>
  );
}
