export const downloadFile = (response: any,fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  let contentDisposition;
  if (response.headers) {
    contentDisposition = (response.headers as any).get('Content-Disposition');
  }
  
  if (contentDisposition) {
    // This regex includes extended characters and supports filename* for RFC 5987 encoding
    // RFC 5987 format
    const filenameRegex = /filename\*=UTF-8''(.+)$/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches && matches[1]) {
      // The filename is encoded, decode it
      fileName = decodeURIComponent(matches[1]);
    } else {
      // Fallback to non-encoded filename
      const asciiFilenameRegex = /filename="([^"]*)"/;
      const asciiMatches = asciiFilenameRegex.exec(contentDisposition);
      if (asciiMatches && asciiMatches[1]) {
        fileName = asciiMatches[1];
      }
    }
  }
  link.setAttribute('download', fileName); // or any other name you want
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};
