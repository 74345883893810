// @mui
import { Box, Skeleton, Grid, GridProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends GridProps {
  height?: number | string;
  animation?: "wave" | "pulse" | false;
}

export default function SkeletonPostItem({ height,animation,...other }: Props) {
  return (
    <Grid item xs={12} {...other}>
      <Skeleton variant="rectangular" animation = {animation} width="100%" sx={{ height: height ?? 400,minWidth:300,mx:3, borderRadius: 2 }} />
      {/* <Box sx={{ display: 'flex', mt: 1.5 }}>
        <Skeleton variant="circular" sx={{ width: 40, height: 40 }} />
        <Skeleton variant="text" sx={{ mx: 1, flexGrow: 1 }} />
      </Box> */}
    </Grid>
  );
}
