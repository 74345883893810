import { Chip, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { FolderLogic } from 'src/api';
import { RHFAutocomplete, RHSelect } from 'src/components/hook-form';
import { RHAutocomplete } from 'src/components/hook-form/RHFAutocomplete';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  minWidth?: number;
  disabled?: boolean;
  onWebhookEventsSelect: (webhookEvents: string[]) => void;
  webhookEventsInput?: string[];
}

export default function MailchimpTriggerWebhookEventsSelect({
  maxWidth,
  disabled,
  webhookEventsInput,
  minWidth,
  onWebhookEventsSelect,
}: Props) {
  const [selectedWebhookEvents, setSelectedWebhookEvents] = useState<string[]>([]);
  const [allEvents, setAllEvents] = useState<any[]>([
    { name: 'subscribe', description: 'Triggered when a user subscribes to a Mailchimp audience (list).' },
    { name: 'unsubscribe', description: 'Triggered when a user unsubscribes from a Mailchimp audience.' },
    { name: 'profile', description: 'Triggered when a user updates their profile information in a Mailchimp audience.' },
    { name: 'upemail', description: `Triggered when a user's email address is updated in a Mailchimp audience.` },
    { name: 'cleaned', description: 'Triggered when an email address is cleaned from a Mailchimp audience because it bounced or is invalid.' },
    { name: 'campaign', description: 'Triggered when a campaign is sent or its status changes.' },
  ]);

  useEffect(() => {
    if (webhookEventsInput) {
      // dimensionsInput includes only the name, so we need to find the whole object
      const webhookEvents = allEvents.filter((webhookEvent) => webhookEventsInput.includes(webhookEvent.name));
      setSelectedWebhookEvents(webhookEvents);
    }
  }, [webhookEventsInput, allEvents]);
  return (
    <>
      <RHAutocomplete
        name="webhookEvents"
        placeholder="+ Events"
        multiple
        label="Webhook Events"
        value={selectedWebhookEvents}
        size="small"
        zIndex={5000}
        onChange={(event, value) => {
          //  const names = value.map((val) => val.name);
          setSelectedWebhookEvents(value);
          onWebhookEventsSelect(value);
        }}
        disableCloseOnSelect
        options={allEvents.map((option) => option)}
        getOptionLabel={(option) => option.description + option.name}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            {option.description} ({option.name})
          </li>
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option.name}
              label={option.name}
              size="small"
              color="info"
              variant="soft"
            />
          ))
        }
      />
    </>
  );
}
