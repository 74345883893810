import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IDashboardState } from 'src/@types/dashboard';
// @types
import { DashboardApi } from 'src/api';

// ----------------------------------------------------------------------

const initialState: IDashboardState = {
  dashboardWidgetsData: null,
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // START LOADING
    setLoading(state, action) {
      state.loading = action.payload;
    },
    fetchDashboardWidgetsDataSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.dashboardWidgetsData = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function fetchDashboardWidgetsData() {
  return async (dispatch: Dispatch) => {
    try {
      const dashboardApi = new DashboardApi();
      dispatch(slice.actions.setLoading(true));
      const response = await dashboardApi.apiDashboardGet();
      dispatch(slice.actions.fetchDashboardWidgetsDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
