import { Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import { Stack } from '@mui/system';
import Iconify from 'src/components/iconify';
import ContactForm from './ContactForm';

export interface Props {
  onClose: () => void;
  open: boolean;
}

export default function ContactDialog({ onClose, open }: Props) {
  return (
    <Dialog fullWidth maxWidth={false} open={open} onClose={onClose}>
      <DialogTitle>
        {' '}
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span>Contact Us</span>
          <IconButton onClick={onClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx = {{my:2}}>
        <ContactForm />
      </DialogContent>
    </Dialog>
  );
}
