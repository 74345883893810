import { MenuItem } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ConfigurationLogic } from 'src/api';
import { RHSelect } from 'src/components/hook-form';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  label?: string;
  minWidth?: number;
  onConfigurationSelect: (configurationId: number) => void;
  inputConfiguration: ConfigurationLogic | null | undefined;
}

export default function ConfigurationSelect({
  maxWidth,
  minWidth,
  label,
  onConfigurationSelect,
  inputConfiguration,
}: Props) {
  const [selectedConfiguration, setSelectedConfiguration] = useState<ConfigurationLogic>();
  const configurations = useSelector((state) => state.configurations.configurations);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  useEffect(() => {
    if (inputConfiguration) {
      const originalConfig = configurations.find(
        (configuration) => configuration.id === inputConfiguration.id
      );
      if (!_.isEqual(inputConfiguration, originalConfig)) {
        //  setSelectedConfiguration(undefined);
      } else {
        setSelectedConfiguration(originalConfig);
      }
    }
  }, [selectedConfiguration, inputConfiguration, configurations]);

  return (
    <>
      <RHSelect
        zIndex={3410}
        disabled={runsMode}
        width={maxWidth}
        key="selectConfiguration"
        name="selectConfiguration"
        size="small"
        sx={{ minWidth, maxWidth }}
        label={label ?? 'Load Configuration'}
        value={selectedConfiguration?.id || ''}
        onChange={(event) => {
          setSelectedConfiguration(
            configurations.find(
              (configuration) => configuration.id === parseInt(event.target.value, 10)
            )
          );
          onConfigurationSelect(parseInt(event.target.value, 10));
        }}
        InputLabelProps={{ shrink: true }}
      >
        {configurations.map((configuration) => (
          <MenuItem value={configuration.id as number}>
            <span>{configuration.name}</span>
          </MenuItem>
        ))}
      </RHSelect>
    </>
  );
}
