import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { EmailProviderApi } from 'src/api';
import { ICampaignsState } from 'src/@types/campaign';

// ----------------------------------------------------------------------

const initialState: ICampaignsState = {
  campaigns: {},
  error: null,
  loading: false,
};

const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    // START LOADING
    startFetching(state) {
      state.loading = true;
    },
    fetchCampaignsSuccess(state, action) {
      state.loading = false;
      state.campaigns[action.payload.provider] = action.payload.campaigns;
    },
    deleteCampaignSuccess(state, action) {
      state.campaigns[action.payload.provider] = state.campaigns[action.payload.provider].filter(
        (campaign) => campaign.id !== action.payload.campaignId
      );
    },
    deleteCampaignsSuccess(state, action) {
      
      state.campaigns[action.payload.provider] = state.campaigns[action.payload.provider].filter(
        (campaign) => !action.payload.campaignsId.includes(campaign.id)
      );
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchCampaigns(provider: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetching());
    try {
      const emailProviderApi = new EmailProviderApi();
      const response = await emailProviderApi.apiEmailProviderCampaignsGet(provider);
      dispatch(slice.actions.fetchCampaignsSuccess({ campaigns: response.data, provider }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteCampaign(campaignId: string, provider: string, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderCampaignIdDelete(campaignId, {
      //   providerName: provider,
      // });
      enqueueSnackbar('Campaign was deleted succesfully', { variant: 'success' });
      dispatch(slice.actions.deleteCampaignSuccess({ campaignId, provider }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteCampaigns(campaignsId: string[], provider: string, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderCampaignsDelete({
      //   providerName: provider,
      //   campaignsId,
      // });
      
      enqueueSnackbar('Campaigns were deleted succesfully', { variant: 'success' });
      dispatch(slice.actions.deleteCampaignsSuccess({ campaignsId, provider }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
