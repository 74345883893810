import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IPromptsState } from 'src/@types/prompts';
import { ITemplatesState } from 'src/@types/templates';
// @types
import { PromptLogic, PromptsApi, TemplateApi } from 'src/api';

// ----------------------------------------------------------------------

const initialState: IPromptsState = {
  prompts: [],
  promptRepositories: [],
  selectedPromptRepository: null,
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'expressions',
  initialState,
  reducers: {
    /// prompt repositories
    fetchPromptRepositoriesSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.promptRepositories = action.payload;
    },
    setSelectedPromptRepository(state, action) {
      state.selectedPromptRepository = action.payload;
    },
    updatePromptRepositorySuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.promptRepositories = state.promptRepositories.map((promptRepository) => {
        if (promptRepository.id === action.payload.id) {
          return action.payload;
        }
        return promptRepository;
      });
    },

    deletePromptRepositorySuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.promptRepositories = state.promptRepositories.filter(
        (promptRepository) => promptRepository.id !== action.payload
      );
    },

    createPromptRepositorySuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.promptRepositories = [...state.promptRepositories, action.payload];
    },

    ///
    fetchPromptsSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.prompts = action.payload;
    },
    updatePromptSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.prompts = state.prompts.map((prompt) => {
        if (prompt.id === action.payload.id) {
          return action.payload;
        }
        return prompt;
      });
    },

    deletePromptSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.prompts = state.prompts.filter((prompt) => prompt.id !== action.payload);
    },

    createPromptSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.prompts = [...state.prompts, action.payload];
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

// Actions
export const { setSelectedPromptRepository, hasError } = slice.actions;
// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// CRUD

// prompt repositories

export function fetchPromptRepositories() {
  return async (dispatch: Dispatch) => {
    try {
      const promptsApi = new PromptsApi();
      const response = await promptsApi.apiPromptsPromptRepositoriesGet();
      dispatch(slice.actions.fetchPromptRepositoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createPromptRepository(promptRepository: any, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const promptsApi = new PromptsApi();
      const response = await promptsApi.apiPromptsCreatePromptRepositoryPost(promptRepository);
      enqueueSnackbar('Prompt repository created successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      dispatch(slice.actions.createPromptRepositorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error creating prompt repository', { variant: 'error' });
    }
  };
}

export function updatePromptRepository(promptRepository: any, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const promptsApi = new PromptsApi();
      const response = await promptsApi.apiPromptsUpdatePromptRepositoryPut(promptRepository);
      dispatch(slice.actions.updatePromptRepositorySuccess(response.data));
      enqueueSnackbar('Prompt repository updated successfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error updating prompt repository', { variant: 'error' });
    }
  };
}

export function deletePromptRepository(promptRepositoryId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const promptsApi = new PromptsApi();
      await promptsApi.apiPromptsDeletePromptRepositoryDelete(promptRepositoryId);
      dispatch(slice.actions.deletePromptRepositorySuccess(promptRepositoryId));
      enqueueSnackbar('Prompt repository deleted successfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error deleting prompt repository', { variant: 'error' });
    }
  };
}

export function fetchPrompts(promptRepoId : number) {
  return async (dispatch: Dispatch) => {
    try {
      const promptsApi = new PromptsApi();
      const response = await promptsApi.apiPromptsPromptsPromptRepositoryIdGet(promptRepoId);
      dispatch(slice.actions.fetchPromptsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// finish crud
export function createPrompt(prompt: PromptLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const promptsApi = new PromptsApi();
      const response = await promptsApi.apiPromptsCreatePromptPost(prompt);
      enqueueSnackbar('Prompt created successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      dispatch(slice.actions.createPromptSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error creating prompt', { variant: 'error' });
    }
  };
}

export function updatePrompt(prompt: PromptLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const promptsApi = new PromptsApi();
      const response = await promptsApi.apiPromptsUpdatePromptPut(prompt);
      dispatch(slice.actions.updatePromptSuccess(prompt));
      enqueueSnackbar('Prompt updated successfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error updating prompt', { variant: 'error' });
    }
  };
}

export function deletePrompt(promptId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const promptsApi = new PromptsApi();
      await promptsApi.apiPromptsDeletePromptDelete(promptId);
      dispatch(slice.actions.deletePromptSuccess(promptId));
      enqueueSnackbar('Prompt deleted successfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error deleting prompt', { variant: 'error' });
    }
  };
}
