import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import Iconify from 'src/components/iconify';
import RefinePage from 'src/pages/marketing/actions/RefinePage';

interface Props {
  onClose: () => void;
  open: boolean;
  selectedRefineAction?: string;
  selectedEditorContent?: string;
}

export default function RefineDialog({ onClose, open, selectedRefineAction, selectedEditorContent }: Props) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span>Refine Content</span>
          <IconButton onClick={handleClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ typography: 'body2', minHeight: 600, overflow: 'hidden' }}>
        <Scrollbar>
          <Box sx={{ p: 2, width: '100%', overflow: 'hidden' }}>
            <Typography variant="h6" gutterBottom>
              <RefinePage selectedEditorContent = {selectedEditorContent} selectedRefineActionProp={selectedRefineAction} />
            </Typography>
          </Box>
        </Scrollbar>
      </DialogContent>
    </Dialog>
  );
}
