// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, AutocompleteProps, CircularProgress, Popper, TextField } from '@mui/material';

// ----------------------------------------------------------------------

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  field?: any;
  error?: string | boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  zIndex?: number;
  helperText?: React.ReactNode;
}

export function RHAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  name,
  label,
  error,
  field,
  variant = 'outlined',
  loading,
  zIndex,
  helperText,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {

  
  return (
    <Autocomplete
    
    slotProps={{
      popper: {
        // Targeting the popover component
        sx: {
          // Adding styles to the popover component
          zIndex: zIndex ?? 'auto', // Set your desired zIndex value
        },
      },
    }}
    // PopperComponent={CustomPopper}
      {...field}
      renderInput={(params) => (
        <TextField
        
        variant={variant}
          label={label}
          error={!!error}
          SelectProps={{
            MenuProps: {
              style: { zIndex: 9000 },
            },
          }}
          helperText={helperText}

          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...other}
    />
  );
}

export function RHFAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  name,
  label,
  helperText,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => setValue(name, newValue, { shouldValidate: true })}
          renderInput={(params) => (
            <TextField
              SelectProps={{
                MenuProps: {
                  style: { zIndex: 9000 },
                },
              }}
              label={label}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
            />
          )}
          {...other}
        />
      )}
    />
  );
}
