export enum ModelType {
  gpt_3_5_turbo_16k = 'gpt-3.5-turbo-16k',
  gpt_3_5_turbo_0125 = 'gpt-3.5-turbo-0125',
  gpt_4 = 'gpt-4',
  gpt_4_turbo = 'gpt-4-turbo',
  gpt_4_o = 'gpt-4o',
  gpt_4_o_mini = 'gpt-4o-mini',
  gpt_vision = 'gpt-vision',
  dall_e_2 = 'dall-e-2',
  dall_e_3 = 'dall-e-3',
  gemini_vision = 'gemini-pro-vision',
  claude_vision = 'claude-vision',
  Gemini1Pro = 'Gemini 1.0 Pro',
  Gemini15Pro = 'Gemini 1.5 Pro',
  ClaudeSonnet35 = 'claude-3-5-sonnet-20240620',
  ClaudeHaiku = 'claude-3-haiku-20240307',
  ClaudeSonnet = 'claude-3-sonnet-20240229',
  ClaudeOpus = 'claude-3-opus-20240229',
}

export enum FineTuneModelType {
  gpt_3_5_turbo_0125 = 'gpt-3.5-turbo-0125',
  gpt_3_5_turbo_1106 = 'gpt-3.5-turbo-1106',
  gpt_4_0613 = 'gpt-4-0613',
}

export enum EmbeddingsModelType {
  text_embedding_3_small = 'text-embedding-3-small',
  text_embedding_3_large = 'text-embedding-3-large',
  text_embedding_ada_002 = 'text-embedding-ada-002',
}

export const ModelTypeMaxTokens = {
  'gpt-3.5-turbo-16k': 16350,
  'gpt-3.5-turbo-0125': 4050,
  'gpt-4-turbo': 4050,
  'gpt-4o': 4050,
  'gpt-4o-mini': 16350,
  'gpt-4': 8150,
  'gpt-vision': 4050,
  'Gemini 1.0 Pro': 32750,
  'Gemini 1.5 Pro': 1050000,
  'claude-3-5-sonnet-20240620': 200000,
  'claude-3-haiku-20240307': 200000,
  'claude-3-sonnet-20240229': 200000,
  'claude-3-opus-20240229': 200000,
};

export const llmModels = {
  OpenAI: {
    Generate: [
      ModelType.gpt_4_o_mini,
      ModelType.gpt_4_o,
      ModelType.gpt_4_turbo,
      ModelType.gpt_4
    ],
    Vision: [ModelType.gpt_4_o,ModelType.gpt_4_o_mini],
    VisionGenerate: [ModelType.dall_e_2, ModelType.dall_e_3],
  },
  Gemini: {
    Generate: [ModelType.Gemini1Pro, ModelType.Gemini15Pro],
    Vision: [ModelType.Gemini15Pro],
    VisionGenerate: [],
  },
  Claude: {
    Generate: [ModelType.ClaudeSonnet35, ModelType.ClaudeHaiku, ModelType.ClaudeSonnet, ModelType.ClaudeOpus],
    Vision: [ModelType.claude_vision],
    VisionGenerate: [],
  },
};

export const modelCompanyModelNameMap = {
  OpenAI: [
    'gpt-3.5-turbo-16k',
    'gpt-3.5-turbo-0125',
    'gpt-4',
    'gpt-4-turbo',
    'gpt-4o',
    'gpt-4o-mini',
    'gpt-vision',
  ],
  Gemini: [
    'Gemini 1.0 Pro',
    'Gemini 1.5 Pro',
  ],
  Claude: [
    'Claude Haiku',
    'Claude Sonnet',
    'Claude Opus',
    'claude-vision',
  ],
};

export const fineTuneLlmModels = {
  OpenAI: [
    FineTuneModelType.gpt_3_5_turbo_0125,
    FineTuneModelType.gpt_4_0613,
    FineTuneModelType.gpt_3_5_turbo_1106,
  ]
};

export const embeddingsLlmModels = {
  OpenAI: [
    EmbeddingsModelType.text_embedding_3_small,
    EmbeddingsModelType.text_embedding_3_large,
    EmbeddingsModelType.text_embedding_ada_002,
  ]
};

export function findIntegrationByModelType(
  modelTypeValue: string,
  type: 'Generate' | 'Vision' = 'Generate'
): string | undefined {
  const modelType = modelTypeValue as ModelType;
  for (const [integration, models] of Object.entries(llmModels)) {
    if (models[type].includes(modelType)) {
      return integration;
    }
  }
  return undefined; // Return undefined if the model type value is not found
}

export function getIntegrationAndModelDisplayName(integrationName: string): string | undefined {
  // Convert the integrationName string to the corresponding enum value
  const modelType = ModelType[integrationName as keyof typeof ModelType];
  if (!modelType) {
    return undefined; // Return undefined if the integration name is not valid
  }

  const integration = findIntegrationByModelType(modelType);
  if (integration) {
    return `${integration} ${modelType}`;
  }
  return undefined;
}
