import { Card, Tooltip, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { chunk } from 'lodash';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Iconify from '../iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { useDispatch } from 'src/redux/store';
import FullPageDialog from 'src/common/modals/FullPageDialog';
import { setFullscreen } from 'src/redux/slices/aiflows';

interface Props {
  text: string;
  language: string;
  fullScreen?: boolean;
  height?: number;
}
export default function CodeOutputCard({ text, language, fullScreen, height }: Props) {
  const openFullScreen = useBoolean(false);
  const showCopiedAlert = useBoolean(false);
  const dispatch = useDispatch();
  const startShowCopiedAlert = () => {
    showCopiedAlert.onTrue();
    setTimeout(() => {
      showCopiedAlert.onFalse();
    }, 2000);
  };
  return (
    <>
      <Card sx={{ p: 1 }}>
        <Stack>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            {/* {showCopiedAlert.value && <Alert sx={{}}>Copied!</Alert>} */}
            {!fullScreen && (
              <Tooltip title="Full screen">
                <IconButton onClick={openFullScreen.onTrue}>
                  <Iconify icon="material-symbols:fullscreen" />
                </IconButton>
              </Tooltip>
            )}
            {showCopiedAlert.value && (
              <IconButton disableRipple sx={{ cursor: 'default' }}>
                <Iconify icon="el:ok-circle" color="success" />
              </IconButton>
            )}
            {!showCopiedAlert.value && (
              <Tooltip title="Copy Code">
                <IconButton
                  onClick={() => {
                    startShowCopiedAlert();
                    navigator.clipboard.writeText(text);
                  }}
                  sx={{ maxWidth: 40, maxHeight: 40 }}
                >
                  <Iconify icon="ph:copy" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          <SyntaxHighlighter
            // key={index}
            customStyle={{ height: `${height ?? 400}px`, overflow: 'auto', borderRadius: '8px' }}
            language={language}
            style={a11yDark}
          >
            {text}
          </SyntaxHighlighter>
        </Stack>
      </Card>
      <FullPageDialog
        title="Code Output"
        zIndex={2500}
        children={<CodeOutputCard text={text} language={language} height = {650} fullScreen />}
        open={openFullScreen.value}
        onClose={() => {
          dispatch(setFullscreen(false));
          openFullScreen.onFalse();
        }}
      />
    </>
  );
}
