import { useCallback, useEffect, useRef, useState } from 'react';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Avatar,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  InputBase,
  TextField,
} from '@mui/material';
// utils
// _mock_
// components
import { AiFlowItemNoteLogic, OutputCommentLogic } from 'src/api';
import _ from 'lodash';
import { notificationTitleByType } from 'src/common/constants/notifications.constants';
import { useNavigate } from 'react-router';
import { PATH_MARKETING } from 'src/routes/paths';
import { StyledScrollbarDiv } from 'src/components/scrollbar/styles';
import { IconButtonAnimate } from '../animate';
import MenuPopover from '../menu-popover';
import Iconify from '../iconify';
import { fToNow } from 'src/utils/formatTime';
import { useDispatch, useSelector } from 'src/redux/store';
import { addAiFlowItemNote, deleteAiFlowItemNote, updateAiFlowItemNote } from 'src/redux/slices/aiflows';
import { useSnackbar } from '../snackbar';

// ----------------------------------------------------------------------

export default function AiFlowItemNotesPopover() {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  // const [outputComments, setOutputComments] = useState<OutputCommentLogic[]>([]);
  const [newNote, setNewNote] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const selectedItemNotes = useSelector(
    (state) => state.aiFlows.selectedAiFlowItem?.aiFlowItemNotes
  );
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const fetchOutputComments = async () => {
  //     const aiflowRunApi = new AiFlowRunsApi();
  //     const ocResponse = await aiflowRunApi.apiAiFlowRunsOutputCommentsGet(outputId);
  //     if (!_.isEqual(ocResponse.data, outputComments)) {
  //       setOutputComments(ocResponse.data);
  //     }
  //   };
  //   if (openPopover) {
  //     fetchOutputComments();
  //   }
  // }, [outputComments, openPopover, outputId]);

  const scrollbarRef = useRef();

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const createNewNote = useCallback(async () => {
    try {
      if (!newNote) {
        return;
      }
      const newNotee: AiFlowItemNoteLogic = {
        note: newNote,
        aiFlowItemId: selectedItem?.id as number,
      };
      dispatch(addAiFlowItemNote(newNotee));
      setNewNote('');
    } catch (error) {
      console.error(error);
    }
  }, [newNote, dispatch, selectedItem]);

  const handleAddNote = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        if (newNote) {
          await createNewNote();
        }
      }
    },
    [newNote, createNewNote]
  );

  const fetchMoreData = () => {};

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={selectedItemNotes?.length} color="primary">
          <Iconify icon="uil:notes" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 500, p: 0, zIndex: 4500 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notes</Typography>

            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography> */}
          </Box>

          {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="material-symbols:mark-email-read-outline" />
              </IconButton>
            </Tooltip>
          )} */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <StyledScrollbarDiv ref={scrollbarRef} sx={{ maxHeight: { sm: 465 } }} id="scrollableDiv">
          {selectedItemNotes && selectedItemNotes.length === 0 && (
            <Box sx={{ p: 2 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                There are no notes.
              </Typography>
            </Box>
          )}
          <List disablePadding>
            {selectedItemNotes &&
              selectedItemNotes.length > 0 &&
              selectedItemNotes.map((itemNotes) => (
                <AiFlowItemNoteItem key={itemNotes.id} note={itemNotes} />
              ))}
          </List>
        </StyledScrollbarDiv>
        <InputBase
          value={newNote}
          onKeyUp={handleAddNote}
          onChange={(event) => {
            setNewNote(event.target.value);
          }}
          placeholder="Write a note..."
          //  disabled={disabled}

          endAdornment={
            <Stack direction="row" sx={{ flexShrink: 0 }}>
              <IconButton onClick={() => createNewNote()}>
                <Iconify icon="material-symbols-light:post-add" />
              </IconButton>
            </Stack>
          }
          sx={{
            px: 2,
            height: 56,
            width: '100%',
            flexShrink: 0,
            borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
          }}
        />

        <input type="file" style={{ display: 'none' }} />
        {/* <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

const getFormattedTitleByTypeAndParams = (type: string, parameters: string[]) => {
  let title = notificationTitleByType[type as keyof typeof notificationTitleByType];
  if (!title) {
    return '';
  }
  if (!parameters || parameters?.length < 2) {
    return title.replace(/\{\{(\d+)\}\}/g, '');
  }
  for (let i = 1; i < parameters.length; i++) {
    title = title.replace(`{{${i}}}`, parameters[i]);
  }
  return title;
};

// ----------------------------------------------------------------------

type AiFlowItemNoteItemProps = {
  note: AiFlowItemNoteLogic;
  //  seHoveredItem: (item?: AiFlowItemNoteLogic | null) => void;
};

function AiFlowItemNoteItem({ note }: AiFlowItemNoteItemProps) {
  const { avatar, title } = renderContent(note);
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [noteAvatar, setNoteAvatar] = useState<JSX.Element | null>(avatar);
  const [noteTitle, setNoteTitle] = useState<JSX.Element | null>(title);
  const navigate = useNavigate();
  const [selectedNoteItem, setSelectedNoteItem] = useState<AiFlowItemNoteLogic | null>(null);
  const [editingNoteItem, setEditingNoteItem] = useState<AiFlowItemNoteLogic | null>(null);
  const handleFlowNotificationClick = (runNumber: string) => {
    const runNumberLong = parseFloat(runNumber);
    if (runNumberLong) {
      navigate(`${PATH_MARKETING.pages.aiFlowsRuns}/${runNumberLong}`);
    }
  };

  const onTitleChange = useCallback((newNoteText: string) => {
    if (!editingNoteItem) {
      return;
    }
    const newEditedComment = _.cloneDeep(editingNoteItem);
    newEditedComment.note = newNoteText;
    setEditingNoteItem(newEditedComment);
  }, [editingNoteItem]);

  const onFinishedEditing = useCallback(() => {
    if (!editingNoteItem) {
      return;
    }
    dispatch(updateAiFlowItemNote(editingNoteItem));
    setEditingNoteItem(null);
  }, [editingNoteItem, dispatch]);

  useEffect(() => {
    if (editingNoteItem) {
      const newTitle = renderContent(
        _.cloneDeep(editingNoteItem),
        true,
        onTitleChange,
        onFinishedEditing
      ).title;
      setNoteTitle(newTitle);
    }
    else{
      const newTitle = renderContent(
        _.cloneDeep(note),
        false,
        onTitleChange,
        onFinishedEditing
      ).title;
      setNoteTitle(newTitle);
    }
  }, [editingNoteItem, note, onTitleChange, onFinishedEditing]);

  return (
    <>
      <ListItemButton
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
        }}
        onClick={() => {}}
        onMouseEnter={() => {
          setSelectedNoteItem(note);
        }}
        onMouseLeave={() => {
          setSelectedNoteItem(null);
        }}
        disableRipple
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={noteTitle}
          secondary={
            <>
              <Stack direction="row" justifyContent="space-between">
                <Stack
                  direction="row"
                  sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}
                >
                  <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
                  <Typography variant="caption">{fToNow(note.createdAt as Date)}</Typography>
                </Stack>
                {selectedNoteItem?.id === note.id && !editingNoteItem && (
                  <Stack direction="row" sx={{ position: 'absolute', right: 5, top: 10 }}>
                    <IconButton
                      onClick={() => {
                        setEditingNoteItem(note);
                      }}
                    >
                      <Iconify icon="eva:edit-fill" width={20} sx={{ mr: 0.5 }} />
                    </IconButton>
                    <IconButton onClick={() => {
                      dispatch(deleteAiFlowItemNote(note.id as number,enqueueSnackbar));
                    }}>
                      <Iconify icon="eva:trash-2-outline" width={20} sx={{ mr: 0.5 }} />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
            </>
          }
        />
      </ListItemButton>
    </>
  );
}

// ----------------------------------------------------------------------

const renderContent = (
  note: AiFlowItemNoteLogic,
  editingNoteItem?: boolean,
  onTitleChange?: any,
  onFinishedEditing?: any
) => {
  const title = (
    <>
      <Typography variant="subtitle2">
        {note.author}:
        {!editingNoteItem && (
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            &nbsp; {note.note}
          </Typography>
        )}
        {editingNoteItem && (
          <TextField
            rows={2}
            onBlur={() => {
              onFinishedEditing();
            }}
            multiline
            value={note.note}
            onChange={(event) => {
              onTitleChange(event.target.value);
            }}
            sx={{ width: '100%', fontSize: 14 }}
          />
        )}
      </Typography>
    </>
  );
  return {
    avatar: note.authorAvatar ? (
      <Avatar
        src={note.authorAvatar as string}
        sx={{
          width: 36,
          height: 36,
          border: (theme) => `solid 2px ${theme.palette.background.default}`,
        }}
      />
    ) : (
      <Iconify icon="material-symbols-light:person" width={24} height={24} />
    ),
    title,
  };
};
