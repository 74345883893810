import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import Scrollbar from 'src/components/scrollbar';
import { DialogComponentMode } from 'src/common/constants/general.constants';
import Iconify from 'src/components/iconify';
import EditGeneratedContentPage from 'src/pages/marketing/content/generated/EditGeneratedContentPage';
import OutputsPageOld from 'src/pages/outputs/OutputsPage_old';

interface Props {
  onClose: () => void;
  open: boolean;
}

export default function ContentManagementDialog({ onClose, open }: Props) {
  const handleClose = () => {
    setSelectedGeneratedContentId(undefined);
    setGeneratedContentMode(DialogComponentMode.View);
    setContentManagementTabIndex(0);
    onClose();
  };
  const [selectedGeneratedContentId, setSelectedGeneratedContentId] = useState<number>();
  const [generatedContentMode, setGeneratedContentMode] = useState<DialogComponentMode>(
    DialogComponentMode.View
  );

  const handleOnEditGenerateContentPage = (generatedContentId: number) => {
    setSelectedGeneratedContentId(generatedContentId as number);
    setGeneratedContentMode(DialogComponentMode.Edit);
  };

  const handleSetGeneratedContentMode = (mode: DialogComponentMode) => {
    setGeneratedContentMode(mode);
  };
  const navigate = useNavigate();
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 0, mt: 3 }}>{children}</Box>}
      </div>
    );
  }

  const [contentManagementTabIndex, setContentManagementTabIndex] = useState(0);

  const dialogRootRef = useRef();

  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span>Generated</span>
          <IconButton onClick={handleClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ typography: 'body2', minHeight: 600, overflow: 'hidden' }}>
        <Scrollbar>
          <Box sx={{ p: 2, width: '100%', overflow: 'hidden' }}>
          <Typography variant="h6" gutterBottom>
                {generatedContentMode === DialogComponentMode.View && (
                  <OutputsPageOld dialogMode onEdit={handleOnEditGenerateContentPage} />
                )}
                {generatedContentMode === DialogComponentMode.Edit && (
                  <EditGeneratedContentPage
                    onSetDialogMode={handleSetGeneratedContentMode}
                    dialogMode
                    generatedContentId={selectedGeneratedContentId?.toString()}
                  />
                )}
              </Typography>
            {/* <Tabs
              variant="fullWidth"
              value={contentManagementTabIndex}
              onChange={(event: React.SyntheticEvent, newValue: number) => {
                setContentManagementTabIndex(newValue);
              }}
            >
              <Tab label="Content Snippets" />
              <Tab label="Content Tags" />
              <Tab label="Generated Content" />
            </Tabs>
            <TabPanel value={contentManagementTabIndex} index={0}>
              <ContentSnippetsPage dialogMode/>
            </TabPanel>
            <TabPanel value={contentManagementTabIndex} index={1}>
            <ContentTagsPage dialogMode />
            </TabPanel>
            <TabPanel value={contentManagementTabIndex} index={2}>
              <Typography variant="h6" gutterBottom>
                {generatedContentMode === DialogComponentMode.View && (
                  <GeneratedContentPage dialogMode onEdit={handleOnEditGenerateContentPage} />
                )}
                {generatedContentMode === DialogComponentMode.Edit && (
                  <EditGeneratedContentPage
                    onSetDialogMode={handleSetGeneratedContentMode}
                    dialogMode
                    generatedContentId={selectedGeneratedContentId?.toString()}
                  />
                )}
              </Typography>
            </TabPanel> */}
          </Box>
        </Scrollbar>
      </DialogContent>
    </Dialog>
  );
}
