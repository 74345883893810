import { useCallback, useEffect, useRef, useState } from 'react';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Avatar,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  InputBase,
  TextField,
} from '@mui/material';
// utils
// _mock_
// components
import { OutputCommentLogic } from 'src/api';
import _ from 'lodash';
import { notificationTitleByType } from 'src/common/constants/notifications.constants';
import { useNavigate } from 'react-router';
import { PATH_MARKETING } from 'src/routes/paths';
import { StyledScrollbarDiv } from 'src/components/scrollbar/styles';
import { IconButtonAnimate } from '../animate';
import MenuPopover from '../menu-popover';
import Iconify from '../iconify';
import { fToNow } from 'src/utils/formatTime';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  addOutputComment,
  deleteOutputComment,
  updateOutputComment,
} from 'src/redux/slices/aiFlow-runs';
import { useQuery } from 'src/utils/query';

// ----------------------------------------------------------------------

interface Params {
  outputId: number;
}

export default function OutputCommentsPopover({ outputId }: Params) {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [newComment, setNewComment] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const outputComments = useSelector((state) => state.aiFlowsRuns.selectedOutputComments);
  const dispatch = useDispatch();
  const scrollbarRef = useRef();
  const iconButtonRef = useRef<any>(); // Ref for the IconButton

  const query = useQuery();
  const queryOutputId = query.get('outputId');

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  useEffect(() => {
    if (queryOutputId && iconButtonRef.current) {
      // Programmatically trigger the click event
      iconButtonRef.current.click();
    }
  }, [queryOutputId]);

  const createNewOutputComment = useCallback(async () => {
    try {
      if (!newComment) {
        return;
      }
      const newCom = {
        comment: {
          comment: newComment,
          aiFlowRunOutputId: outputId,
        },
      };
      dispatch(addOutputComment(newCom));
      setNewComment('');
    } catch (error) {
      console.error(error);
    }
  }, [newComment, outputId, dispatch]);

  const handleSendNewComment = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        if (newComment) {
          await createNewOutputComment();
        }
      }
    },
    [newComment, createNewOutputComment]
  );

  const fetchMoreData = () => {};

  return (
    <>
      <IconButtonAnimate
        ref={iconButtonRef} // Assign the ref to the IconButton
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={outputComments?.length} color="primary">
          <Iconify icon="ic:outline-comment" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 500, p: 0, zIndex: 4500 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Comments</Typography>

            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography> */}
          </Box>

          {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="material-symbols:mark-email-read-outline" />
              </IconButton>
            </Tooltip>
          )} */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <StyledScrollbarDiv ref={scrollbarRef} sx={{ maxHeight: { sm: 465 } }} id="scrollableDiv">
          {outputComments.length === 0 && (
            <Box sx={{ p: 2 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                There are no comments.
              </Typography>
            </Box>
          )}
          <List disablePadding>
            {outputComments.map((oc) => (
              <OutputCommentItem key={oc.id} outputComment={oc} />
            ))}
          </List>
        </StyledScrollbarDiv>
        <InputBase
          value={newComment}
          onKeyUp={handleSendNewComment}
          onChange={(event) => {
            setNewComment(event.target.value);
          }}
          placeholder="Type a comment"
          //  disabled={disabled}

          endAdornment={
            <Stack direction="row" sx={{ flexShrink: 0 }}>
              <IconButton onClick={() => createNewOutputComment()}>
                <Iconify icon="material-symbols:send-outline" />
              </IconButton>
            </Stack>
          }
          sx={{
            px: 2,
            height: 56,
            width: '100%',
            flexShrink: 0,
            borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
          }}
        />

        <input type="file" style={{ display: 'none' }} />
        {/* <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

const getFormattedTitleByTypeAndParams = (type: string, parameters: string[]) => {
  let title = notificationTitleByType[type as keyof typeof notificationTitleByType];
  if (!title) {
    return '';
  }
  if (!parameters || parameters?.length < 2) {
    return title.replace(/\{\{(\d+)\}\}/g, '');
  }
  for (let i = 1; i < parameters.length; i++) {
    title = title.replace(`{{${i}}}`, parameters[i]);
  }
  return title;
};

// ----------------------------------------------------------------------

type OutputCommentItemProps = {
  outputComment: OutputCommentLogic;
};

function OutputCommentItem({ outputComment }: OutputCommentItemProps) {
  const { avatar, title } = renderContent(outputComment);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCommentItem, setSelectedCommentItem] = useState<OutputCommentLogic | null>(null);
  const [editingCommentItem, setEditingCommentItem] = useState<OutputCommentLogic | null>(null);
  const [commentTitle, setCommentTitle] = useState<JSX.Element | null>(title);
  const handleFlowNotificationClick = (runNumber: string) => {
    const runNumberLong = parseFloat(runNumber);
    if (runNumberLong) {
      navigate(`${PATH_MARKETING.pages.aiFlowsRuns}/${runNumberLong}`);
    }
  };

  const onTitleChange = useCallback(
    (newCommentText: string) => {
      if (!editingCommentItem) {
        return;
      }
      const newEditedComment = _.cloneDeep(editingCommentItem);
      newEditedComment.comment = newCommentText;
      setEditingCommentItem(newEditedComment);
    },
    [editingCommentItem]
  );

  const onFinishedEditing = useCallback(() => {
    if (!editingCommentItem) {
      return;
    }
    dispatch(updateOutputComment(editingCommentItem));
    setEditingCommentItem(null);
  }, [editingCommentItem, dispatch]);

  useEffect(() => {
    if (editingCommentItem) {
      const newTitle = renderContent(
        _.cloneDeep(editingCommentItem),
        true,
        onTitleChange,
        onFinishedEditing
      ).title;
      setCommentTitle(newTitle);
    } else {
      const newTitle = renderContent(
        _.cloneDeep(outputComment),
        false,
        onTitleChange,
        onFinishedEditing
      ).title;
      setCommentTitle(newTitle);
    }
  }, [editingCommentItem, outputComment, onTitleChange, onFinishedEditing]);

  return (
    <>
      <ListItemButton
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
        }}
        onClick={() => {}}
        onMouseEnter={() => {
          setSelectedCommentItem(outputComment);
        }}
        onMouseLeave={() => {
          setSelectedCommentItem(null);
        }}
        disableRipple
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={commentTitle}
          secondary={
            <>
              <Stack
                direction="row"
                sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}
              >
                <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
                <Typography variant="caption">{fToNow(outputComment.createdAt as Date)}</Typography>
              </Stack>
              {selectedCommentItem?.id === outputComment.id && !editingCommentItem && (
                <Stack direction="row" sx={{ position: 'absolute', right: 5, top: 10 }}>
                  <IconButton
                    onClick={() => {
                      setEditingCommentItem(outputComment);
                    }}
                  >
                    <Iconify icon="eva:edit-fill" width={20} sx={{ mr: 0.5 }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      dispatch(
                        deleteOutputComment(
                          outputComment.id as number,
                          outputComment.aiFlowRunOutputId as number
                        )
                      );
                    }}
                  >
                    <Iconify icon="eva:trash-2-outline" width={20} sx={{ mr: 0.5 }} />
                  </IconButton>
                </Stack>
              )}
            </>
          }
        />
      </ListItemButton>
    </>
  );
}

// ----------------------------------------------------------------------

function renderContent(
  oc: OutputCommentLogic,

  editingCommentItem?: boolean,
  onTitleChange?: any,
  onFinishedEditing?: any
) {
  const title = (
    <Typography variant="subtitle2">
      {oc.commenterName}:
      {!editingCommentItem && (
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
          &nbsp; {oc.comment}
        </Typography>
      )}
      {editingCommentItem && (
        <TextField
          rows={2}
          onBlur={() => {
            onFinishedEditing();
          }}
          multiline
          value={oc.comment}
          onChange={(event) => {
            onTitleChange(event.target.value);
          }}
          sx={{ width: '100%', fontSize: 14 }}
        />
      )}
    </Typography>
  );
  return {
    avatar: oc.commenterAvatar ? (
      <Avatar
        src={oc.commenterAvatar as string}
        sx={{
          width: 36,
          height: 36,
          border: (theme) => `solid 2px ${theme.palette.background.default}`,
        }}
      />
    ) : (
      <Iconify icon="material-symbols-light:person" width={24} height={24} />
    ),
    title,
  };
}
