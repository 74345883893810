import { Helmet } from "react-helmet-async";
import ModernRegisterView from "./modern-register-view";

export default function ModernRegisterPage() {
    return (
      <>
        <Helmet>
          <title>Kuverto - Register New Account</title>
        </Helmet>
  
        <ModernRegisterView />
      </>
    );
  }
  