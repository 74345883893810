import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Paper,
  Typography,
  Divider,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import _mock from 'src/_mock';
import { RunOutputInfo } from 'src/api';
import OpenAiIcon from 'src/assets/icons/OpenAiIcon';
import Iconify from 'src/components/iconify';
import MuiImage from 'src/components/image';
import PromptEditor from 'src/components/prompt-editor/PromptEditor';

export interface Props {
  onClose: () => void;
  open: boolean;
  runOutputInfo: RunOutputInfo;
}

export default function AiFlowItemOutputInfoDialog({ onClose, open, runOutputInfo }: Props) {
  const outputInfoStats = [
    {
      name: 'modelProvider',
      label: 'Model Provider',
      color: '#006097',
      value: runOutputInfo?.modelProvider ?? runOutputInfo?.modelName ? 'OpenAI': null,
      icon: 'material-symbols-light:input',
    },
    {
      name: 'ProcessedDate',
      label: 'Processed Date',
      color: '#1877F2',
      // turn date into string yyyy-mm-dd hh:mm:ss
      value: runOutputInfo?.processedDate
        ? new Date(runOutputInfo.processedDate).toLocaleString()
        : '',
      icon: 'material-symbols:date-range-outline',
    },
    {
      name: 'ProcessTime',
      label: 'Process Time',
      color: '#DF3E30',
      // format process time to minutes, seconds, milliseconds(0 minutes, 10 seconds, 100 milliseconds)
      value: runOutputInfo?.processTime
        ? new Date(runOutputInfo.processTime).toISOString().substr(14, 5)
        : '',
      icon: 'mdi:stopwatch-outline',
    },
    {
      name: 'modelName',
      label: 'Model Name',
      color: '#006097',
      value: runOutputInfo?.modelName,
      icon: 'tabler:box-model',
    },
    {
      name: 'InputTokenCount',
      label: 'Input Token Count',
      color: '#006097',
      value: runOutputInfo?.inputTokenCount,
      icon: 'material-symbols-light:input',
    },
    {
      name: 'OutputTokenCount',
      label: 'Output Token Count',
      color: '#1C9CEA',
      value: runOutputInfo?.outputTokenCount,
      icon: 'material-symbols-light:output',
    },
  ];
  return (
    <Dialog sx={{ zIndex: 4400 }} fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        {' '}
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span>Output Info</span>
          <IconButton onClick={onClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ my: 2 }}>
        <Stack spacing={2} sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          <Box
            display="grid"
            gap={2}
            gridTemplateColumns={
              runOutputInfo && !!runOutputInfo?.modelProvider
                ? 'repeat(3, 1fr)'
                : 'repeat(2, 1fr)'
            }
            sx={{ p: 3, width: '100%' }}
          >
            {outputInfoStats
              .filter((o) => o.value !== null)
              .map((outputInfoStat) => (
                <Paper
                  key={outputInfoStat.label}
                  variant="outlined"
                  sx={{ py: 2.5, textAlign: 'center' }}
                >
                  {outputInfoStat.name === 'modelProvider' && (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          component="img"
                          src={`/assets/images/integrations/${outputInfoStat.value}.svg`}
                          sx={{
                            maxWidth: 40,
                            maxHeight: 40,
                            justifyContent: 'center',
                            objectFit: 'cover',
                          }}
                        />
                        {/* <MuiImage
                   
                      alt="icon"
                      src={`/assets/images/integrations/${outputInfoStat.value}.svg`}
                      sx={{ maxWidth: 40, maxHeight: 42}}
                    /> */}
                      </Box>
                    </>
                  )}
                  {outputInfoStat.name !== 'modelProvider' && (
                    <Iconify icon={outputInfoStat.icon} color={outputInfoStat.color} width={32} />
                  )}
                  <Typography variant="h6" sx={{ mt: 0.5 }}>
                    {outputInfoStat.value}
                  </Typography>

                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {outputInfoStat.label}
                  </Typography>
                </Paper>
              ))}
          </Box>
          {runOutputInfo?.completePrompt && (
            <Paper
              key=""
              variant="outlined"
              sx={{
                py: 1.5,
                mx: 1,
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                flexGrow: 1,
                height: '100%',
                width: '50%',
              }}
            >
              <Typography variant="subtitle2" sx={{ textAlign: 'center', paddingBottom: 1 }}>
                User Prompt
              </Typography>

              <Divider sx={{ mb: 1 }} />
              <PromptEditor
                promptInput={runOutputInfo?.completePrompt as string}
                readonly
                setPromptInput={(prompt: string) => {}}
              />
            </Paper>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
