import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { EmailProviderApi } from 'src/api';
import { ITagState } from 'src/@types/tags';

// ----------------------------------------------------------------------

const initialState: ITagState = {
  tags: {},
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    // START LOADING
    startFetching(state) {
      state.loading = true;
    },
    fetchSegmentSuccess(state, action) {
      state.loading = false;
      if (!state.tags[action.payload.provider]) {
        state.tags[action.payload.provider] = {};
      }
      if (!state.tags[action.payload.provider][action.payload.listId]) {
        state.tags[action.payload.provider][action.payload.listId] = [];
      }
      state.tags[action.payload.provider][action.payload.listId] = action.payload.tags;
      state.loaded = true;
    },
    deleteSegment(state, action) {
      state.tags[action.payload.provider][action.payload.listId] = state.tags[
        action.payload.provider
      ][action.payload.listId].filter((tag) => tag.id !== action.payload);
    },
    deleteTagsSuccess(state, action) {
      
      state.tags[action.payload.provider][action.payload.listId] = state.tags[
        action.payload.provider
      ][action.payload.listId].filter((tag) => !action.payload.tagsId.includes(tag.id));
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchTags(provider: string, listId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetching());
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderGetTagsGet(provider, listId);
      // dispatch(slice.actions.fetchSegmentSuccess({ provider, listId, tags: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteTags(
  tagsIds: number[],
  provider: string,
  listId: string,
  enqueueSnackbar: any
) {
  return async (dispatch: Dispatch) => {
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderTagsDelete({
      //   providerName: provider,
      //   listId,
      //   tagsIds,
      // });
      enqueueSnackbar('Tags were deleted succesfully', { variant: 'success' });
      dispatch(slice.actions.deleteTagsSuccess({ tagsIds, provider, listId }));
    } catch (error) {
      enqueueSnackbar('Error deleting tags', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    }
  };
}
