import { Helmet } from "react-helmet-async";
import ModernLoginView from "./modern-login-view";

export default function ModernLoginPage() {
    return (
      <>
        <Helmet>
          <title>Login</title>
        </Helmet>
  
        <ModernLoginView />
      </>
    );
  }
  