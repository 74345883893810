import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';
import Label from '../label';
import Iconify from '../iconify';
import { LogoBox } from './LogoBox';
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();
    const authenticated = useSelector((state) => state.auth.authenticated);
    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg
          id="Layer_2"
          data-name="Layer 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 230.32 230.62"
        >
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <polygon
                className="cls-1"
                style={{ fill: '#45ce4e' }}
                points="226.75 0 189.58 39.62 120.39 113.4 87.77 148.2 65.23 172.22 32.61 206.99 22.29 196 10.07 182.96 0 172.22 32.61 137.44 55.16 113.4 87.77 78.64 161.52 0 226.75 0"
              />
              <polygon
                className="cls-1"
                style={{ fill: '#45ce4e' }}
                points="120.08 0 67.06 56.54 34.43 91.33 25.38 81.65 1.81 56.54 34.43 21.77 54.85 0 120.08 0"
              />
              <polygon
                className="cls-1"
                style={{ fill: '#45ce4e' }}
                points="230.32 230.62 165.08 230.62 110.07 171.97 127.82 153.02 142.69 137.18 230.32 230.62"
              />
              <polygon
                className="cls-1"
                style={{ fill: '#45ce4e' }}
                points="120.02 230.62 55.04 230.62 72.67 211.84 87.54 195.98 88.24 196.74 120.02 230.62"
              />
            </g>
          </g>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <>
        <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
          <LogoBox ref={ref} height={40} width={40} sx={sx} />
          {/* {logo} */}
        </Link>
        {authenticated && (
          <Label color="success" sx={{ position: 'absolute', left: 80, top: '20px' }}>
            Beta
          </Label>
        )}
      </>
    );
  }
);

export default Logo;
