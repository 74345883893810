import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ConfigurationLogic, ConfigurationsApi } from 'src/api';
import { useSnackbar } from 'src/components/snackbar';
import { useDispatch } from 'src/redux/store';
import { upsertConfiguration } from 'src/redux/slices/configurations';

interface Props {
  onClose: () => void;
  open: boolean;
  configuration?: ConfigurationLogic;
  saveNew?: boolean;
}

export default function SaveConfigurationsDialog({ onClose, open, configuration, saveNew }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [savingConfigurations, setSavingConfigurations] = React.useState(false);
  const [configurationName, setConfigurationName] = React.useState('');
  const dispatch = useDispatch();
  const save = async () => {
    const saveConf = {
      ...configuration,
      name: configurationName,
      id: saveNew ? null : configuration?.id,
    };
    
    dispatch(
      upsertConfiguration(
        {
          ...configuration,
          name: configurationName,
          id: saveNew ? null : configuration?.id,
        },
        enqueueSnackbar
      )
    );
    setConfigurationName('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ zIndex: 1600 }}>
      <DialogTitle>Save Configurations</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          value={configurationName}
          onChange={(e) => setConfigurationName(e.target.value)}
          label="Configurations Name"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={savingConfigurations} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={savingConfigurations} onClick={save}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
