import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
// components
import Iconify from 'src/components/iconify';
// assets
import { EmailInboxIcon } from 'src/assets/icons';
import RouterLink from 'src/components/router/router-link';
import RHFCode from 'src/components/hook-form/rhf-code';
import { PATH_AUTH, PATH_MARKETING } from 'src/routes/paths';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useLocation, useParams } from 'react-router';
import { Link } from '@mui/material';
import { AccountApi } from 'src/api';
import { useSnackbar } from 'src/components/snackbar';

// ----------------------------------------------------------------------

export default function ModernVerifyView() {
  const location = useLocation();

  // Function to parse query parameters
  const useQuery = () => new URLSearchParams(location.search);

  const { enqueueSnackbar } = useSnackbar();

  const query = useQuery();
  const registeredEmail = query.get('email');

  const VerifySchema = Yup.object().shape({
    code: Yup.string().min(6, 'Code must be at least 6 characters').required('Code is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  });

  const defaultValues = {
    code: '',
    email: registeredEmail ?? '',
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <RHFTextField
        disabled
        name="email"
        label="Email"
        placeholder="example@gmail.com"
        InputLabelProps={{ shrink: true }}
      />

      <RHFCode name="code" />

      <LoadingButton fullWidth size="large" type="submit" variant="outlined" loading={isSubmitting}>
        Verify
      </LoadingButton>

      <Typography variant="body2">
        {`Don’t have a code? `}
        <Link
          variant="subtitle2"
          sx={{
            cursor: 'pointer',
          }}
        >
          Resend code
        </Link>
      </Typography>

      <Link
        component={RouterLink}
        href={PATH_AUTH.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Return to sign in
      </Link>
    </Stack>
  );

  const renderHead = (
    <>
      <EmailInboxIcon sx={{ height: 96 }} />

      <Stack spacing={2} sx={{ my: 5 }}>
        <Typography variant="h3">Please check your email!</Typography>

        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          To complete your registration, we need to verify your email address.
        </Typography>
        <Typography variant="h5" sx={{ color: 'text.secondary' }}>
          What happens next?
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          We’ve sent a verification email to{' '}
          <Link
            variant="subtitle2"
            sx={{
              cursor: 'pointer',
            }}
          >
            {registeredEmail}.{' '}
          </Link>
          In the email, you will find a link. Click on it to verify your email address.
        </Typography>
        <Typography variant="h5" sx={{ color: 'text.secondary' }}>
          Didn’t receive the email?
        </Typography>
        <Link
          variant="subtitle2"
          sx={{
            cursor: 'pointer',
          }}
          onClick={async () => {
            const accountApi = new AccountApi();
            const resendVerification =
              await accountApi.apiAccountResendNewAccountEmailVerificationPost({
                email: registeredEmail,
              });
              enqueueSnackbar('Verification email has been sent', { variant: 'success' });
          }}
        >
          Resend Verification Email
        </Link>
        <Link
          component={RouterLink}
          href={PATH_AUTH.login}
          color="inherit"
          variant="subtitle2"
          sx={{
            alignItems: 'center',
          }}
        >
          Return to sign in
        </Link>
      </Stack>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {/* {renderForm} */}
    </FormProvider>
  );
}
