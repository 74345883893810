import { Box, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { ModelType, llmModels } from 'src/common/constants/llm-models.constants';
import { RHSelect } from 'src/components/hook-form';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  disabled?: boolean;
  label: string;
  name: string;
  helperText?: string
  onSelect: (mode: string) => void;
  selectedInput?: string | null | undefined;
  options: any[];
}

export default function GenericSelect({ name,disabled, label, helperText,maxWidth,options, onSelect, selectedInput }: Props) {
  

  const [selected, setSelected] = useState<string>();
  return (
    <>
      <RHSelect
        key={name}
        name={name}
        disabled = {disabled}
        zIndex={3400}
        size="small"
        sx={{ maxWidth: maxWidth as number }}
        label={label}
        helperText="Select the model you want to use. Without selected connection there will be just one available Model to select from."
        value={selectedInput ?? selected}
        onChange={(event) => {
          setSelected(event.target.value);
          onSelect(event.target.value);
        }}
        InputLabelProps={{ shrink: true }}
      >
        {options.map((option) => (
          <MenuItem disabled={option.disabled} value={option.value}>
            <span>{option.label}</span>
          </MenuItem>
        ))}
      </RHSelect>
    </>
  );
}
