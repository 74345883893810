import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'src/redux/store';
import { EmailTemplate } from 'src/@types/email-template';
import { EmailTemplateApi, TemplateApi } from 'src/api';
import { useSnackbar } from 'src/components/snackbar';

interface Props {
  onClose: () => void;
  open: boolean;
}

export default function SaveNewTemplateDialog({ onClose, open }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const templates = useSelector((state) => state.emailTemplates.templates);
  const storeSelectedTemplateId = useSelector((state) => state.campaignForms.selectedTemplate?.id);
  const [savingTemplate, setSavingTemplate] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState<EmailTemplate | undefined>(
    storeSelectedTemplateId
      ? templates.find((template) => template.id === storeSelectedTemplateId)
      : undefined
  );

  const saveTemplate = async () => {
    const toSaveTemplate = [selectedTemplate as EmailTemplate];
    const api = new EmailTemplateApi();
    setSavingTemplate(true);
    const result = await api.apiEmailTemplateSaveTemplatesPost({templatesData: toSaveTemplate});
    enqueueSnackbar('Save template success!');
    setSavingTemplate(false);
    onClose();
  };

  React.useEffect(() => {
    const selTemplate = storeSelectedTemplateId
      ? templates.find((template) => template.id === storeSelectedTemplateId)
      : undefined;
    setSelectedTemplate(selTemplate);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Save Template</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          value={selectedTemplate?.name}
          onChange={(e) => setSelectedTemplate({ ...selectedTemplate, name: e.target.value })}
          label="Template Name"
          type="email"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button disabled = {savingTemplate} onClick={onClose}>Cancel</Button>
        <Button disabled = {savingTemplate} onClick={saveTemplate}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
