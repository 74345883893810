import { useEffect, useRef, useState } from 'react';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _notifications } from '../../../_mock/arrays';
// components
import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { useDispatch, useSelector } from 'src/redux/store';
import { updateNotifications } from 'src/redux/slices/notifications';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NotificationLogic } from 'src/api';
import _ from 'lodash';
import {
  notificationIconByType,
  notificationTitleByType,
} from 'src/common/constants/notifications.constants';
import { useNavigate } from 'react-router';
import { PATH_MARKETING } from 'src/routes/paths';
import { StyledScrollbarDiv } from 'src/components/scrollbar/styles';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const dispatch = useDispatch();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const allNotifications = useSelector((state) => state.notifications.notifications);
  const [notifications, setNotifications] = useState(allNotifications.slice(0, 20));
  const [hasMore, setHasMore] = useState<boolean>(true);
  // const notifications: NotificationLogic[] = [
  //   {
  //     read: true,
  //     createdAt: new Date(),
  //     id: 1,
  //     type: 'flow_started',
  //     parameters: ['Verga_2'],
  //   },
  // ];

  const fetchMoreData = () => {
    if (notifications.length >= allNotifications.length) {
      setHasMore(false);
      return;
    }
    // Fetch more data
    setTimeout(() => {
      setNotifications(
        notifications.concat(
          allNotifications.slice(notifications.length, notifications.length + 20)
        )
      );
    }, 1000);
  };

  useEffect(() => {
    if (openPopover) {
      let unreadNotifications = _.cloneDeep(allNotifications.filter((item) => !item.read));
      if (unreadNotifications.length > 0) {
        unreadNotifications = unreadNotifications.map((item) => {
          item.read = true;
          return item;
        });
        dispatch(updateNotifications(unreadNotifications));
      }
      setNotifications(allNotifications.slice(0, 20));
    }
  }, [allNotifications, openPopover, dispatch]);

  const totalUnRead = allNotifications.filter((item) => !item.read).length;

  const scrollbarRef = useRef();

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = () => {
    let unreadNotifications = _.cloneDeep(allNotifications.filter((item) => !item.read));
    unreadNotifications = unreadNotifications.map((item) => {
      item.read = true;
      return item;
    });
    dispatch(updateNotifications(unreadNotifications));
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>

            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography> */}
          </Box>

          {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="material-symbols:mark-email-read-outline" />
              </IconButton>
            </Tooltip>
          )} */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <StyledScrollbarDiv ref={scrollbarRef} sx={{ maxHeight: { sm: 465 } }} id="scrollableDiv">
          <InfiniteScroll
            dataLength={notifications.length}
            next={fetchMoreData}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
            loader={
              <Box display="flex" justifyContent="center">
                <Typography variant="body2">Loading...</Typography>
              </Box>
            }
            endMessage={
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Yay! You have seen it all
                </Typography>
              </Box>
            }
          >
            {notifications.length === 0 && (
              <Box sx={{ p: 2 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  There are no notifications
                </Typography>
              </Box>
            )}
            <List disablePadding>
              {notifications.map((notification) => (
                <NotificationItem handleClosePopover={handleClosePopover} key={notification.id} notification={notification} />
              ))}
            </List>
          </InfiniteScroll>
        </StyledScrollbarDiv>

        {/* <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

const getFormattedTitleByTypeAndParams = (type: string, parameters: string[]) => {
  let title = notificationTitleByType[type as keyof typeof notificationTitleByType];
  if (!title) {
    return '';
  }
  if (!parameters || parameters?.length < 2) {
    return title.replace(/\{\{(\d+)\}\}/g, '');
  }
  for (let i = 1; i < parameters.length; i++) {
    title = title.replace(`{{${i}}}`, parameters[i]);
  }
  return title;
};

// ----------------------------------------------------------------------

type NotificationItemProps = {
  notification: NotificationLogic;
  handleClosePopover?: () => void;
};

function NotificationItem({ notification, handleClosePopover }: NotificationItemProps) {
  const { avatar, title } = renderContent(notification);
  const navigate = useNavigate();

  const handleNotificationClickByType = (type: string) => {
    if (!notification?.parameters || notification?.parameters?.length < 2) {
      return;
    }
    if (type === 'flow_started' || (type === 'flow_completed' && notification.parameters[1])) {
      handleFlowNotificationClick(notification?.parameters[1]);
    }
    console.log('notification', notification);
    if (
      type === 'add_comment' &&
      notification.parameters.length > 3 &&
      notification.parameters[1] &&
      notification.parameters[2] &&
      notification.parameters[3]
    ) {
      //   navigate(`${PATH_MARKETING.pages.outputs}/${notification.parameters[1]}`);
      navigate(
        `${PATH_MARKETING.pages.outputs}?header=${notification.parameters[3]}&outputId=${notification.parameters[2]}`
      );
      if (handleClosePopover) {
        handleClosePopover();
      }
    }
  };

  const handleFlowNotificationClick = (runNumber: string) => {
    const runNumberLong = parseFloat(runNumber);
    if (runNumberLong) {
      navigate(`${PATH_MARKETING.pages.aiFlowsRuns}/${runNumberLong}`);
    }
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.read && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => handleNotificationClickByType(notification.type as string)}
      disableRipple
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <Box sx={{ position: 'absolute', right: 5, top: 30 }}>
        {!notification.read && (
          <Tooltip title=" Mark as read">
            <IconButton
              color="inherit"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Iconify icon="material-symbols:mark-email-read-outline" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
            <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">{fToNow(notification.createdAt as Date)}</Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: NotificationLogic) {
  const title = (
    <Typography variant="subtitle2">
      {notification?.parameters && notification?.parameters.length > 0
        ? notification?.parameters[0]
        : ''}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp;{' '}
        {getFormattedTitleByTypeAndParams(
          notification.type as keyof typeof notificationTitleByType,
          notification.parameters as string[]
        )}
      </Typography>
    </Typography>
  );
  return {
    avatar: (
      <Iconify
        icon={notificationIconByType[notification.type as keyof typeof notificationTitleByType]}
      />
    ),
    title,
  };
}
