import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { CraftApi, EmailProviderApi, UserContentSnippet } from 'src/api';
import { IEditorState } from 'src/@types/editor';

// ----------------------------------------------------------------------

const initialState: IEditorState = {
  contentTags: [],
  contentSnippets: [],
  fullScreen: false,
  error: null,
};

const slice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    // START LOADING
    fetchContentTagsSuccess(state, action) {
      state.contentTags = action.payload;
    },
    fetchContentSnippetsSuccess(state, action) {
      state.contentSnippets = action.payload;
    },
    deleteContentSnippetsSuccess(state, action) {
      state.contentSnippets = state.contentSnippets.filter((snippet) => !action.payload.includes(snippet.id));
    },
    addContentSnippetSuccess(state, action) {
      state.contentSnippets = [...(state.contentSnippets as UserContentSnippet[]), action.payload];
    },
    setFullScreen(state, action) {
      state.fullScreen = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { setFullScreen, hasError } = slice.actions;

// ----------------------------------------------------------------------

// export function fetchContentTags() {
//   return async (dispatch: Dispatch) => {
//     try {
//       const craftApi = new CraftApi();
//       const response = await craftApi.apiContentContentTagsGet();
//       dispatch(slice.actions.fetchContentTagsSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
// export function fetchContentSnippets() {
//   return async (dispatch: Dispatch) => {
//     try {
//       const craftApi = new CraftApi();
//       const response = await craftApi.apiContentContentSnippetsGet();
//       dispatch(slice.actions.fetchContentSnippetsSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function saveNewContentSnippet(name: string, content: string) {
//   return async (dispatch: Dispatch) => {
//     try {
//       const craftApi = new CraftApi();
//       const response = await craftApi.apiContentContentSnippetPost({
//         name,
//         content,
//       });
//       dispatch(slice.actions.addContentSnippetSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
// export function deleteContentSnippets(ids:number[],enqueueSnackbar: any){
//   return async (dispatch: Dispatch) => {
//     try {
//       const craftApi = new CraftApi();
//       const response = await craftApi.apiContentContentSnippetDelete({snippetContentIds: ids});
//       dispatch(slice.actions.deleteContentSnippetsSuccess(ids));
//       enqueueSnackbar("Snippet(s) deleted successfully", { variant: 'success' });
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }