import { Button, IconButton, Paper, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FilesApi, FilesRepoApi } from 'src/api';
import FilesUploadDialog from 'src/common/modals/FilesUploadDialog';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';
import Iconify from 'src/components/iconify';
import PromptEditor from 'src/components/prompt-editor/PromptEditor';
import ProviderSelect from 'src/components/provider-select/ProviderSelect';
import { MultiFilePreview } from 'src/components/upload';
import { useBoolean } from 'src/hooks/use-boolean';
import { updateSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import { downloadFile } from 'src/utils/fileUtils';
import AiFlowInputMailchimpActionSetup from './providers/mailchimp/AiFlowInputMailchimpActionSetup';
import AiFlowInputGoogleAnalyticsActionSetup from './providers/google-analytics/AiFlowInputGoogleAnalyticsActionSetup';
import AiFlowInputHubSpotActionSetup from './providers/hubspot/AiFlowInputHubSpotActionSetup';
import AiFlowInputSalesforceActionSetup from './providers/salesforce/AiFlowInputSalesforceActionSetup';
import ConnectionSelect from 'src/components/connection/ConnectionSelect';
import FilesRepoSelect from 'src/pages/files-repos/FilesRepoSelect';
import FilesRepoFileSelect from 'src/pages/files-repos/FilesRepoFileSelect';

export default function AiFlowInputActionSetup() {
  const dispatch = useDispatch();
  const [inputOptions, setInputOptions] = useState([
    { label: 'Input', value: 'Input', disabled: false },
    { label: 'File', value: 'File', disabled: false },
    // { label: 'Web Crawler', value: 'Web Crawler', disabled: false },
    { label: 'Provider', value: 'Provider', disabled: false },
  ]);
  const [fileInputTypeOptions, setFileInputTypeOptions] = useState([
    { label: 'Files Repository', value: 'Files Repo', disabled: false },
    { label: 'Upload', value: 'Upload', disabled: false },
    { label: 'URL', value: 'URL', disabled: false },
  ]);
  const [espImportTypes, setEspImportTypes] = useState([
    { label: 'Templates', value: 'Templates' },
    { label: 'Campaigns Emails', value: 'Campaigns Emails' },
  ]);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [input, setInput] = useState<string>('');
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const [filesRepoFiles, setFilesRepoFiles] = useState<any[]>([]);
  const [filesUrl, setFilesUrl] = useState<string[]>([]);
  const [selectedFileInputType, setSelectedFileInputType] = useState<string>('Files Repo');
  const [provider, setProvider] = useState<string>('');
  const [selectedConnection, setSelectedConnection] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<(File | string)[]>([]);
  const [initialInputContent, setInitialInputContent] = useState<string>();
  const [selectedFilesRepoName, setSelectedFilesRepoName] = useState<any>();
  const [selectedFilesRepoFile, setSelectedFilesRepoFile] = useState<any>();
  const [selectedInputOption, setSelectedInputOption] = useState<string>('Input');
  const [selectedEspImportType, setSelectedEspImportType] = useState<string>('Templates');
  const openUploadFileDialog = useBoolean(false);
  const filesRepos = useSelector((state) => state.filesRepo.filesRepos);
  const updateSelectedItem = (content: string) => {
    const updatedItem = {
      ...selectedItem,
      inputData: {
        ...selectedItem?.inputData,
        actionInputData: {
          ...selectedItem?.inputData?.actionInputData,
          inputData: {
            ...selectedItem?.inputData?.actionInputData?.inputData,
            inputType: selectedInputOption,
            input: content,
          },
        },
      },
    };
    dispatch(updateSelectedAiFlowItem(updatedItem));
  };

  useEffect(() => {
    if (runsMode) {
      // add disabled to the input fields
      setInputOptions(inputOptions.map((option) => ({ ...option, disabled: true })));
      setFileInputTypeOptions(
        fileInputTypeOptions.map((option) => ({ ...option, disabled: true }))
      );
    }
  }, [runsMode, inputOptions, fileInputTypeOptions]);

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.inputData?.actionInputData?.inputData?.inputType) {
        setSelectedInputOption(selectedItem.inputData?.actionInputData?.inputData?.inputType);
      }
      if (selectedItem.inputData?.actionInputData?.inputData) {
        if (!initialInputContent) {
          setInitialInputContent(
            selectedItem.inputData?.actionInputData?.inputData?.input as string
          );
        }
        if (selectedItem.inputData?.actionInputData?.inputData?.input) {
          setInput(selectedItem.inputData?.actionInputData?.inputData?.input);
        }
        if (selectedItem.inputData?.actionInputData?.inputData?.urlFiles) {
          setFilesUrl(selectedItem.inputData?.actionInputData?.inputData?.urlFiles as string[]);
        }
        if (selectedItem.inputData?.actionInputData?.inputData?.providerData) {
          setProvider(
            selectedItem.inputData?.actionInputData?.inputData?.providerData?.provider as string
          );
        }
        setUploadedFiles(
          selectedItem.inputData?.actionInputData?.inputData?.uploadedFilesNames as (
            | File
            | string
          )[]
        );
        if (selectedItem.inputData?.actionInputData?.inputData?.fileInputType) {
          setSelectedFileInputType(
            selectedItem.inputData?.actionInputData?.inputData?.fileInputType as string
          );
        }
        if (selectedItem.inputData?.actionInputData?.inputData?.providerData) {
          setSelectedConnection(
            selectedItem.inputData?.actionInputData?.inputData?.providerData?.connectionId as number
          );
        }
      }
    }
  }, [selectedItem, initialInputContent]);

  useEffect(() => {
    const getFiles = async (kbId: number) => {
      const filesRepoApi = new FilesRepoApi();
      const filesResponse = await filesRepoApi.apiFilesRepoFilesFilesRepoIdGet(kbId);
      setFilesRepoFiles(filesResponse.data);
    };
    if (selectedInputOption === 'File' && selectedFileInputType === 'Files Repo') {
      if (
        !selectedItem?.inputData?.actionInputData?.inputData?.filesRepoId &&
        !selectedFilesRepoName
      ) {
        const firstFilesRepo = filesRepos[0];
        if (firstFilesRepo) {
          setSelectedFilesRepoName(firstFilesRepo.name);
          getFiles(firstFilesRepo.id as number);
          const updatedItem = {
            ...selectedItem,
            inputData: {
              ...selectedItem?.inputData,
              actionInputData: {
                ...selectedItem?.inputData?.actionInputData,
                inputData: {
                  ...selectedItem?.inputData?.actionInputData?.inputData,
                  filesRepoId: firstFilesRepo.id,
                },
              },
            },
          };
          dispatch(updateSelectedAiFlowItem(updatedItem));
        }
      } else {
        const kbTmp = filesRepos.find(
          (kb) => kb.id === selectedItem?.inputData?.actionInputData?.inputData?.filesRepoId
        );
        if (kbTmp) {
          setSelectedFilesRepoName(kbTmp.name);
          getFiles(kbTmp.id as number);
          setSelectedFilesRepoFile(
            selectedItem?.inputData?.actionInputData?.inputData?.filesRepoFile
          );
        }
      }
    }
  }, [
    filesRepos,
    selectedInputOption,
    dispatch,
    selectedFilesRepoName,
    selectedItem,
    selectedFileInputType,
  ]);

  return (
    <>
      <Stack spacing={3} sx={{ mt: 2, mx: 1 }}>
        <RHRadioGroup
          value={selectedInputOption}
          onChange={(event) => {
            setSelectedInputOption(event.target.value);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  inputData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData,
                    inputType: event.target.value,
                  },
                },
              },
            };
            dispatch(updateSelectedAiFlowItem(updatedItem));
          }}
          row
          spacing={4}
          name="exportOptions"
          options={inputOptions}
        />
        {selectedInputOption === 'Input' && (
          // <SmartEditor
          //   height={fullScreen ? 550 : 400}
          //   initialInputContent={initialInputContent}
          //   onEditorChange={(content) => {
          //     updateSelectedItem(content);
          //   }}
          //   inputTemplateId={undefined}
          //   provider={null}
          //   toolbarType="minimal"
          // />
          <>
            <Paper
              key=""
              variant="outlined"
              sx={{
                py: 1.5,
                mx: 1,
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                flexGrow: 1,
                height: '100%',
              }}
            >
              <Typography variant="subtitle2" sx={{ textAlign: 'center', paddingBottom: 1 }}>
                Text Input
              </Typography>
              <PromptEditor
                promptInput={initialInputContent ?? ''}
                setPromptInput={updateSelectedItem}
              />
            </Paper>
          </>
        )}
        {selectedInputOption === 'File' && (
          <Stack spacing={4}>
            <RHRadioGroup
              value={selectedFileInputType} // upload/url
              onChange={(event) => {
                setSelectedFileInputType(event.target.value);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      inputData: {
                        ...selectedItem?.inputData?.actionInputData?.inputData,
                        fileInputType: event.target.value,
                      },
                    },
                  },
                };
                dispatch(updateSelectedAiFlowItem(updatedItem));
              }}
              row
              spacing={4}
              name="exportOptions"
              options={fileInputTypeOptions}
            />
            {selectedFileInputType === 'Files Repo' && (
              <>
                <FilesRepoSelect
                  onFilesRepoSelect={async (kbl) => {
                    const filesRepoApi = new FilesRepoApi();
                    const filesResponse =
                      await filesRepoApi.apiFilesRepoFilesFilesRepoIdGet(
                        kbl.id as number
                      );
                    setFilesRepoFiles(filesResponse.data);
                    const updatedItem = {
                      ...selectedItem,
                      inputData: {
                        ...selectedItem?.inputData,
                        actionInputData: {
                          ...selectedItem?.inputData?.actionInputData,
                          inputData: {
                            ...selectedItem?.inputData?.actionInputData?.inputData,
                            filesRepoId: kbl.id,
                          },
                        },
                      },
                    };
                    dispatch(updateSelectedAiFlowItem(updatedItem));
                  }}
                />

                <FilesRepoFileSelect
                selectedFilesRepoFileInput={selectedFilesRepoFile as string}
                  filesRepoFiles={filesRepoFiles}
                  onFilesRepoFileSelect={(selectedFile) => {
                    const updatedItem = {
                      ...selectedItem,
                      inputData: {
                        ...selectedItem?.inputData,
                        actionInputData: {
                          ...selectedItem?.inputData?.actionInputData,
                          inputData: {
                            ...selectedItem?.inputData?.actionInputData?.inputData,
                            filesRepoFile: selectedFile,
                          },
                        },
                      },
                    };
                    dispatch(updateSelectedAiFlowItem(updatedItem));
                  }}
                />
              </>
            )}
            {selectedFileInputType === 'Upload' && (
              <>
                <MultiFilePreview
                  disabled={runsMode}
                  files={uploadedFiles}
                  onDownload={async (file: any) => {
                    const filesApi = new FilesApi();
                    const response = await filesApi.apiFilesDownloadContainerNameGet(
                      'inputs',
                      `${selectedItem?.id ?? 'Temp'}/${file as string}`,
                      {
                        responseType: 'blob',
                      }
                    );
                    if (response.data) {
                      downloadFile(response, file);
                    }
                  }}
                  onRemove={(fileName: any) => {
                    const filtered = uploadedFiles.filter((file) => file !== fileName);
                    setUploadedFiles(filtered);
                    const updatedItem = {
                      ...selectedItem,
                      inputData: {
                        ...selectedItem?.inputData,
                        actionInputData: {
                          ...selectedItem?.inputData?.actionInputData,
                          inputData: {
                            ...selectedItem?.inputData?.actionInputData?.inputData,
                            uploadedFilesNames: filtered,
                            fileInputType: selectedFileInputType,
                          },
                        },
                      },
                    };
                    dispatch(updateSelectedAiFlowItem(updatedItem));
                  }}
                />
                <Button
                  fullWidth
                  disabled={runsMode}
                  onClick={openUploadFileDialog.onTrue}
                  startIcon={<Iconify icon="mdi:file-upload-outline" />}
                  variant="outlined"
                  size="large"
                  color="inherit"
                  sx={{
                    mt: 2,
                    minWidth: { md: 350 },
                    maxHeight: { md: 40 },
                  }}
                >
                  Upload File
                </Button>
              </>
            )}
            {selectedFileInputType === 'URL' && (
              <>
                {filesUrl.length === 0 && (
                  <>
                    <Stack alignItems="center" spacing={2}>
                      <TextField
                        fullWidth
                        onChange={(event) => {
                          const updatedItem = {
                            ...selectedItem,
                            inputData: {
                              ...selectedItem?.inputData,
                              actionInputData: {
                                ...selectedItem?.inputData?.actionInputData,
                                inputData: {
                                  ...selectedItem?.inputData?.actionInputData?.inputData,
                                  urlFiles: [event.target.value],
                                  fileInputType: selectedFileInputType,
                                },
                              },
                            },
                          };
                          dispatch(updateSelectedAiFlowItem(updatedItem));
                          //    setFilesUrl([event.target.value]);
                        }}
                        value={filesUrl[0]}
                        placeholder="Enter File URL"
                        sx={{
                          minWidth: 350,
                        }}
                        label="File URL"
                      />
                      <IconButton
                        sx={{
                          borderRadius: 1,
                          borderColor: 'primary.main',
                          borderWidth: 2,
                          border: '1px solid',
                          justifyContent: 'center',
                          alignItems: 'center',

                          width: 80,
                        }}
                        onClick={() => {
                          // openSelectRunDialog.onTrue();
                        }}
                      >
                        <Iconify sx={{ width: 24, height: 24 }} icon="material-symbols:add" />
                      </IconButton>{' '}
                    </Stack>
                  </>
                )}

                {filesUrl.map((url, index) => (
                  <TextField
                    fullWidth
                    onChange={(event) => {
                      const newFileUrls = filesUrl
                        .slice(0, index)
                        .concat(event.target.value, filesUrl.slice(index + 1));
                      setFilesUrl(newFileUrls);
                      const updatedItem = {
                        ...selectedItem,
                        inputData: {
                          ...selectedItem?.inputData,
                          actionInputData: {
                            ...selectedItem?.inputData?.actionInputData,
                            inputData: {
                              ...selectedItem?.inputData?.actionInputData?.inputData,
                              urlFiles: [
                                ...filesUrl.slice(0, index),
                                event.target.value,
                                ...filesUrl.slice(index + 1),
                              ],
                              fileInputType: selectedFileInputType,
                            },
                          },
                        },
                      };
                      dispatch(updateSelectedAiFlowItem(updatedItem));
                    }}
                    //    dispatch(updateSelectedAiFlowItem(updatedItem));

                    value={url}
                    placeholder="Enter File URL"
                    sx={{
                      minWidth: 350,
                    }}
                    label="File URL"
                  />
                ))}
              </>
            )}
          </Stack>
        )}
        {selectedInputOption === 'Web Crawler' && (
          <TextField
            fullWidth
            value="Https://Kuverto.com"
            placeholder="Enter URL to crawl"
            label="URL"
          />
        )}
        {selectedInputOption === 'Provider' && (
          <>
            <Stack spacing={3}>
              <ProviderSelect
                disabled={runsMode}
                selectedProviderInput={provider}
                handleSelectProvider={(selectedProvider) => {
                  setProvider(selectedProvider);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        inputData: {
                          ...selectedItem?.inputData?.actionInputData?.inputData,
                          providerData: {
                            ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                            provider: selectedProvider,
                          },
                        },
                      },
                    },
                  };
                  dispatch(updateSelectedAiFlowItem(updatedItem));
                }}
              />
              <ConnectionSelect
                disabled={runsMode}
                selectedIntegration={provider}
                selectedConnectionInput={selectedConnection}
                onConnectionSelect={(connectionId: number) => {
                  setSelectedConnection(connectionId);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        inputData: {
                          ...selectedItem?.inputData?.actionInputData?.inputData,
                          providerData: {
                            ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                            connectionId,
                          },
                        },
                      },
                    },
                  };
                  dispatch(updateSelectedAiFlowItem(updatedItem));
                }}
              />
              {provider === 'Mailchimp' && <AiFlowInputMailchimpActionSetup />}
              {provider === 'GoogleAnalytics' && <AiFlowInputGoogleAnalyticsActionSetup />}
              {provider === 'HubSpot' && <AiFlowInputHubSpotActionSetup />}
              {provider === 'Salesforce' && <AiFlowInputSalesforceActionSetup />}
            </Stack>
          </>
        )}
        <FilesUploadDialog
          open={openUploadFileDialog.value}
          onClose={openUploadFileDialog.onFalse}
        />
      </Stack>
    </>
  );
}
