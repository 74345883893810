// ----------------------------------------------------------------------

import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { ICreditStatusState } from 'src/@types/credit';
import { CreditApi } from 'src/api';

const initialState: ICreditStatusState = {
  creditStatus: null,
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'creditStatus',
  initialState,
  reducers: {
    // START LOADING
    setLoading(state, action) {
      state.loading = action.payload;
    },
    startLoading(state) {
      state.loading = true;
    },
    fetchCreditsStatusSuccess(state, action) {
      state.creditStatus = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    updateCreditStatus(state, action) {
      state.creditStatus = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.loaded = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { updateCreditStatus, startLoading } = slice.actions;

// ----------------------------------------------------------------------

export function fetchCreditsStatus() {
  return async (dispatch: Dispatch) => {
    try {
      const creditApi = new CreditApi();
      dispatch(slice.actions.startLoading()) ;
      const creditStatusResponse = await creditApi.apiCreditGet();
      dispatch(slice.actions.fetchCreditsStatusSuccess(creditStatusResponse.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}
