import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { FolderLogic } from 'src/api';
import { RHSelect } from 'src/components/hook-form';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  minWidth?: number;
  disabled?: boolean;
  onFolderSelect: (folder?: FolderLogic) => void;
  aiFlowFolderIds?: number[];
  selectedFolderIdInput?: number;
}

export default function FolderSelect({ maxWidth,disabled, onFolderSelect, aiFlowFolderIds, minWidth, selectedFolderIdInput }: Props) {
  const folders = useSelector((state) => state.folders.folders);
  const [selectedFolder, setSelectedFolder] = useState<FolderLogic>();
  const navSelectedFolder = useSelector((state) => state.folders.selectedFolder);
  return (
    <>
      <RHSelect
        key="selectFolder"
        zIndex={1400}
        disabled={disabled}
        name="selectFolder"
        size="small"
        sx={{ maxWidth: maxWidth ?? 150 , minWidth: minWidth ?? 150}}
        label="Select Folder"
        value={selectedFolderIdInput ?? selectedFolder?.id}
        onChange={(event) => {
          onFolderSelect(folders.find((folder) => folder.id === parseInt(event.target.value, 10)));
          setSelectedFolder(
            folders.find((folder) => folder.id === parseInt(event.target.value, 10))
          );
        }}
        InputLabelProps={{ shrink: true }}
        // sx={{ maxWidth: { md: 180 } }}
      >
        {folders.map((folder) => (
          <MenuItem
            disabled = {navSelectedFolder?.id === folder.id ||
              (aiFlowFolderIds && aiFlowFolderIds.includes(folder.id as number))}
            value={folder.id as number}
          >
            <span>{folder.name}</span>
          </MenuItem>
        ))}
      </RHSelect>
    </>
  );
}
