import { Container } from '@mui/system';
import { Helmet } from 'react-helmet-async';
import AiFlowPage from './AiFlowPage';

export default function NewAiFlowPage() {

  return (
    <>
      <Helmet>
        <title>New AI Flow</title>
      </Helmet>

      <Container maxWidth={false}>
        <AiFlowPage />
      </Container>
    </>
  );
}
