import { useSelector } from "src/redux/store";
import { RHAutocomplete } from "../hook-form/RHFAutocomplete";
import { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import { usePubSub } from "../pub-sub/PubSubProvider";
import _ from "lodash";

export default function PreviousActionsChips(){
    
    const [previousActions, setPreviousActions] = useState<string[]>([]);
    const runsMode = useSelector((state) => state.aiFlows.runsMode);
    const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
    const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
    const pubSub = usePubSub();
    useEffect(() => {
        const tmpPreviousActions = selectedAiFlow?.items?.filter(
          (item: any) => item && (item.sequence as number) < (selectedItem?.sequence as number)
        );
        const previousActionsStrArr = tmpPreviousActions?.map((item: any) => item.name as string) ?? [];
        if (!_.isEqual(previousActionsStrArr, previousActions)) {
          setPreviousActions(previousActionsStrArr);
        }
      }, [selectedAiFlow, selectedItem, previousActions]);
      
    return (
        <RHAutocomplete
        name="prevousActions"
        label="Previous Actions"
        disabled={runsMode}
        placeholder="+ Tags"
        size="small"
        sx={{ px: 1 }}
        multiple
        value={previousActions ?? []}
        freeSolo
        options={[]}
        getOptionLabel={(option) => option}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            {option}
          </li>
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => {
            const { onDelete, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // dispatch(
                  //   updateSelectedAiFlowItem({
                  //     ...selectedItem,
                  //     inputData: {
                  //       ...selectedItem?.inputData,
                  //       actionInputData: {
                  //         ...selectedItem?.inputData?.actionInputData,
                  //         promptInput: `${promptInput} {{{${option}}}}`,
                  //       },
                  //     },
                  //   })
                  // );
                  pubSub.publish('editor_focus', `{${option}}`);
                }}
                label={option}
                size="small"
                color="info"
                variant="soft"
                {...tagProps}
                // Explicitly set onDelete to undefined
                onDelete={undefined}
              />
            );
          })
        }
      />
    )
}