// utils

import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { IAuthState } from 'src/@types/auth';
import authService from 'src/auth/AuthorizeService';
import { setSession } from 'src/auth/utils';

// ----------------------------------------------------------------------

const initialState: IAuthState = {
  error: null,
  user: null,
  initzialized: false,
  authenticated: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginCallbackSuccess(state, action) {
      state.user = action.payload;
      state.initzialized = true;
      state.authenticated = true;
    },
    loginInitilized(state, action) {
      state.initzialized = true;
      state.authenticated = action.payload.isAuthenticated;
    },
    logoutSucess(state) {
      state.user = null;
      state.initzialized = true;
      state.authenticated = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { loginCallbackSuccess, loginInitilized, hasError } = slice.actions;

export function logout() {
  return async (dispatch: Dispatch) => {
    try {
      setSession(null);
      const authServ = authService;
      await authServ.signingOut();
      dispatch(slice.actions.logoutSucess());
    } catch (error) {
      
      dispatch(slice.actions.hasError(error));
    }
  };
}
