import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import Login from '../pages/auth/LoginPage';
import { useSelector } from 'src/redux/store';
import AuthModernLayout from 'src/sections/auth/modern';
import ModernLoginPage from 'src/pages/auth/modern/modern-login-page';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { pathname } = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.authenticated);
  const isInitizalized = useSelector((state) => state.auth.initzialized);
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  if (!isInitizalized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return (
      <AuthModernLayout>
        <ModernLoginPage />
      </AuthModernLayout>
    );
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
