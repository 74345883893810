import { AiFlowItemLogic } from 'src/api';
import { ActiveTypes } from 'src/common/constants/active-types.constants';
import { AiFlowItemType } from 'src/pages/aiFlows/enums/AiFlowItemType.enum';

export const isValidItemToBePlaced = (
  activeId?: number,
  itemBefore?: AiFlowItemLogic,
  itemAfter?: AiFlowItemLogic,
  header?: string,
  sequence?: number
) => {
  if (
    (header === 'Transformer' && sequence === 1 && activeId && activeId >= ActiveTypes.Action) ||
    (header === 'Extractor' && sequence === 1 && activeId && activeId >= ActiveTypes.Action) ||
    (header === 'Combine' && sequence === 1 && activeId && activeId >= ActiveTypes.Action) ||
    (header === 'Locator' && sequence === 1 && activeId && activeId >= ActiveTypes.Action) ||
    (header === 'Validator' && sequence === 1 && activeId && activeId >= ActiveTypes.Action) ||
    (header === 'FineTune' && sequence === 1 && activeId && activeId >= ActiveTypes.Action) ||
    (header === 'Embeddings' && sequence === 1 && activeId && activeId >= ActiveTypes.Action) ||
    (header === 'Embedding' && sequence === 1 && activeId && activeId >= ActiveTypes.Action) ||
    (header === 'Segmentation' && sequence === 1 && activeId && activeId >= ActiveTypes.Action) ||
    (header === 'Export' && sequence === 1 && activeId && activeId >= ActiveTypes.Action)
  ) {
    return false;
  }
  return true;
};

export const canRunFlowValidation = (items: AiFlowItemLogic[]) => {
  const everyGenerativeItemHasConnection = items?.every((item) => {
    if (item.inputData?.actionInputData?.promptInput) {
      return !!item.inputData?.actionInputData?.configuration?.connectionId;
    } else {
      return true;
    }
  });
  if (!everyGenerativeItemHasConnection) {
    return {
      error: 'Please configure the connection for all generative actions',
      validationSuccess: false,
    };
  }
  debugger;
  const everyInputItemHasAtleastOneUpload = items?.every((item) => {
    if (
      item.inputData?.actionInputData?.inputData &&
      item.inputData?.actionInputData?.inputData?.inputType === 'File' &&
      item.inputData?.actionInputData?.inputData?.fileInputType === 'Upload'
    ) {
      return (
        !!item.inputData?.actionInputData?.inputData?.uploadedFilesNames &&
        item.inputData?.actionInputData?.inputData?.uploadedFilesNames.length > 0
      );
    } else {
      return true;
    }
  });
  if (!everyInputItemHasAtleastOneUpload) {
    return {
      error: 'Please upload atleast one file for all input actions',
      validationSuccess: false,
    };
  }

  return {
    error: '',
    validationSuccess: true,
  };
};
