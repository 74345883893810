import { Helmet } from 'react-helmet-async';
import ModernNewPasswordView from './modern-new-password-view';
// sections

// ----------------------------------------------------------------------

export default function ModernNewPasswordPage() {
  return (
    <>
      <Helmet>
        <title> Auth Classic: New Password</title>
      </Helmet>

      <ModernNewPasswordView />
    </>
  );
}
