import { configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer, { rootPersistConfig } from './rootReducer';
import { isValidToken } from 'src/auth/utils';
import { loginInitilized } from './slices/auth';
import { InitStore } from 'src/utils/storeUtils';

// ----------------------------------------------------------------------

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

// Define AppDispatch here, after store has been created
export type AppDispatch = typeof store.dispatch;

const persistor = persistStore(store);

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

// Update useDispatch to correctly infer the type
const useDispatch = () => useAppDispatch<AppDispatch>();

// ----------------------------------------------------------------------

const accessToken = localStorage.getItem('accessToken');
const currentPath = window.location.pathname;

// Define the paths for which you don't want to run InitStore
const excludedPaths = [
  '/reset-email'  // Only specify the path, not the full URL
  // Add other paths as needed
];

if (accessToken && isValidToken(accessToken)) {
  store.dispatch(
    loginInitilized({
      isAuthenticated: true,
    })
  );
  // Only run InitStore if the current path is not in the excludedPaths list
  if (!excludedPaths.includes(currentPath)) {
    InitStore(store.dispatch);
  }
} else {
  store.dispatch(
    loginInitilized({
      isAuthenticated: false,
    })
  );
}
export { store, persistor, useSelector, useDispatch };