import { Card, TextField, Skeleton, MenuItem, Button } from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { TranslateModel } from 'src/@types/language';
import { LanguageApi, LanguageViewModel } from 'src/api';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { RHSelect } from 'src/components/hook-form';
import { RHAutocomplete } from 'src/components/hook-form/RHFAutocomplete';
import Scrollbar from 'src/components/scrollbar';
import { fetchSupportedLanguages } from 'src/redux/slices/language';
import { useDispatch, useSelector } from 'src/redux/store';
import { PATH_MARKETING } from 'src/routes/paths';

interface Props {
  sourceText: string;
  setTranslatedText?: (text: string) => void;
}

type OptionType = {
  value: string;
  label: string;
};

export default function TranslationPage({ sourceText, setTranslatedText }: Props) {
  const [targetText, setTargetText] = useState<string>('');
  const [newSourceText, setNewSourceText] = useState<string>(sourceText);
  const [selectedModel, setSelectedModel] = useState<TranslateModel>(
    TranslateModel.GoogleTranslate
  ); // ['googleTranslate', 'gpt'
  const [selectedTargetLanguageCode, setSelectedTargetLanguageCode] = useState<string>('en'); // ['en', 'zh'
  const [fetchingTranslatedText, setFetchingTranslatedText] = useState<boolean>(false);
  const [allLanguages, setAllLanguages] = useState<OptionType[]>();
  //  const allLanguageCodes = ISO6391.getAllCodes();
  const languages = useSelector((state) => state.language.languages) as LanguageViewModel[];
  const languagesLoaded = useSelector((state) => state.language.loaded);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!languagesLoaded) {
      dispatch(fetchSupportedLanguages());
    }
  }, [dispatch, languagesLoaded]);

  useEffect(() => {
    if (languages) {
      const all = languages.reduce((unique, language) => {
        if (!unique.some((obj) => obj.label === language.name)) {
          unique.push({
            label: language.name as string,
            value: language.code as string,
          });
        }
        return unique;
      }, [] as Array<{ label: string; value: string }>);
      setAllLanguages(all);
    }
  }, [languages]);

  const translate = async () => {
    setFetchingTranslatedText(true);
    const languageApi = new LanguageApi();
    const translatedTextResponse = await languageApi.apiLanguageTranslateTextGet(
      selectedModel,
      newSourceText,
      selectedTargetLanguageCode
    );
    setFetchingTranslatedText(false);
    setTargetText(translatedTextResponse.data);
    if (setTranslatedText) {
      setTranslatedText(translatedTextResponse.data);
    }
  };

  return (
    <>
      <Helmet>
        <title>Translate</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading="Translate"
          links={[
            { name: 'Actions', href: PATH_MARKETING.pages.actions },
            { name: 'Translate' },
          ]}
        />
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <RHSelect
            key="template"
            name="template"
            size="small"
            label="Template"
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: { md: 180 }, mx: 2 }}
          >
            <MenuItem value="default">
              <span>Default</span>
            </MenuItem>
          </RHSelect>
          <RHAutocomplete
            name="targetLanguage"
            size="small"
            label="Target Language"
            options={allLanguages as OptionType[]}
            sx={{ minWidth: { md: 200 } }}
            defaultValue={null}
            onChange={async (event: any, newValue: any) => {
              setSelectedTargetLanguageCode(newValue.value);
            }}
            getOptionLabel={(option: any) => option.label}
            // isOptionEqualToValue={(option: any, value: any) => option.startsWith(value)}
            filterOptions={(options, state) => {
              const filteredOptions = options.filter((option) =>
                option.label.toLowerCase().startsWith(state.inputValue.toLowerCase())
              );

              return filteredOptions;
            }}
          />
          <RHSelect
            key="model"
            name="model"
            size="small"
            defaultValue={selectedModel}
            onChange={async (event) => {
              setSelectedModel(event.target.value as any);
            }}
            label="Model"
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: { md: 180 }, mx: 2 }}
          >
            <MenuItem value={TranslateModel.GoogleTranslate}>
              <span>Google Translate</span>
            </MenuItem>
            <MenuItem value={TranslateModel.ChatGPT}>
              <span>Chat GPT</span>
            </MenuItem>
            <MenuItem value={TranslateModel.Bard}>
              <span>Bard</span>
            </MenuItem>
          </RHSelect>
        </Stack>
        <Scrollbar sx={{ minHeight: 320 }}>
          <Box sx={{ typography: 'body2', minHeight: 300 }}>
            <Card sx={{ p: 2, mb: 2 }}>
              <Stack spacing={3}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    id="source"
                    name="source"
                    value={newSourceText || ''}
                    onChange={(event) => {
                      setNewSourceText(event.target.value);
                    }}
                    label="Place source text here"
                    multiline
                    minRows={20}
                    maxRows={20}
                  />
                  {!fetchingTranslatedText ? (
                    <TextField
                      fullWidth
                      id="target"
                      name="target"
                      disabled
                      value={targetText || ''}
                      label="Translated Text"
                      multiline
                      minRows={20}
                      maxRows={20}
                    />
                  ) : (
                    <Box sx={{ mx: 3, width: '100%', overflow: 'auto', height: 260 }}>
                      <Skeleton variant="rectangular" width="100%" height="100%" />
                    </Box>
                  )}
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center">
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{
                      maxWidth: { md: 130 },
                      maxHeight: { md: 35 },
                    }}
                    onClick={async () => {
                      translate();
                    }}
                  >
                    Translate
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{
                      maxWidth: { md: 200 },
                      maxHeight: { md: 35 },
                    }}
                    color="inherit"
                  >
                    Configurations
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </Box>
        </Scrollbar>
      </Container>
    </>
  );
}
