import { IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';
import { useDispatch, useSelector } from 'src/redux/store';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import React, { useCallback, useEffect, useState } from 'react';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  DragOverEvent,
  closestCorners,
} from '@dnd-kit/core';

import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import {
  saveAiFlow,
  selectAiFlowItem,
  selectAiFlowItemById,
  selectAiFlowItemBySequence,
  setAddBeforeSequence,
  setSidebarPopoverOpen,
  updateSelectedAiFlow,
} from 'src/redux/slices/aiflows';
import _ from 'lodash';
import { AiFlowItem, AiFlowItemNode } from 'src/@types/aiflow';
import { ActiveTypes, ActiveTypesSequences } from 'src/common/constants/active-types.constants';
import { isValidItemToBePlaced } from 'src/utils/aiFlows-validator-utils';
import { AiFlowItemLogic, AiFlowLogic } from 'src/api';
import { useSnackbar } from 'src/components/snackbar';
import { orderItemsBySequence, orderItemsSequence } from 'src/utils/aiFlowsUtils';
import { useQuery } from 'src/utils/query';
import { useNavigate } from 'react-router';
import AiFlowItemNew from '../AiFlowItemNew';
import AiFlowSidebarDrawer from '../drawer/AiFlowSidebarDrawer';
import { AiFlowItemType } from '../enums/AiFlowItemType.enum';
import AiFlowAction from '../item-cards/AiFlowAction';
import AiFlowSideBar from '../sidebar/AiFlowSideBar';

import {
  ReactFlow,
  Background,
  Edge,
  Node,
  ProOptions,
  ReactFlowProvider,
  useReactFlow,
} from '@xyflow/react';

import '@xyflow/react/dist/style.css';
import useLayout from './hooks/useLayout';
import nodeTypes from './NodeTypes';
import edgesTypes from './EdgeTypes';
import AiFlowSidebarPopover from './popovers/AiFlowSidebarPopover';

interface Props {
  sidebarHidden?: boolean;
}

export default function WorkflowDesigner({ sidebarHidden }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actions = useSelector((state) => state.aiFlows.actions);
  const fullscreen = useSelector((state) => state.aiFlows.fullscreen);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const fetchingAiFlows = useSelector((state) => state.aiFlows.loading);
  const selectedAiFlowVersion = useSelector((state) => state.aiFlows.selectedAiFlowVersion);
  const selectedAiFlowRun = useSelector((state) => state.aiFlowsRuns.selectedAiFlowRun);
  const aiFlowRunOutputs = useSelector((state) => state.aiFlowsRuns.aiFlowRunsOutputs);
  const [aiFlowItemsSequences, setAiFlowItemsSequences] = useState<string[]>([]);
  const [draggedActiveId, setDraggedActiveId] = useState<number>(0);
  const [draggedAiFlowItem, setDraggedAiFlowItem] = useState<AiFlowItem | null>(null);
  const [draggedOverId, setDraggedOverId] = useState<number>(0);
  const [actionsSequences, setActionsSequences] = useState<string[]>([]);
  const [aiFlowItems, setAiFlowItems] = useState<AiFlowItem[]>([]);
  const [aiFlowItemsAdd, setAiFlowItemsAdd] = useState<AiFlowItem[]>([]);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const editorFullScreenMode = useSelector((state) => state.editor.fullScreen);
  const savingAiFlow = useSelector((state) => state.aiFlows.savingAiFlow);
  const selectedAiFlowItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const lastSavedAiFlow = useSelector((state) => state.aiFlows.lastSavedSelectedAiFlow);
  const isAiFlowDirty = useSelector((state) => state.aiFlows.isDirty);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const loaded = useSelector((state) => state.aiFlows.loaded);
  const aiFlowRunItems = useSelector((state) => state.aiFlowsRuns.aiFlowRunItems);
  const [runsModeFlowLoaded, setRunsModeFlowLoaded] = useState(true);
  const query = useQuery();
  const itemFromQuery = query.get('item');
  // const onDragEnd = useCallback(async ({ destination, source, draggableId, type }: DropResult) => {
  //   try {
  //     if (!destination) {
  //       return;
  //     }

  //     if (destination.droppableId === source.droppableId && destination.index === source.index) {
  //       return;
  //     }
  //   } catch (err) {
  //   }
  // }, []);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor)
  );
  const moveElement = (arr: any[], fromIndex: number, toIndex: number) => {
    const item = arr.splice(fromIndex, 1)[0];
    arr.splice(toIndex, 0, item);
    return arr;
  };

  const getTypeByActiveId = (activeId: number) => {
    if (activeId >= ActiveTypes.Action && activeId < ActiveTypes.Validator) {
      return AiFlowItemType.Action;
    }
    if (activeId >= ActiveTypes.Validator && activeId < ActiveTypes.Delay) {
      return AiFlowItemType.Validator;
    }
    if (activeId >= ActiveTypes.Delay) {
      return AiFlowItemType.Delay;
    }
    return AiFlowItemType.Action;
  };

  const shiftOneSequenceArrayItems = useCallback(
    (wfItems: AiFlowItem[], activeId: number, overId: number) => {
      moveElement(wfItems, activeId - 1, overId - 1);
      wfItems = wfItems.map((item, index) => {
        item.sequence = index + 1;
        return item;
      });
    },
    []
  );

  // const getInfoDataByItemType = (
  //   itemType: AiFlowItemType,
  //   eventCurrentData: any
  // ): InfoDataLogic => {
  //   if (itemType === AiFlowItemType.Action) {
  //     return { actionInfoData: { actionType: eventCurrentData.actionInfo?.header } };
  //   } else if (itemType === AiFlowItemType.Validator) {
  //     return {};
  //   } else if (itemType === AiFlowItemType.Delay) {
  //     return {};
  //   }
  //   return undefined as any;
  // };

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const activeId = event.active.id as number;

      if (activeId >= ActiveTypes.Action) {
        setAiFlowItems(aiFlowItemsAdd);
        const item: AiFlowItem = {
          actionInfo: event.active.data.current?.actionInfo,
          //   type: AiFlowItemType.Action,
        };
        setDraggedAiFlowItem(item as AiFlowItem);
        //  dispatch(addAiFlowItem({ aiFlowItem: item as AiFlowItem }));
        // const tmpAiFlowItems = _.cloneDeep(aiFlowItems);
        // tmpAiFlowItems.unshift(item as AiFlowItem);
        // setAiFlowItems(tmpAiFlowItems);
        //   moveElement(aiFlowItems, activeId - 1, overId - 1);
      }

      setDraggedActiveId(activeId);
      // const item = {
      //   data: JSON.stringify({ action: actions[0] }),
      //   actionInfo: actions[0],
      //   type: AiFlowItemType.Action,
      //   sequence: 1001,
      // };

      // dispatch(addAiFlowItem({ aiFlowItem: item as AiFlowItem }));
    },
    [aiFlowItemsAdd]
  );
  const handleDragOver = useCallback((event: DragOverEvent) => {
    const overId = parseInt(event.over ? event.over.id.toString() : '0', 10);
    const activeId = parseInt(event.active?.id.toString(), 10);
    setDraggedOverId(overId);

    // const overId = parseInt(event.over ? event.over.id.toString() : '0', 10);
    // const activeId = parseInt(event.active?.id.toString(), 10);
    // if (activeId >= 1000 && selectedAiFlow) {
    //
    //   const tmpAiFlow = _.cloneDeep(selectedAiFlow);
    //   let tmpAiFlowItems = tmpAiFlow.items as AiFlowItem[];
    //   tmpAiFlowItems = tmpAiFlowItems.filter((item) => !item.dragging);
    //   const item: AiFlowItem = {
    //     data: JSON.stringify({ action: actions[0] }),
    //     actionInfo: actions[6],
    //     type: AiFlowItemType.Action,
    //     aiFlowId: selectedAiFlow.id as number,
    //     sequence: overId + 1,
    //     dragging: true,
    //   };
    //   tmpAiFlowItems.splice(overId, 0, item);
    //   // setAiFlowItemsSequences((sequence) => {
    //   //   const oldIndex = sequence.indexOf(activeId.toString());
    //   //   const newIndex = sequence.indexOf(overId.toString());

    //   //   return arrayMove(aiFlowItemsSequences, oldIndex, newIndex);
    //   // });
    //   tmpAiFlow.items = tmpAiFlowItems;
    //   dispatch(updateSelectedAiFlow(tmpAiFlow));
    //   //  dispatch(addAiFlowItem({ aiFlowItem: item as AiFlowItem }));
    // }
  }, []);
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      let tmpAiFlowItems: AiFlowItem[] = [] as AiFlowItem[];
      let tmpAiFlow;
      if (selectedAiFlow) {
        tmpAiFlow = _.cloneDeep(selectedAiFlow);
        tmpAiFlowItems = tmpAiFlow.items ? (tmpAiFlow.items as AiFlowItem[]) : [];
      }
      let activeId = parseInt(active.id.toString(), 10);
      let overId = parseInt(over ? over.id.toString() : '0', 10);

      if (activeId >= ActiveTypes.Action && overId < ActiveTypes.Action) {
        setAiFlowItems(tmpAiFlowItems);
        return;
      }

      if (
        !isValidItemToBePlaced(
          activeId,
          tmpAiFlowItems.find((item) => item.sequence === overId - 1000),
          tmpAiFlowItems.find((item) => item.sequence === overId - 1000 + 1),
          event.active.data.current?.actionInfo?.header,
          overId - 1000 + 1
        )
      ) {
        enqueueSnackbar('Invalid action placement', { variant: 'error' });
        setAiFlowItems(tmpAiFlowItems);
        return;
      }

      if (overId >= ActiveTypes.Action && activeId >= ActiveTypes.Action) {
        const item: AiFlowItem = {
          actionInfo: event.active.data.current?.actionInfo,
          // type: getTypeByActiveId(activeId),
          sequence: overId - 1000 + 1,
          aiFlowVersionId: selectedAiFlowVersion?.id as number,
          aiFlowId: selectedAiFlow?.id as number,
          header: event.active.data.current?.actionInfo?.header,
        };
        aiFlowItemsSequences.push((overId - 1000 + 1).toString());
        tmpAiFlowItems.splice(overId - 1000, 0, item);
        tmpAiFlowItems = tmpAiFlowItems.map((wfItem, index) => {
          wfItem.sequence = index + 1;
          return wfItem;
        });
        // if (tmpAiFlowItems.length === 1) {
        //   tmpAiFlowItems.push({ type: AiFlowItemType.Finish, sequence: 2 });
        // }
        if (tmpAiFlow) {
          tmpAiFlow.items = tmpAiFlowItems;
        } else {
          tmpAiFlow = { items: tmpAiFlowItems };
        }
        dispatch(updateSelectedAiFlow(tmpAiFlow));
        setAiFlowItems(tmpAiFlowItems);
        setDraggedActiveId(0);
        const tmpSequences: string[] = [];
        for (let i = 0; i < tmpAiFlowItems.length; i++) {
          if (tmpAiFlowItems && tmpAiFlowItems[i]) {
            tmpSequences.push(tmpAiFlowItems[i].sequence!.toString());
          }
        }
        // setAiFlowItemsSequences((sequences) => {
        //   const oldIndex = sequences.indexOf(activeId.toString());
        //   const newIndex = sequences.indexOf(overId.toString());
        //
        //   return [...tmpSequences];
        // });
        return;
      }

      if (activeId >= ActiveTypesSequences.Validator && activeId < ActiveTypes.Action) {
        activeId %= 100;
      }
      if (overId >= ActiveTypesSequences.Validator && overId < ActiveTypes.Action) {
        overId %= 100;
      }
      const activeItem = tmpAiFlowItems.find((item) => item.sequence === activeId);
      activeItem!.sequence = overId;
      // moveElement(tmpAiFlowItems, activeId - 1, overId - 1);
      // tmpAiFlowItems = tmpAiFlowItems.map((item, index) => {
      //   item.sequence = index + 1;
      //   return item;
      // });

      shiftOneSequenceArrayItems(tmpAiFlowItems, activeId, overId);

      setAiFlowItems(tmpAiFlowItems);
      if (tmpAiFlow) {
        tmpAiFlow.items = tmpAiFlowItems;
        dispatch(updateSelectedAiFlow(tmpAiFlow));
      }
      dispatch(selectAiFlowItemBySequence(null));
      const tmpSequences: string[] = [];
      for (let i = 0; i < tmpAiFlowItems.length; i++) {
        if (tmpAiFlowItems && tmpAiFlowItems[i]) {
          tmpSequences.push(tmpAiFlowItems[i].sequence!.toString());
        }
      }
      setAiFlowItemsSequences((sequences) => {
        const oldIndex = sequences.indexOf(activeId.toString());
        const newIndex = sequences.indexOf(overId.toString());

        const movedArray = arrayMove(aiFlowItemsSequences, oldIndex, newIndex);
        return movedArray;
      });
    },
    [
      selectedAiFlow,
      aiFlowItemsSequences,
      enqueueSnackbar,
      shiftOneSequenceArrayItems,
      selectedAiFlowVersion,
      dispatch,
    ]
  );

  useEffect(() => {
    const tmpSequences: string[] = [];

    // if (actions) {
    //   for (const action of actions) {
    //     if (action) {
    //       tmpSequences.push((action && action.id ? action.id + 1000 : 0).toString());
    //     }
    //   }
    // }
    debugger;
    let wkItemsAdd: AiFlowItem[] = [];
    wkItemsAdd.push({ id: 1000, new: true });
    let items = [];
    if (runsMode) {
      if (selectedAiFlowRun && aiFlowRunItems) {
        items = aiFlowRunItems[selectedAiFlowRun!.id as number] ?? [];
        items = orderItemsBySequence(_.cloneDeep(items));
        if (!_.isEqual(aiFlowItems, items)) {
          setAiFlowItems(items);
        }
      }
    } else if (selectedAiFlow && selectedAiFlow.items) {
      if (draggedActiveId < ActiveTypes.Action) {
        items = selectedAiFlow.items;
        if (selectedAiFlowVersion?.id) {
          items = [
            ...selectedAiFlow.items.filter((item) =>
              item.aiFlowVersionId ? item.aiFlowVersionId === selectedAiFlowVersion?.id : true
            ),
          ];
        }

        items = orderItemsSequence(items);

        if (!_.isEqual(aiFlowItems, items)) {
          setAiFlowItems(items);
          if (!selectedAiFlow?.id) {
            dispatch(
              saveAiFlow(
                {
                  name: selectedAiFlow?.name ?? 'untitled',
                  aiFlowType: selectedAiFlow?.aiFlowType,
                  draft: true,
                  items,
                },
                enqueueSnackbar,
                navigate,
                true
              )
            );
          }
        }
      }

      wkItemsAdd = wkItemsAdd.concat(
        aiFlowItems.reduce((accumulator: AiFlowItem[], currentValue) => {
          const newItem: AiFlowItem = { id: (currentValue.sequence as number) + 1000, new: true };
          if (Number.isNaN(newItem.id as number)) {
            return accumulator.concat([currentValue]);
          }
          // ^ This is just an example, replace it with actual construction logic of the new item
          return accumulator.concat([currentValue, newItem]);
        }, [])
      );

      for (let i = 0; i < selectedAiFlow.items?.length; i++) {
        if (selectedAiFlow.items && selectedAiFlow.items[i]) {
          tmpSequences.push((i + 1).toString());

          // else if (selectedAiFlow.items[i].type === AiFlowItemType.Validator) {
          //   tmpSequences.push(
          //     (
          //       ActiveTypesSequences.Validator + (selectedAiFlow.items[i].sequence as number)
          //     ).toString()
          //   );
          // } else if (selectedAiFlow.items[i].type === AiFlowItemType.Delay) {
          //   tmpSequences.push(
          //     (ActiveTypesSequences.Delay + (selectedAiFlow.items[i].sequence as number)).toString()
          //   );
          // }
        }
      }
      // foreach action in actions
      setAiFlowItemsSequences(tmpSequences);
    }
    setAiFlowItemsAdd(wkItemsAdd);
  }, [
    selectedAiFlow,
    aiFlowItems,
    dispatch,
    enqueueSnackbar,
    navigate,
    draggedActiveId,
    runsMode,
    aiFlowRunItems,
    selectedAiFlowRun,
    aiFlowRunOutputs,
    selectedAiFlowVersion,
  ]);

  // useEffect(() => {
  //   const tmpSequences: string[] = [];
  //   if (actions) {
  //     for (const action of actions) {
  //       if (action) {
  //         tmpSequences.push((action && action.id ? action.id + 1000 : 0).toString());
  //       }
  //     }
  //   }
  //   setActionsSequences(tmpSequences);
  // }, [actions]);
  const theme = useTheme();
  const matchesXXL = useMediaQuery(theme.breakpoints.up(2000));

  const itemsPerRow = () => {
    if (sidebarHidden && !fullscreen) {
      return 'repeat(2, 1fr)';
    }
    if (matchesXXL) {
      return 'repeat(5, 1fr)';
    }
    return fullscreen ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)';
  };

  // const defaultNodes: Node[] = [
  //   {
  //     id: '1',
  //     data: { label: '🌮 Taco' },
  //     position: { x: 0, y: 0 },
  //     type: 'workflow',
  //   },
  //   {
  //     id: '2',
  //     data: { label: '+' },
  //     position: { x: 0, y: 150 },
  //     type: 'placeholder',
  //   },
  // ];

  const [nodes, setNodes] = useState<AiFlowItemNode[]>([]);
  // initial setup: connect the workflow node to the placeholder node with a placeholder edge
  const [edges, setEdges] = useState<Edge[]>([
    {
      id: '1=>2',
      source: '1',
      target: '2',
      type: 'placeholder',
    },
  ]);
  useEffect(() => {
    // map aiflow items to nodes
    if (selectedAiFlowItem) {
      return;
    }
    let items = selectedAiFlow?.items;
    if (runsMode) {
      items = aiFlowRunItems[selectedAiFlowRun?.id as number];
      if (items && items.length > 0) {
        items = orderItemsBySequence(_.cloneDeep(items));
      }
    }
    const tmpNodes =
      (items?.map((item) => ({
        id: (item.sequence as number).toString(),
        data: { label: item.header, item },
        item,
        position: { x: 0, y: 220 * (item.sequence as number) },
        type: 'workflow',
      })) as AiFlowItemNode[]) ?? [];
    if (!runsMode) {
      tmpNodes.push({
        id: (tmpNodes.length + 1).toString(),
        data: { label: '+' },
        position: { x: 0, y: 220 * (tmpNodes.length + 1) },
        type: 'placeholder',
      });
    }

    if (!_.isEqual(tmpNodes, nodes)) {
      console.log('tmpNodes', tmpNodes);
      setNodes(tmpNodes);
    }
    const tmpEdges =
      (items?.map((item, index) => ({
        id: `${index + 1}=>${index + 2}`,
        source: (index + 1).toString(),
        target: (index + 2).toString(),
        type: 'workflow',
      })) as Edge[]) ?? [];
    if (!_.isEqual(tmpEdges, edges)) {
      console.log('tmpEdges', tmpEdges);
      setEdges(tmpEdges);
      if (runsMode) {
        setRunsModeFlowLoaded(false);
        setTimeout(() => {
          setRunsModeFlowLoaded(true);
        }, 5);
      }
    }
  }, [
    selectedAiFlow,
    nodes,
    edges,
    selectedAiFlowItem,
    runsMode,
    aiFlowRunItems,
    selectedAiFlowRun,
  ]);

  const fitViewOptions = {
    padding: 0,
  };

  const handleDragCancel = useCallback(() => {
    const originalAiFlowItems = selectedAiFlow?.items;
    setAiFlowItems(originalAiFlowItems as AiFlowItem[]);
  }, [selectedAiFlow]);

  useEffect(() => {
    console.log('selectedAiFlow', selectedAiFlow);
    console.log('selectedAiFlowItem', selectedAiFlowItem);
    console.log('runsMode', runsMode);
    console.log('savingAiFlow', savingAiFlow);
    console.log('loaded', loaded);
    console.log('isAiFlowDirty', isAiFlowDirty);
    console.log('lastSavedAiFlow', lastSavedAiFlow);
    if (
      selectedAiFlow?.id &&
      !savingAiFlow &&
      loaded &&
      !selectedAiFlowItem &&
      !runsMode &&
      isAiFlowDirty &&
      !_.isEqual(selectedAiFlow, lastSavedAiFlow)
    ) {
      dispatch(saveAiFlow(selectedAiFlow as AiFlowLogic, enqueueSnackbar));
    }
  }, [
    selectedAiFlow,
    selectedAiFlowItem,
    dispatch,
    enqueueSnackbar,
    savingAiFlow,
    runsMode,
    loaded,
    isAiFlowDirty,
    lastSavedAiFlow,
  ]);

  // Initialize the placeholder node with a fixed ID
  const placeholderNode = {
    id: 'placeholder',
    data: { label: '+' },
    position: { x: 0, y: 0 },
    type: 'placeholder',
  };
  const [nextNodeId, setNextNodeId] = useState(1);

  const dashboardWidgetsData = useSelector((state) => state.dashboard.dashboardWidgetsData);
  const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };
  useEffect(() => {
    if (itemFromQuery && runsMode && dashboardWidgetsData?.recentOutputs) {
      const recentOutput = dashboardWidgetsData?.recentOutputs.find(
        (ro) => ro.item?.id === Number(itemFromQuery)
      );
      if (recentOutput) dispatch(selectAiFlowItem(recentOutput.item as AiFlowItemLogic));
    }
  }, [itemFromQuery, runsMode, dispatch, dashboardWidgetsData?.recentOutputs]);

  // const ReactFlowWrapper = () => {
  //   // useLayout();
  //   return (

  //   );
  // };

  return (
    <>
      <Stack direction="row" spacing={3} sx={{ position: 'relative' }}>
        <>
          <DndContext
            sensors={sensors}
            autoScroll={{ layoutShiftCompensation: false }}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            {/* {!sidebarHidden && <AiFlowSideBar />} */}
            <ReactFlowProvider>
              {runsModeFlowLoaded && (
                <div style={{ width: '100%', height: '640px' }}>
                  <ReactFlow
                    edges={edges}
                    nodes={nodes}
                    onNodeClick={(event, node) => {
                      dispatch(setAddBeforeSequence(Number(node.id) as number));
                      if (node.type === 'placeholder') {
                        console.log('placeholder clicked:', node);
                        dispatch(setSidebarPopoverOpen(event.currentTarget as HTMLElement));
                      }
                    }}
                    onEdgeClick={(event, edge) => {
                      console.log('edge clicked:', edge);
                      dispatch(setAddBeforeSequence(Number(edge.target) as number));
                      dispatch(setSidebarPopoverOpen(event.currentTarget as HTMLElement));
                    }}
                    // onNodesChange={(tmpNodes) => {
                    //   console.log('nodes changed:', tmpNodes);
                    // }}
                    // onNodeClick={(event, node) => {
                    //   if (node.type === 'placeholder') {
                    //     // Generate a new unique ID for the new node
                    //     const newNodeId = nextNodeId.toString();
                    //     setNextNodeId((prevId) => prevId + 1);

                    //     const newNode = {
                    //       id: newNodeId,
                    //       data: { label: `Node ${newNodeId}` },
                    //       type: 'workflow',
                    //       position: { x: 0, y: 0 }, // Position will be updated later
                    //     };

                    //     setNodes((prevNodes) => {
                    //       // Remove the placeholder node
                    //       const nodesWithoutPlaceholder = prevNodes.filter((n) => n.type !== 'placeholder');

                    //       // Insert the new node before the placeholder
                    //       const updatedNodes = [...nodesWithoutPlaceholder, newNode];

                    //       // Re-add the placeholder node
                    //       updatedNodes.push(placeholderNode);

                    //       // Update positions
                    //       updatedNodes.forEach((tmpNode, index) => {
                    //         tmpNode.position = { x: 0, y: 220 * index };
                    //       });

                    //       return updatedNodes;
                    //     });

                    //     setEdges((prevEdges) => {
                    //       const nodesWithoutPlaceholder = nodes.filter((n) => n.type !== 'placeholder');
                    //       const nodeIds = nodesWithoutPlaceholder.map((n) => n.id).concat(newNodeId);

                    //       const tmpEdges = [];

                    //       // Create edges between the nodes
                    //       for (let i = 0; i < nodeIds.length - 1; i++) {
                    //         tmpEdges.push({
                    //           id: `e${nodeIds[i]}-${nodeIds[i + 1]}`,
                    //           source: nodeIds[i],
                    //           target: nodeIds[i + 1],
                    //           type: 'workflow',
                    //         });
                    //       }

                    //       // Add edge from the last node to the placeholder
                    //       tmpEdges.push({
                    //         id: `e${nodeIds[nodeIds.length - 1]}-placeholder`,
                    //         source: nodeIds[nodeIds.length - 1],
                    //         target: 'placeholder',
                    //         type: 'workflow',
                    //       });

                    //       return tmpEdges;
                    //     });
                    //   }
                    // }}
                    proOptions={proOptions}
                    fitView
                    nodeTypes={nodeTypes}
                    edgeTypes={edgesTypes}
                    fitViewOptions={fitViewOptions}
                    minZoom={0.1}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    zoomOnDoubleClick={false}
                    // we are setting deleteKeyCode to null to prevent the deletion of nodes in order to keep the example simple.
                    // If you want to enable deletion of nodes, you need to make sure that you only have one root node in your graph.
                    deleteKeyCode={null}
                  >
                    <Background />
                  </ReactFlow>
                </div>
              )}
            </ReactFlowProvider>

            {draggedAiFlowItem && draggedActiveId > 0 && (
              <DragOverlay>
                <AiFlowAction
                  key={draggedActiveId}
                  index={draggedActiveId}
                  item={draggedAiFlowItem as AiFlowItem}
                />
              </DragOverlay>
            )}
          </DndContext>
        </>
        <AiFlowSidebarDrawer />
        <AiFlowSidebarPopover
        />
      </Stack>
    </>
  );
}
