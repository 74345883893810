import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PromptLogic } from 'src/api';
import Iconify from 'src/components/iconify';
import PromptEditor from 'src/components/prompt-editor/PromptEditor';
import { useSnackbar } from 'src/components/snackbar';
import { createNewVersionAiFlow } from 'src/redux/slices/aiflows';
import { createPrompt, updatePrompt } from 'src/redux/slices/prompts';
import { useDispatch, useSelector } from 'src/redux/store';

interface Props {
  onClose: () => void;
  open: boolean;
  inputPrompt?: PromptLogic;
}

export default function AddUpdatePromptDialog({ onClose, open, inputPrompt }: Props) {
  const [name, setName] = useState<string>('');
  const [userPrompt, setUserPrompt] = useState<string>('');
  const selectedPromptRepository = useSelector((state) => state.prompts.selectedPromptRepository);
  const [description, setDescription] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    onClose();
  };

  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);

  useEffect(() => {
    if (selectedItem && !inputPrompt) {
      setName(`${selectedItem.name}_Prompt`);
      setUserPrompt(selectedItem.inputData?.actionInputData?.promptInput || '');
    }
  }, [selectedItem, inputPrompt]);

  useEffect(() => {
    if (inputPrompt) {
      setName(inputPrompt.name || '');
      setDescription(inputPrompt.description || '');
      setUserPrompt(inputPrompt.userPrompt || '');
    }
  }, [inputPrompt]);

  const dispatch = useDispatch();

  return (
    <Dialog sx={{ zIndex: 4500 }} fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span>{inputPrompt ? 'Edit' : 'Add'} Prompt</span>
          <IconButton onClick={handleClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ typography: 'body2', overflow: 'hidden', p: 3 }}>
        <Stack direction="column" alignItems="center" spacing={3}>
          <TextField
            margin="dense"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Name"
            type="email"
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label="Description(Optional)"
            type="email"
            fullWidth
            rows={2}
            variant="standard"
          />
          <Paper
            key=""
            variant="outlined"
            sx={{
              py: 1.5,
              mx: 1,
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              flexGrow: 1,
              height: '100%',
              width: '100%',
            }}
          >
            <Stack direction="row" justifyContent="center" sx={{ px: 1 }}>
              <div> </div>
              <Typography variant="subtitle2" sx={{ textAlign: 'center', paddingBottom: 1 }}>
                User Prompt
              </Typography>
            </Stack>
            <Divider sx={{ mb: 1 }} />
            <PromptEditor
              promptInput={userPrompt}
              setPromptInput={(prompt: string) => {
                setUserPrompt(prompt);
              }}
            />
          </Paper>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={!userPrompt}
          onClick={() => {
            if (inputPrompt) {
              dispatch(
                updatePrompt(
                  {
                    name,
                    description,
                    promptRepositoryId: inputPrompt.promptRepositoryId,
                    userPrompt,
                    createdAt: inputPrompt.createdAt,
                    createdByUser: inputPrompt.createdByUser,
                    id: inputPrompt.id,
                  },
                  enqueueSnackbar
                )
              );
            } else {
              dispatch(
                createPrompt(
                  {
                    name,
                    description,
                    promptRepositoryId: selectedPromptRepository?.id ?? 0,
                    userPrompt,
                  },
                  enqueueSnackbar
                )
              );
            }

            onClose();
          }}
        >
          {inputPrompt ? 'Update' : 'Create'} Prompt
        </Button>
      </DialogActions>
    </Dialog>
  );
}
