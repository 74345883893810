import { MenuItem, Avatar } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { RHSelect } from 'src/components/hook-form';

export interface Props {
  handleSelectMethod: (provider: string) => void;
  selectedMethodInput?: string;
  maxWidth?: number;
  disabled?: boolean;
}

export default function HubspotMethodSelect({
  handleSelectMethod: handleSelectProvider,
  maxWidth,
  disabled,
  selectedMethodInput,
}: Props) {
  const [selectedMethod, setSelectedMethod] = useState<string>();
  const [allMethods, setAllMethods] = useState<any[]>([
    {
      "category": "Campaigns",
      "methods": [
        {
          "name": "List campaigns",
          "description": "Retrieve information about all campaigns.",
          "endpoint": "GET /campaigns"
        },
        {
          "name": "Get campaign info",
          "description": "Retrieve detailed information about a specific campaign.",
          "endpoint": "GET /campaigns/{campaign_id}"
        },
        {
          "name": "Create campaign",
          "description": "Create a new campaign.",
          "endpoint": "POST /campaigns"
        },
        {
          "name": "Update campaign",
          "description": "Update settings for an existing campaign.",
          "endpoint": "PATCH /campaigns/{campaign_id}"
        },
        {
          "name": "Delete campaign",
          "description": "Delete a specific campaign.",
          "endpoint": "DELETE /campaigns/{campaign_id}"
        }
      ]
    },
    {
      "category": "Contacts",
      "methods": [
        {
          "name": "List contacts",
          "description": "Get information about members in a list.",
          "endpoint": "GET /lists/{list_id}/members"
        },
        {
          "name": "Get contact info",
          "description": "Get detailed information about a list member.",
          "endpoint": "GET /lists/{list_id}/members/{subscriber_hash}"
        },
        {
          "name": "Add contact",
          "description": "Add a new member to a list.",
          "endpoint": "POST /lists/{list_id}/members"
        },
        {
          "name": "Update contact",
          "description": "Update a list member’s information.",
          "endpoint": "PATCH /lists/{list_id}/members/{subscriber_hash}"
        },
        {
          "name": "Remove contact",
          "description": "Remove a member from a list.",
          "endpoint": "DELETE /lists/{list_id}/members/{subscriber_hash}"
        }
      ]
    },
    {
      "category": "Reports",
      "methods": [
        {
          "name": "List reports",
          "description": "Get report summaries for all campaigns.",
          "endpoint": "GET /reports"
        },
        {
          "name": "Get report",
          "description": "Get detailed report information for a specific campaign.",
          "endpoint": "GET /reports/{campaign_id}"
        },
        {
          "name": "Email activity",
          "description": "Get a detailed report of email activity for a specific campaign, including opens, clicks, and bounces.",
          "endpoint": "GET /reports/{campaign_id}/email-activity"
        }
      ]
    }
  ]);

  return (
    <>
      <RHSelect
        disabled={disabled}
        name="selectProvider"
        label="Select Provider"
        zIndex={2500}
        defaultValue={(selectedMethod ?? selectedMethodInput) || ''}
        onChange={(e) => {
          setSelectedMethod(e.target.value);
          handleSelectProvider(e.target.value as string);
        }}
        size="small"
        InputLabelProps={{ shrink: true }}
        sx={{ maxWidth: { md: maxWidth ?? 300 } }}
      >
        {allMethods.map((method, index) => (
          <MenuItem key={index} value={method?.integrationName ?? ''}>
            <Stack direction="row" alignItems="center" spacing={1}>
             
              <span>{method?.integrationLabel}</span>
            </Stack>
          </MenuItem>
        ))}
      </RHSelect>
    </>
  );
}
