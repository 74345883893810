export const notificationTitleByType = {
  flow_started: 'run #{{1}} has started',
  flow_completed: 'run #{{1}} has completed',
  flow_failed: 'run #{{1}} has failed',
  add_comment: 'has added a comment to output #{{1}}',
};

export const notificationIconByType = {
  flow_started: 'codicon:debug-start',
  flow_completed: 'codicon:debug-start',
  flow_failed: 'codicon:debug-start',
  add_comment: 'ic:outline-comment',
};
