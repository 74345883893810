// utils

import { createSlice, Dispatch } from '@reduxjs/toolkit';
import _ from 'lodash';
import { CampaignInfo, ICampaignFormsState } from 'src/@types/campaign';
import { EmailTemplate, TemplateType } from 'src/@types/email-template';
import { EmailProviderApi } from 'src/api';

// ----------------------------------------------------------------------

const initialState: ICampaignFormsState = {
  error: null,
  activeStep: 0,
  selectedTemplate: {},
  campaignInfo: {},
  campaignSettings: {},
  campaignSetup: {},
};

const slice = createSlice({
  name: 'campaignForms',
  initialState,
  reducers: {
    nextStep(state) {
      state.activeStep++;
    },
    previousStep(state) {
      state.activeStep--;
    },
    setCampaignInfo(state, action) {
      state.campaignInfo = action.payload;
    },
    setSelectedTemplate(state, action) {
      const newTemplate = action.payload as EmailTemplate;
      if (!newTemplate) {
        state.selectedTemplate = undefined;
        return;
      }
      // if (
      //   state.selectedTemplate?.id !== newTemplate.id &&
      //   state.selectedTemplate?.type === TemplateType.Provider
      // ) {
      //   newTemplate.content = undefined;
      // }
      state.selectedTemplate = newTemplate;
    },
    setSelectedTemplateContent(state, action) {
      if (state.selectedTemplate) {
        state.selectedTemplate.content = action.payload;
      }
    },
    updateTemplateHtml(state, action) {
      if (state.selectedTemplate) {
        state.selectedTemplate.content = action.payload;
      }
    },
    setCampaignSetup(state, action) {
      const updatedCampaignSetup = { ...state.campaignSetup, ...action.payload };
      state.campaignSetup = updatedCampaignSetup;
    },
    setCampaignId(state, action){
      state.campaignId = action.payload;
    },
    clearCampaignForms(state) {
      state.activeStep = 0;
      state.selectedTemplate = {};
      state.campaignInfo = {};
      state.campaignSettings = {};
      state.campaignSetup = {};
    },

    hasError(state, action) {
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  nextStep,
  previousStep,
  setCampaignInfo,
  setSelectedTemplate,
  clearCampaignForms,
  setCampaignId,
  updateTemplateHtml,
  setCampaignSetup,
} = slice.actions;

export function createCampaign(campaignInfo: CampaignInfo) {
  return async (dispatch: Dispatch) => {
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderCreateCampaignPost({
      //   campaign: {
      //     campaignName: campaignInfo.campaignName as string,
      //     provider: campaignInfo.emailProvider,
      //     type: campaignInfo.abTesting ? 'A/B Variation' : 'Regular',
      //     listId: campaignInfo.selectedAudienceList,
      //   },
      // });
     // dispatch(slice.actions.setCampaignId(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCampaign() {
  // i need to get campaign id from state
  
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const state = getState().newCampaign as ICampaignFormsState;
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderUpdateCampaignPut({
      //   campaign: {
      //     id: state.campaignId,
      //     campaignName: state.campaignInfo.campaignName as string,
      //     provider: state.campaignInfo.emailProvider,
      //     type: state.campaignInfo.abTesting ? 'A/B Variation' : 'Regular',
      //     listId: state.campaignInfo.selectedAudienceList,
      //     subject: state.campaignSetup.subject,
      //     fromName: state.campaignSetup.fromName,
      //     fromEmail: state.campaignSetup.fromEmail,
      //     previewText: state.campaignSetup.previewText,
      //     templateContent: state.selectedTemplate?.content,
      //     templateId: state.selectedTemplate?.id,
      //   },
      // });
      // console.log('response', response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}
