import { Dispatch, createSlice } from '@reduxjs/toolkit';
// @types
import { IOrganizationState } from 'src/@types/organization';
import { OrganizationApi } from 'src/api';

// ----------------------------------------------------------------------

const initialState: IOrganizationState = {
  organizationInfo: null,
  error: null,
  users: [],
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    // START LOADING
    setLoading(state, action) {
      state.loading = action.payload;
    },
    fetchUsersSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.users = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function fetchUsers() {
  return async (dispatch: Dispatch) => {
    try {
      const orgApi = new OrganizationApi();
      const response = await orgApi.apiOrganizationUsersGet();
      dispatch(slice.actions.fetchUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


