import {
  Dialog,
  DialogActions,
  Button,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useRef, useState } from 'react';
import TranslationPage from 'src/pages/marketing/actions/TranslationPage';

interface Props {
  onClose: () => void;
  onReplace: (text: string) => void;
  open: boolean;
  sourceText: string;
}
export type TranslateFormProps = {
  zz: string;
};
export default function TranslationDialog({ onClose, onReplace, open, sourceText }: Props) {
  const [formattedSourceText, setFormattedSourceText] = useState<string>('');
  // const allLanguageNames = ISO6391.getAllNames();

  const handleClose = () => {
    // setTargetText('');
    onClose();
  };
  const [targetText, setTargetText] = useState<string>('');

  const setTranslatedText = (text: string) => {
    setTargetText(text);
  };

  const dialogRootRef = useRef();

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={() => {
        handleClose();
      }}
      ref={dialogRootRef as any}
    >
      {/* <DialogTitle>
        Translate
      </DialogTitle> */}
      {/* <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <span>Translation</span>
          <RHAutocomplete
            name="language"
            size="small"
            label="Target Language"
            options={allLanguages as OptionType[]}
            sx={{ minWidth: { md: 200 } }}
            defaultValue={null}
            onChange={async (event: any, newValue: any) => {
              if (!newValue) {
                setTargetText('');
                return;
              }
              console.log('event newValue', event, newValue);
              setFetchingTranslatedText(true);
              const languageApi = new LanguageApi();
              const translatedTextResponse = await languageApi.apiLanguageTranslateTextGet(
                selectedModel,
                sourceText,
                newValue.value as string
              );
              setFetchingTranslatedText(false);
              setTargetText(translatedTextResponse.data);
              setSelectedTargetLanguageCode(newValue.value);
            }}
            getOptionLabel={(option: any) => option.label}
            // isOptionEqualToValue={(option: any, value: any) => option.startsWith(value)}
            filterOptions={(options, state) => {
              const filteredOptions = options.filter((option) =>
                option.label.toLowerCase().startsWith(state.inputValue.toLowerCase())
              );

              console.log('filteredOptions', filteredOptions);
              return filteredOptions;
            }}
          />
          <RHSelect
            key="zz"
            name="zz"
            size="small"
            defaultValue={selectedModel}
            onChange={async (event) => {
              console.log('event.target.value', event.target.value);
              setFetchingTranslatedText(true);
              const languageApi = new LanguageApi();
              const translatedTextResponse = await languageApi.apiLanguageTranslateTextGet(
                event.target.value as any,
                sourceText,
                selectedTargetLanguageCode as string
              );
              setFetchingTranslatedText(false);
              setTargetText(translatedTextResponse.data);
              setSelectedModel(event.target.value as any);
            }}
            label="Model"
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: { md: 180 } }}
          >
            <MenuItem value={TranslateModel.GoogleTranslate}>
              <span>Google Translate</span>
            </MenuItem>
            <MenuItem value={TranslateModel.ChatGPT}>
              <span>Chat GPT</span>
            </MenuItem>
            <MenuItem value={TranslateModel.Bard}>
              <span>Bard</span>
            </MenuItem>
          </RHSelect>
        </Stack>
      </DialogTitle> */}
      <Box sx={{ p: 2, mt: 2 }}>
        <TranslationPage sourceText={sourceText} setTranslatedText={setTranslatedText} />
      </Box>
      <DialogActions>
        <Stack display="flex" direction="row" justifyContent="space-between" spacing={3}>
          <Button
            variant="outlined"
            size="large"
            //   disabled={!selectedTargetLanguageCode}
            onClick={() => {
              handleClose();
              setTimeout(() => {
                onReplace(targetText);
              });
            }}
            sx={{
              maxWidth: { md: 130 },
              maxHeight: { md: 35 },
            }}
          >
            Replace
          </Button>
          <Button
            sx={{
              maxWidth: { md: 130 },
              maxHeight: { md: 40 },
            }}
            variant="outlined"
            color="inherit"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
