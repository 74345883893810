// utils

import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IEmailTemplateState } from 'src/@types/email-template';
import { EmailTemplateApi, TemplateApi } from 'src/api';

// ----------------------------------------------------------------------

const initialState: IEmailTemplateState = {
  error: null,
  fetching: false,
  templates: [],
};

const slice = createSlice({
  name: 'email-templates',
  initialState,
  reducers: {
    fetchingEmailTemplates(state, action) {
      state.fetching = action.payload;
    },
    fetchEmailProviderTemplatesSuccuess(state, action) {
      state.templates = [...state.templates, ...action.payload];
    },
    fetchSavedEmailTemplatesSuccuess(state, action) {
      state.templates = [...action.payload, ...state.templates];
      state.fetching = false;
    },
    addEmailTemplate(state, action) {
      state.templates.unshift(action.payload);
    },
    addEmailTemplates(state, action) {
      state.templates = [...action.payload, ...state.templates];
    },
    setEmailTemplateContent(state, action) {
      const index = state.templates.findIndex(
        (template) => template.id === action.payload.templateId
      );
      if (index !== -1) {
        state.templates[index].content = action.payload.content;
      }
      state.fetching = false;
    },
    deleteEmailTemplate(state, action) {
      const index = state.templates.findIndex(
        (template) => template.id === action.payload.templateId
      );
      if (index !== -1) {
        const newTemplates = [...state.templates];
        newTemplates.splice(index, 1);
        state.templates = newTemplates;
      }
    },
    updateEmailTemplate(state, action) {
      const index = state.templates.findIndex((template) => template.id === action.payload.id);
      if (index !== -1) {
        state.templates[index] = action.payload;
        state.templates = [...state.templates];
      }
    },
    hasError(state, action) {
      state.error = action.payload;
      state.fetching = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addEmailTemplate,
  addEmailTemplates,
  fetchEmailProviderTemplatesSuccuess,
  fetchSavedEmailTemplatesSuccuess,
  updateEmailTemplate,
  hasError,
} = slice.actions;

// ----------------------------------------------------------------------

export function deleteEmailTemplate(templateId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const api = new EmailTemplateApi();
      await api.apiEmailTemplateDeleteTemplateIdDelete(templateId);
      dispatch(slice.actions.deleteEmailTemplate({ templateId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEmailProviderTemplateHtml(emailProvider: string, templateId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const api = new EmailTemplateApi();
      dispatch(slice.actions.fetchingEmailTemplates(true));
      // const templateHtmlResponse = await api.apiEmailTemplateGetProviderTemplateContentGet(
      //   emailProvider,
      //   templateId.toString()
      // );
   //   dispatch(slice.actions.setEmailTemplateContent(templateHtmlResponse.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchEmailProvidersTemplates(providerName: string) {
  return async (dispatch: Dispatch) => {
    try {
      const api = new EmailTemplateApi();

      // const templates = (await api.apiEmailTemplateGetProviderTemplatesGet(providerName)).data;
      // dispatch(slice.actions.fetchEmailProviderTemplatesSuccuess(templates));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchEmailSavedTemplates() {
  return async (dispatch: Dispatch) => {
    try {
      const api = new EmailTemplateApi();
      dispatch(slice.actions.fetchingEmailTemplates(true));
      const response = (await api.apiEmailTemplateGetSavedTemplatesGet()).data;
      dispatch(slice.actions.fetchSavedEmailTemplatesSuccuess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
