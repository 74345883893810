import { MenuItem, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { RHSelect } from 'src/components/hook-form';
import { useSelector } from 'src/redux/store';
import Label from '../label';
import { ConnectionLogic } from 'src/api';
import { findIntegrationByModelType } from 'src/common/constants/llm-models.constants';
import Iconify from '../iconify';
import { useNavigate } from 'react-router';
import { PATH_MARKETING } from 'src/routes/paths';

export interface Props {
  maxWidth?: number;
  selectedModel?: string;
  selectedIntegration?: string;
  onConnectionSelect: (connectionId: number) => void;
  selectedConnectionInput?: number | null | undefined;
  disabled?: boolean;
  px?: number;
}

export default function ConnectionSelect({
  maxWidth,
  disabled,
  selectedIntegration,
  onConnectionSelect,
  selectedConnectionInput,
  selectedModel,
  px,
  ...other
}: Props) {
  const connections = useSelector((state) => state.connection.connections);
  const [filteredConnections, setFilteredConnections] = useState<ConnectionLogic[]>();
  const [selectedConnection, setSelectedConnection] = useState<ConnectionLogic>();
  const [selectedConnectionId, setSelectedConnectionId] = useState<number>(0);
  const [initated, setInitiated] = useState<boolean>(false);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const navigate = useNavigate();

  useEffect(() => {
    if (connections) {
      let modelType = 'Generate';
      if (selectedItem) {
        if (selectedItem.header === 'VisionaryAnalysis') {
          modelType = 'Vision';
        }
      }
      const selModel = selectedModel?.startsWith('ft:')
        ? selectedModel?.split(':')[1]
        : selectedModel;
      const integrationName =
        selectedIntegration ?? findIntegrationByModelType(selModel as string, modelType as any);
      const tmpconnections = connections.filter(
        (connection: any) => connection.integrationName === integrationName
      );
      let tmpconnectionsCloned = _.cloneDeep(tmpconnections) as ConnectionLogic[];
      if (integrationName === 'TwilioSendgrid') {
        const kuvertoDefaultConnection: ConnectionLogic = {
          id: 0,
          connectionName: 'Kuverto',
          integrationType: 'apiKey',
          integrationName: 'TwilioSendgrid',
          //     default: true,
          apiKeyDetails: {
            apiKey: 'Kuverto',
          },
        };
        if (!tmpconnectionsCloned) {
          tmpconnectionsCloned = [];
        }
        const kuvertoConnection = [kuvertoDefaultConnection];
        const newTmpConnectionsCloned = kuvertoConnection.concat(_.cloneDeep(tmpconnections));
        if (!_.isEqual(filteredConnections, newTmpConnectionsCloned)) {
          //    tmpconnectionsCloned = newTmpConnectionsCloned;
          setFilteredConnections(newTmpConnectionsCloned);
        }
      } else if (!_.isEqual(filteredConnections, tmpconnectionsCloned)) {
        setFilteredConnections(tmpconnectionsCloned);
      }

      const tmpSelectedIntegration = tmpconnectionsCloned.find(
        (connection: any) => connection.default
      );
      if (selectedConnectionId !== tmpSelectedIntegration?.id) {
        setSelectedConnectionId(tmpSelectedIntegration?.id as number);
        setSelectedConnection(tmpSelectedIntegration);
      }
      // if (tmpSelectedIntegration && !selectedConnectionInput && !selectedConnectionId && !initated) {
      //   
      //   setInitiated(true);
      //   onConnectionSelect(tmpSelectedIntegration?.id as number);
      // }
    }
  }, [
    connections,
    selectedConnectionId,
    selectedModel,
    filteredConnections,
    initated,
    selectedConnection,
    selectedIntegration,
    onConnectionSelect,
    selectedConnectionInput,
    selectedItem,
  ]);

  return (
    <RHSelect
      disabled={disabled || runsMode}
      key="selectConnection"
      name="selectConnection"
      zIndex={3400}
      size="small"
      sx={{ maxWidth: maxWidth as number, px: px ?? 0 }}
      label="Select Connection"
      value={selectedConnectionInput ?? selectedConnectionId}
      onChange={(event) => {
        const foundConnection = connections?.find(
          (connection: any) => connection.id === parseInt(event.target.value, 10)
        );
        setSelectedConnection(foundConnection);
        onConnectionSelect(parseInt(event.target.value, 10));
      }}
      InputLabelProps={{ shrink: true }}
      {...other}
      // sx={{ maxWidth: { md: 180 } }}
    >
      {filteredConnections &&
        filteredConnections.map((connection) => (
          <MenuItem key={connection.id} value={connection.id as number}>
            <Stack direction="row" spacing={4} alignItems="center">
              {connection?.integrationType === 'apiKey' && (
                <span>
                  {' '}
                  {connection?.apiKeyDetails?.apiKey &&
                    `${connection?.apiKeyDetails?.apiKey.substring(
                      0,
                      3
                    )}**...${connection.apiKeyDetails.apiKey.substring(
                      connection.apiKeyDetails.apiKey.length - 4
                    )}`}
                </span>
              )}
              <Typography variant="body2" color="textSecondary">
                {connection?.integrationType === 'apiKey' && connection?.connectionName}
                {(connection?.integrationType === 'oauth' && connection?.oauthDetails?.account) ??
                  `${connection?.oauthDetails?.accessToken?.substring(
                    0,
                    3
                  )}**...${connection.oauthDetails?.accessToken?.substring(
                    connection.oauthDetails.accessToken.length - 5
                  )}`}
              </Typography>
              {connection?.default && <Label color="success">Default</Label>}
            </Stack>
          </MenuItem>
        ))}
      <MenuItem
        key="addConnection"
        onClick={() => navigate(PATH_MARKETING.integrations.root)}
        value="addConnection"
      >
        <Iconify sx={{ mr: 1 }} icon="material-symbols:add" />
        <span>Add Connection</span>
      </MenuItem>
    </RHSelect>
  );
}
