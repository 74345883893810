import React, { createContext, useContext } from 'react';
import pubSub from './PubSubService'; // Import the pub-sub service

export const PubSubContext = createContext({} as any);

interface Params {
  children: React.ReactNode;
}

export const PubSubProvider = ({ children }: Params) => (
  <PubSubContext.Provider value={pubSub}>{children}</PubSubContext.Provider>
);

// Custom hook to use the pub-sub service
export const usePubSub = () => useContext(PubSubContext);
