import { Container } from '@mui/system';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import EditGeneratedContentForm from './EditGeneratedContentForm';
import { useParams } from 'react-router';
import { DialogComponentMode } from 'src/common/constants/general.constants';

interface Params {
  generatedContentId?: string;
  dialogMode?: boolean;
  onSetDialogMode?: (mode: DialogComponentMode) => void;
}

export default function EditGeneratedContentPage({
  generatedContentId,
  dialogMode,
  onSetDialogMode,
}: Params) {
  const { themeStretch } = useSettingsContext();
  const { id } = useParams();
  const inputId = generatedContentId ?? id;
  console.log('dialogMode', dialogMode);
  return (
    <>
      <Helmet>
        <title> Kuverto | Edit Generated Content Feedback</title>
      </Helmet>

      <Container sx = {{overflow: "hidden"}} maxWidth={themeStretch ? false : 'xl'}>
        {!dialogMode && (
          <CustomBreadcrumbs heading="Edit" links={[{ name: 'Generated Content Feedback' }]} />
        )}
        <EditGeneratedContentForm onSetDialogMode={onSetDialogMode} id={inputId as string} />
      </Container>
    </>
  );
}
