import { createSlice } from '@reduxjs/toolkit';
import { IUploadState } from 'src/@types/upload';

const initialState: IUploadState = {
  error: null,
  filesMap: {},
};
const slice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setFileMapName(state, action) {
        state.filesMap = action.payload;
    //   const fileMap = {
    //     ...state.filesMap,
    //     [action.payload.key]: action.payload.name.replace('.html', ''),
    //   };
    //   state.filesMap = fileMap;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setFileMapName } = slice.actions;
