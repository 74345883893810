import _ from 'lodash';
import { AiFlowItem } from 'src/@types/aiflow';
import { ActionInfo, AiFlowVersionLogic } from 'src/api';
import { AiFlowItemType } from 'src/pages/aiFlows/enums/AiFlowItemType.enum';

export default function mapAiFlowItemData(
  aiFlowItem: AiFlowItem,
  aiFlowItems: AiFlowItem[],
  actions: ActionInfo[],
  groups: any[]
) {
  if (
    actions === undefined ||
    actions.length === 0 ||
    groups === undefined ||
    aiFlowItem === undefined
  ) {
    return aiFlowItem;
  }
  const header = aiFlowItem.header;
  const stateActions = _.cloneDeep(actions) as ActionInfo[];
  const actionInfo = stateActions.find((a) => a.header === header);
  const tmpActionGroup = groups.find(
    (group) =>
      group.actions &&
      group.actions?.find(
        (groupAction: ActionInfo) => groupAction && groupAction.header === header
      ) !== undefined
  );
  let tmpName = `${tmpActionGroup?.label}_${actionInfo?.actionLabel}`;
  const nameExist = aiFlowItems.find((item) => item.name === tmpName);
  if (nameExist) {
    const itemsWithSameBaseName = aiFlowItems
      .filter((item) => item?.name?.startsWith(tmpName)) // Filter items that start with the base name
      .map((item) => ({
        name: item.name,
        tildeCount: (item?.name?.match(/~/g) || []).length // Count `~` in each item name
      }));
  
    const itemWithMostTildes = itemsWithSameBaseName
      .sort((a, b) => b.tildeCount - a.tildeCount) // Sort descending by tilde count
      .shift(); // Get the first item from the sorted array, which has the most tildes
  
    tmpName = `${itemWithMostTildes?.name}~`;
  }
  aiFlowItem = {
    ...aiFlowItem,
    inputData: aiFlowItem.inputData ?? {
      actionInputData: {
        promptInput: actionInfo?.prompt,
      },
    },
    name: aiFlowItem.name ?? tmpName,
    actionInfo,
  };
  return aiFlowItem;
}

export const orderItemsSequence = (items: AiFlowItem[]) => {
  const itemsSequence = items.map((item, index) => ({
    ...item,
    sequence: index + 1,
  }));
  return itemsSequence;
};

export const orderItemsBySequence = (items: AiFlowItem[]) =>
  items.sort((a, b) => (a.sequence as number) - (b.sequence as number));

export const findLatestVersion = (aiFlowVersions: AiFlowVersionLogic[]) => {
  if (aiFlowVersions.length === 0) return null; // Return null if the array is empty

  return aiFlowVersions.reduce((highest, current) =>
    // If current version is higher than the highest found so far, return current, otherwise return highest
    (current.version ?? 0) > (highest.version ?? 0) ? current : highest
  );
};

export const updateAiFlowItemWithUpdatedSource = (source: string, newSource: string, item: AiFlowItem) => {
  if (item.header === "Locator") {
    if (item.inputData?.actionInputData?.functionalInputData?.locatorInput?.fileSource === source) {
      item.inputData.actionInputData.functionalInputData.locatorInput.fileSource = newSource;
    }
    else if (item.inputData?.actionInputData?.functionalInputData?.locatorInput?.selectedElementsSource === source) {
      item.inputData.actionInputData.functionalInputData.locatorInput.selectedElementsSource = newSource;
    };
  }
  else if (item.header === "Validator") {
    if (item.inputData?.actionInputData?.functionalInputData?.validatorInput?.expectedSource === source) {
      item.inputData.actionInputData.functionalInputData.validatorInput.expectedSource = newSource;
    }
    else if (item.inputData?.actionInputData?.functionalInputData?.validatorInput?.targetSource === source) {
      item.inputData.actionInputData.functionalInputData.validatorInput.targetSource = newSource;
    };
  }
  else if (item.header === "Extractor") {
    if (item.inputData?.actionInputData?.functionalInputData?.extractorInput?.source === source) {
      item.inputData.actionInputData.functionalInputData.extractorInput.source = newSource;
    }
  }
  else if (item.header === "transformer") {
    if (item.inputData?.actionInputData?.functionalInputData?.transformerInput?.source === source) {
      item.inputData.actionInputData.functionalInputData.transformerInput.source = newSource;
    }
  }
  else if (item.header === "Segmentation") {
    if (item.inputData?.actionInputData?.functionalInputData?.segmentInput?.source === source) {
      item.inputData.actionInputData.functionalInputData.segmentInput.source = newSource;
    }
  }
  else if (item.header === "Scrapper") {
    if (item.inputData?.actionInputData?.functionalInputData?.scrapperInput?.source === source) {
      item.inputData.actionInputData.functionalInputData.scrapperInput.source = newSource;
    }
  }
}

export const checkAiFlowItemHasSource = (item: AiFlowItem, source: string) => {
  if (item.header === "Locator") {
    return item.inputData?.actionInputData?.functionalInputData?.locatorInput?.fileSource === source ||
      item.inputData?.actionInputData?.functionalInputData?.locatorInput?.selectedElementsSource === source;
  }
  else if (item.header === "Validator") {
    return item.inputData?.actionInputData?.functionalInputData?.validatorInput?.expectedSource === source ||
      item.inputData?.actionInputData?.functionalInputData?.validatorInput?.targetSource === source;
  }
  else if (item.header === "Extractor") {
    return item.inputData?.actionInputData?.functionalInputData?.extractorInput?.source === source;
  }
  else if (item.header === "transformer") {
    return item.inputData?.actionInputData?.functionalInputData?.transformerInput?.source === source;
  }
  else if (item.header === "Segmentation") {
    return item.inputData?.actionInputData?.functionalInputData?.segmentInput?.source === source;
  }
  else if (item.header === "Scrapper") {
    return item.inputData?.actionInputData?.functionalInputData?.scrapperInput?.source === source;
  }
  return false;
}

