export type IEmailTemplateState = {
  error: Error | string | null;
  fetching: boolean;
  templates: EmailTemplate[];
};

export type EmailTemplate = {
  id?: number;
  name?: string;
  thumbnail?: string;
  content?: string;
  type?: TemplateType;
  providerName?: string;
};

export type TemplateData = {
  name: string;
  content: string;
};

export enum TemplateType {
  Imported = 'Imported',
  Saved = 'Saved',
  Provider = 'Provider',
}

export type GenerateTemplatesRequest = {
  shortProductDescription: string;
  templateType: string;
  similarContent: string;
  numberOfVersions: number;
};

export type GeneratedTemplate = {
  templateContent: string;
};
