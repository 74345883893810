import { LanguageViewModel } from "src/api";

export type ILanguageState = {
    error: Error | string | null;
    languages?: LanguageViewModel[];
    loaded: boolean;
}
export enum TranslateModel{
    GoogleTranslate,
    ChatGPT,
    Bard
}