import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material';
import { RHFSelect } from 'src/components/hook-form';
import dayjs from 'dayjs';
import Iconify from 'src/components/iconify';
import { useDispatch, useSelector } from 'src/redux/store';
import { TimePicker } from '@mui/x-date-pickers/TimePicker/TimePicker';
import { useEffect, useState } from 'react';
import { saveScheduleInfo, selectAiFlow } from 'src/redux/slices/aiflows';
import { AiFlowLogic, AiFlowScheduleInfoLogic } from 'src/api';
import { useSnackbar } from 'src/components/snackbar';
import _ from 'lodash';

interface Props {
  onClose: () => void;
  open: boolean;
}

export default function AiFlowScheduleSettings({ onClose, open }: Props) {
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const [scheduleType, setScheduleType] = useState(0);
  const [selectedDaysOfTheWeek, setSelectedDaysOfTheWeek] = useState([] as string[]);
  const [selectedMonthlyDate, setSelectedMonthlyDate] = useState(1); // [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
  const [selectedTime, setSelectedTime] = useState(new Date());
  const selectedAiFlowVersion = useSelector((state) => state.aiFlows.selectedAiFlowVersion);
  const handleClose = () => {
    onClose();
  };

  const daysOfWeek = [
    {
      dayFull: 'Monday',
      dayCode: 'Mo',
    },
    { dayFull: 'Tuesday', dayCode: 'Tu' },
    { dayFull: 'Wednsday', dayCode: 'We' },
    { dayFull: 'Thursday', dayCode: 'Th' },
    { dayFull: 'Friday', dayCode: 'Fr' },
    { dayFull: 'Saturday', dayCode: 'Sa' },
    { dayFull: 'Sunday', dayCode: 'Su' },
  ];

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const convertUTCMinutesToLocalDateTime = (utcMinutes: number): Date => {
    // Convert the UTC minutes to milliseconds
    const utcTimeInMs = utcMinutes * 60000;
  
    // Create a new date for today's date at UTC midnight in milliseconds
    const today = new Date();
    const todayAtUTCMidnight = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  
    // Add the UTC minutes to today's UTC midnight
    const targetUTCTimeMs = todayAtUTCMidnight + utcTimeInMs;
  
    // Create a Date object for the target UTC time in local time zone
    const localDateTime = new Date(targetUTCTimeMs);
  
    return localDateTime;
  };

  useEffect(() => {
    if (selectedAiFlow && selectedAiFlow.aiFlowScheduleInfo) {
      setScheduleType(selectedAiFlow.aiFlowScheduleInfo?.type ?? 0); 
      setSelectedTime(convertUTCMinutesToLocalDateTime(selectedAiFlow.aiFlowScheduleInfo?.timeInMinutes as number));
        // dayjs()
        //   .hour(Math.floor((selectedAiFlow.aiFlowScheduleInfo?.timeInMinutes ?? 0) / 60))
        //   .minute((selectedAiFlow?.aiFlowScheduleInfo?.timeInMinutes ?? 0) % 60)
        //   .toDate()
      // );
      setSelectedDaysOfTheWeek(selectedAiFlow.aiFlowScheduleInfo?.days?.split(',') ?? []);
    }
  }, [selectedAiFlow]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span>Schedule Settings</span>
          <IconButton onClick={handleClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ typography: 'body2', overflow: 'hidden', p: 3 }}>
        <Stack direction="column" alignItems="center" spacing={3}>
          <RHFSelect
            zIndex={1400}
            value={scheduleType}
            key="scheduleType"
            name="scheduleType"
            size="small"
            variant="standard"
            label="Schedule Type"
            onChange={(event) => {
              const scType = event.target.value;
              setScheduleType(parseInt(scType, 10));
            }}
            InputLabelProps={{ shrink: true }}
            sx={{ minWidth: { md: 180 } }}
          >
            <MenuItem value={0}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <span>Daily</span>
              </Stack>
            </MenuItem>
            <MenuItem value={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <span>Weekly</span>
              </Stack>
            </MenuItem>
            <MenuItem value={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <span>Monthly</span>
              </Stack>
            </MenuItem>
          </RHFSelect>
          {scheduleType === 1 && (
            <Stack direction="row" spacing={2}>
              {' '}
              {/* Adjust the spacing as needed */}
              {daysOfWeek.map((day, index) => (
                <>
                  <Tooltip key={index} title={day.dayFull} placement="top">
                    <FormControlLabel
                      key={index} // It's generally better to use unique IDs, but day names are unique here
                      control={
                        <Checkbox
                          sx={{ paddingLeft: '0px' }}
                          onChange={(event) => {
                            // Handle change, you can also use day name to identify which checkbox was changed
                            if (event.target.checked) {
                              setSelectedDaysOfTheWeek([...selectedDaysOfTheWeek, day.dayCode]);
                            } else {
                              setSelectedDaysOfTheWeek(
                                selectedDaysOfTheWeek.filter((d) => d !== day.dayCode)
                              );
                            }
                          }}
                          size="small"
                        />
                      }
                      checked={selectedDaysOfTheWeek.includes(day.dayCode)}
                      value={day.dayCode}
                      label={day.dayCode}
                      sx={{ mt: 0, m: 0 }}
                    />
                  </Tooltip>
                </>
              ))}
            </Stack>
          )}
          {scheduleType === 2 && (
            <RHFSelect
              zIndex={1400}
              value={selectedMonthlyDate}
              key="monthlyDate"
              name="monthlyDate"
              size="small"
              variant="standard"
              label="Select Date"
              onChange={(event) => {
                const monthlyDate = parseInt(event.target.value, 10);
                setSelectedMonthlyDate(monthlyDate);
              }}
              InputLabelProps={{ shrink: true }}
              sx={{ minWidth: { md: 180 } }}
            >
              {[...Array(31)].map((t, index) => (
                <MenuItem key={index} value={index + 1}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <span>{index + 1}</span>
                  </Stack>
                </MenuItem>
              ))}
            </RHFSelect>
          )}
          {/* <DesktopTimePicker defaultValue={dayjs('2022-04-17T15:30')} /> */}

          <TimePicker
            value={selectedTime}
            onChange={(date) => {
              setSelectedTime(date as Date);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            // Convert local time to UTC time
            debugger;
            const utcHours = selectedTime.getUTCHours();
            const utcMinutes = selectedTime.getUTCMinutes();
            const updatedScheduleInfo: AiFlowScheduleInfoLogic = {
              ...selectedAiFlow?.aiFlowScheduleInfo,
              type: scheduleType as any,
              aiFlowId: selectedAiFlow?.id as number,
              timeInMinutes: utcHours * 60 + utcMinutes,
              days: scheduleType === 1 ? selectedDaysOfTheWeek.join(',') : null,
              monthlyDate: scheduleType === 2 ? selectedMonthlyDate : null,
            };
            if (!selectedAiFlow?.id){
              const selectedAiFlowClone = _.cloneDeep(selectedAiFlow) as AiFlowLogic;
              selectedAiFlowClone.aiFlowScheduleInfo = updatedScheduleInfo;
              dispatch(selectAiFlow({
                aiFlow: selectedAiFlowClone,
              })); // This is a temporary solution until we have a proper
            }
            else{
              dispatch(saveScheduleInfo(updatedScheduleInfo, enqueueSnackbar));
            }
            onClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
