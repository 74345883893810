import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
} from '@mui/material';
import SmartEditorSettingsNav from './ContentGenerationSettingsNav';
import Scrollbar from 'src/components/scrollbar';
import { RHSelect } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';

interface Props {
  onClose: () => void;
  open: boolean;
}

export default function ContentGenerationSettingsDialog({ onClose, open }: Props) {
  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span>Content Generation Settings</span>
          <IconButton onClick={onClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Scrollbar sx={{ height: 550 }}>
        <DialogContent sx={{ typography: 'body2', minHeight: 530 }}>
          <SmartEditorSettingsNav />
        </DialogContent>
      </Scrollbar>

      <DialogActions sx={{ flexGrow: 1 }}>
        <Stack sx={{ flexGrow: 1 }} display="flex" direction="row" justifyContent="space-between">
          <RHSelect
            name="status"
            size="small"
            label="Selected Settings"
            onChange={(e: any) => {}}
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: { md: 250 } }}
          >
            <MenuItem value="all">
              <span>My Settings</span>
            </MenuItem>
          </RHSelect>
          <Stack display="flex" direction="row" alignItems="center" spacing={3}>
            <Button
              variant="outlined"
              size="large"
              onClick={() => {}}
              sx={{
                maxWidth: { md: 130 },
                maxHeight: { md: 35 },
              }}
            >
              Save New
            </Button>
            <Button
              variant="outlined"
              size="large"
              onClick={() => {}}
              sx={{
                maxWidth: { md: 130 },
                maxHeight: { md: 35 },
              }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="inherit"
              onClick={() => {}}
              sx={{
                maxWidth: { md: 180 },
                maxHeight: { md: 35 },
              }}
            >
              Reset to default
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
