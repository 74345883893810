import { useSelector } from 'src/redux/store';
import AiFlowActionSetup from './AiFlowActionSetup';

export default function AiFlowItemSetup() {
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);

  return (
    <>
      {selectedItem && <AiFlowActionSetup />}
      {/* {selectedItem && selectedItem.type === AiFlowItemType.Delay && <AiFlowDelaySetup />}
      {selectedItem && selectedItem.type === AiFlowItemType.Validator && (
        <AiFlowValidatorSetup />
      )} */}
    </>
  );
}
