import { useEffect } from "react";
import { setupSignalR } from "src/redux/slices/signalR";
import { useDispatch } from "src/redux/store";
import { useSnackbar } from "../snackbar";


export default function SignalRProvider() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setupSignalR(enqueueSnackbar));
  }, [dispatch, enqueueSnackbar]);

    return null;
}
