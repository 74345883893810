import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// routes
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { PATH_AUTH } from 'src/routes/paths';
import RouterLink from 'src/components/router/router-link';
import { AccountApi, OrganizationApi } from 'src/api';
import { Alert, Divider } from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
import { useNavigate, useParams } from 'react-router';
import { setSession } from 'src/auth/utils';
import { GoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function ModernRegisterView() {
  const password = useBoolean();
  const [googleRegisterLoading, setRegisterLoading] = useState(false);
  const [orgId, setOrgId] = useState<number>();
  const { invitePayload } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .test('has-digit', 'Password must contain at least one digit', (value) =>
        /[0-9]/.test(value as string)
      )
      .test(
        'has-non-alphanumeric',
        'Password must contain at least one non-alphanumeric character',
        (value) => /[^A-Za-z0-9]/.test(value as string)
      )
      .test('has-uppercase', 'Password must contain at least one uppercase letter', (value) =>
        /[A-Z]/.test(value as string)
      ),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    password: '',
    afterSubmit: null,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const accountApi = new AccountApi();
      await accountApi.apiAccountRegisterPost({
        email: data.email,
        companyName: data.company,
        username: data.email,
        name: `${data.firstName} ${data.lastName}`,
        organizationId: orgId,
        password: data.password,
      });
      //  enqueueSnackbar('Register successfully', { variant: 'success' });
      if (orgId && invitePayload) {
        navigate(PATH_AUTH.login);
      } else {
        navigate(`${PATH_AUTH.verify}?email=${data.email}`);
      }
    } catch (error) {
      console.error(error);
      reset();
      setError('afterSubmit', {
        ...error,
        message: error.message,
      });
    }
  });

  useEffect(() => {
    const getInviteUserData = async () => {
      const orgApi = new OrganizationApi();
      const inviteUserDataResponse = await orgApi.apiOrganizationInviteUserDataGet(invitePayload);
      setValue('email', inviteUserDataResponse.data.invitedUserEmail || '');
      setValue('company', inviteUserDataResponse.data.organizationName || '');
      setOrgId(inviteUserDataResponse.data.organizationId || 0);
    };
    if (invitePayload) {
      console.log('invitePayload', invitePayload);
      getInviteUserData();
    }
  }, [invitePayload, setValue]);

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
      <Typography variant="h4">Get started absolutely free</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> Already have an account? </Typography>

        <Link href={PATH_AUTH.login} component={RouterLink} variant="subtitle2">
          Sign in
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{
        color: 'text.secondary',
        mt: 2.5,
        typography: 'caption',
        textAlign: 'center',
      }}
    >
      {'By signing up, I agree to '}
      <Link underline="always" color="text.primary">
        Terms of Service
      </Link>
      {' and '}
      <Link underline="always" color="text.primary">
        Privacy Policy
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <RHFTextField name="firstName" label="First name" />
        <RHFTextField name="lastName" label="Last name" />
      </Stack>

      <RHFTextField disabled={!!invitePayload} name="company" label="Company Name*" />
      <RHFTextField disabled={!!invitePayload} name="email" label="Email address" />

      <RHFTextField
        name="password"
        label="Password"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="outlined"
        loading={isSubmitting || googleRegisterLoading}
        endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
      >
        Create account
      </LoadingButton>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack alignItems="center" alignSelf="center" spacing={2}>
        <GoogleLogin
          auto_select
          text="signup_with"
          onSuccess={async (credentialResponse) => {
            //            setLoggingIn(true);
            // Extract the ID token from the Google response
            const idToken = credentialResponse.credential;
            const accountApi = new AccountApi();
            try {
              setRegisterLoading(true);
              const result: any = await accountApi.apiAccountGooglePost({
                token: idToken,
              });
              setSession(result.data.access_token);
              window.location.href = `${process.env.REACT_APP_WEBAPP_URL}`;
            } catch (error) {
              enqueueSnackbar('Error logging in with Google', { variant: 'error' });
            } finally {
              setRegisterLoading(false);
            }

            // const api = BaseAPI.getInstance();
            // api.handleTokenUpdate(result.data.access_token);
            // InitStore(dispatch);

            //    pubSub.publish('login_success', result.data.access_token);
            // setTimeout(() => {
            // window.location.href = "https://localhost:8080/dashboard" as string;
            // }, 0);
            //   navigate(PATH_MARKETING.root, { replace: true });
          }}
          onError={() => {
            //         setLoggingIn(false);
          }}
          useOneTap
        />
      </Stack>
      <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        OR
      </Divider>
      {renderHead}

      {renderForm}

      {renderTerms}
    </FormProvider>
  );
}
