import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Stack,
  IconButton,
  Typography,
  Paper,
  MenuItem,
} from '@mui/material';
import _ from 'lodash';
import test from 'node:test';
import { useEffect, useState } from 'react';
import { ConnectionLogic, Integration } from 'src/api';
import ConfirmDialog from 'src/components/confirm-dialog';
import { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import MenuPopover from 'src/components/menu-popover';
import { useSnackbar } from 'src/components/snackbar';
import { useBoolean } from 'src/hooks/use-boolean';
import { deleteConnection, fetchConnections, upsertConnection } from 'src/redux/slices/connections';
import { authenticate, testApiKey } from 'src/redux/slices/integrations';
import { useDispatch, useSelector } from 'src/redux/store';
import IntegrationAuthDialog from './integration-auth-dialog';

export interface Props {
  onClose: () => void;
  open: boolean;
  connectionInputId?: number;
  integration: Integration;
}

export default function ManageConnectionDialog({
  onClose,
  open,
  connectionInputId,
  integration,
}: Props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [testingApiKey, setTestingApiKey] = useState(false);
  const oauthIframeDialog = useBoolean(false);
  const integrationLoading = useSelector((state) => state.integration.loading);
  const connectionsLoading = useSelector((state) => state.connection.loading);
  const connections = useSelector((state) => state.connection.connections);
  const [integrationConnections, setIntegrationConnections] = useState<ConnectionLogic[]>([]);
  const openOptionPopover = usePopover();
  const [iframeUri, setIframeUri] = useState<string>('');
  const openConfirm = useBoolean(false);
  const [selectedConnection, setSelectedConnection] = useState<ConnectionLogic>();
  const [apiKey, setApiKey] = useState<string>('');
  const [connectionName, setConnectionName] = useState<string>('');
  const handleUpsertApiKey = async () => {
    // dispatch(
    // //  upsertLlmProviderKey({ apiKey, id: llmProviderKeyId, provider: llmProvider }, enqueueSnackbar)
    // );
    const connection: ConnectionLogic = {
      connectionName: connectionName as string,
      apiKeyDetails: {
        apiKey: apiKey as string,
      },
      integrationName: integration.integrationName as string,
      integrationType: integration.integrationType as string,
    };
    dispatch(upsertConnection(connection, enqueueSnackbar));
    onClose();
  };
  useEffect(() => {
    if (connections && open) {
      const tmpConnection = connections.filter(
        (organizationIntegration) =>
          organizationIntegration.integrationName === integration.integrationName
      );
      setIntegrationConnections(tmpConnection);
    }
  }, [connections, open, integration]);

  const integrations = useSelector((state) => state.integration.integrations);

  useEffect(() => {
    if (connectionInputId) {
      const connection = connections.find(
        (organizationIntegration) => organizationIntegration.id === connectionInputId
      );
      if (connection) {
        setSelectedConnection(connection);
        setConnectionName(connection.connectionName as string);
        setApiKey(connection.apiKeyDetails?.apiKey as string);
      }
    }
  }, [connections, connectionInputId, open]);

  // useEffect(() => {
  //   if (llmKeyProviders && llmKeyProviders[llmProvider as number]) {
  //     const llmProviderKey = llmKeyProviders[llmProvider as number].find(
  //       (llmKeyProvider) => llmKeyProvider.id === llmProviderKeyId
  //     );
  //     if (llmProviderKey) {
  //       setApiKey(llmProviderKey.apiKey as string);
  //     }
  //     else{
  //       setApiKey('');
  //     }
  //   }
  //   else{
  //     setApiKey('');
  //   }
  // }, [llmKeyProviders, llmProviderKeyId, llmProvider,open]);

  const handleClose = () => {
    setApiKey('');
    setConnectionName('');
    setSelectedConnection(undefined);

    onClose();
  };

  // useEffect(() => {
  //   if (!oauthIframeDialog){
  //     dispatch(fetchConnections());
  //   }

  // }, [oauthIframeDialog, dispatch]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span> Manage Connection</span>
          <IconButton onClick={handleClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          alignItems="center"
          spacing={4}
          sx={{ p: 3, minWidth: 300, width: 400 }}
        >
          <Typography variant="subtitle1">{integration?.integrationName}</Typography>
          {integration?.integrationType === 'apiKey' && (
            <>
              <TextField
                autoFocus
                margin="dense"
                sx={{ maxWidth: 250 }}
                id="connectionName"
                value={connectionName}
                onChange={(e) => setConnectionName(e.target.value)}
                label="Connection Name"
                type="email"
                fullWidth
                variant="standard"
              />
              <TextField
                autoFocus
                margin="dense"
                sx={{ maxWidth: 250 }}
                id="apiKey"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                label="Api Key"
                type="email"
                fullWidth
                variant="standard"
              />
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                  variant="outlined"
                  disabled={
                    integrationLoading ||
                    connectionsLoading ||
                    !apiKey ||
                    !connectionName ||
                    testingApiKey
                  }
                  onClick={() => {
                    let upsertIntegrationObj;
                    if (selectedConnection) {
                      upsertIntegrationObj = {
                        ...selectedConnection,
                        connectionName,
                        apiKeyDetails: {
                          apiKey,
                        },
                      };
                    } else {
                      upsertIntegrationObj = {
                        connectionName,
                        apiKeyDetails: {
                          apiKey,
                        },
                        integrationName: integration.integrationName as string,
                        integrationType: integration.integrationType as string,
                      };
                    }
                    dispatch(upsertConnection(upsertIntegrationObj, enqueueSnackbar));
                  }}
                >
                  {selectedConnection ? 'Update Connection' : 'Add Connection'}
                </Button>
                <Button
                  disabled={integrationLoading || !apiKey || testingApiKey || connectionsLoading}
                  variant="outlined"
                  onClick={() => {
                    dispatch(
                      testApiKey(apiKey, integration.integrationName as string, enqueueSnackbar)
                    );
                  }}
                >
                  Test Connection
                </Button>
              </Stack>
            </>
          )}
          {integration?.integrationType === 'oauth' && (
            <Button
              variant="outlined"
              onClick={() => {

                const state = encodeURIComponent(
                  JSON.stringify({
                    newBrowserWindow: integration?.newBrowserWindow,
                    provider: integration?.integrationName,
                  })
                );
                const oauthName = integration?.integrationName?.replace(' ', '').toUpperCase();
                const oauthName_OAUTH_URI = `REACT_APP_${oauthName}_OAUTH_URI`;
                let oauthURL = `${process.env[oauthName_OAUTH_URI]}`;
                oauthURL += `&state=${state}`;

                if (integration?.newBrowserWindow) {
                  window.open(oauthURL, '_blank', 'width=700,height=650');
                } else {
                  setIframeUri(oauthURL as string);
                  oauthIframeDialog.onTrue();
                }
              }}
            >
              Sync Account
            </Button>
          )}
          {integrationConnections.length > 0 && (
            <>
              <Typography variant="subtitle1">Connections</Typography>
              <Stack spacing={2}>
                {integrationConnections.map((conn) => (
                  <>
                    <Stack direction="row" alignItems="center">
                      <Paper
                        variant="outlined"
                        sx={{
                          p: 1,
                          minWidth: 350,
                          maxHeight: 130,
                          position: 'relative',
                          cursor: 'pointer',
                          '&:hover': {
                            border: '1px solid',
                            borderColor: 'primary.main',
                          },
                          ...(selectedConnection?.id === conn.id && {
                            border: '1px solid',
                            borderColor: 'primary.main',
                          }),
                        }}
                        onClick={() => {
                          if (_.isEqual(selectedConnection, conn)) {
                            setSelectedConnection(undefined);
                            setApiKey('');
                            setConnectionName('');
                            return;
                          }
                          setSelectedConnection(conn);
                          setConnectionName(conn.connectionName as string);
                          setApiKey(conn.apiKeyDetails?.apiKey as string);
                        }}
                      >
                        {conn.integrationType === 'apiKey' && (
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="subtitle2">
                              {conn?.apiKeyDetails?.apiKey &&
                                `${conn?.apiKeyDetails?.apiKey.substring(
                                  0,
                                  3
                                )}**...${conn.apiKeyDetails.apiKey.substring(
                                  conn.apiKeyDetails.apiKey.length - 4
                                )}`}
                            </Typography>
                            <Typography variant="subtitle2">Name:{conn?.connectionName}</Typography>
                            <Stack direction="row" alignItems="center">
                              {conn?.default && <Label color="success">Default</Label>}
                              <IconButton
                                disabled={
                                  !selectedConnection ||
                                  (selectedConnection && selectedConnection?.id !== conn.id)
                                }
                                onClick={(event) => {
                                  event.stopPropagation();
                                  openOptionPopover.onOpen(event);
                                }}
                              >
                                <Iconify icon="eva:more-vertical-fill" />
                              </IconButton>
                            </Stack>
                          </Stack>
                        )}

                        {conn.integrationType === 'oauth' && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Typography variant="subtitle2">
                              Token: &nbsp;
                              {conn?.oauthDetails?.accessToken &&
                                `${conn?.oauthDetails?.accessToken?.substring(
                                  0,
                                  3
                                )}**...${conn.oauthDetails?.accessToken?.substring(
                                  conn.oauthDetails.accessToken.length - 5
                                )}`}
                            </Typography>
                            {conn?.oauthDetails?.account && (
                              <Typography variant="subtitle2">
                                Account:&nbsp;
                                {conn?.oauthDetails?.account}
                              </Typography>
                            )}

                            <Stack direction="row" alignItems="center">
                              {conn?.default && <Label color="success">Default</Label>}
                              <IconButton
                                disabled={
                                  !selectedConnection ||
                                  (selectedConnection && selectedConnection?.id !== conn.id)
                                }
                                onClick={(event) => {
                                  event.stopPropagation();
                                  openOptionPopover.onOpen(event);
                                }}
                              >
                                <Iconify icon="eva:more-vertical-fill" />
                              </IconButton>
                            </Stack>
                          </Stack>
                        )}
                      </Paper>
                    </Stack>
                  </>
                ))}
              </Stack>
            </>
          )}
        </Stack>
        <MenuPopover
          open={openOptionPopover.open}
          onClose={openOptionPopover.onClose}
          arrow="right-top"
          sx={{ width: 180 }}
        >
          <MenuItem
            onClick={() => {
              if (!selectedConnection) {
                return;
              }
              const updatedIntegration = {
                ...selectedConnection,
                default: true,
              };
              dispatch(upsertConnection(updatedIntegration, enqueueSnackbar));
              setSelectedConnection(updatedIntegration);
              openOptionPopover.onClose();
            }}
          >
            <Iconify icon="healthicons:default-outline" />
            Set Default
          </MenuItem>

          <MenuItem
            onClick={() => {
              openConfirm.onTrue();
              openOptionPopover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="eva:trash-2-outline" />
            Delete
          </MenuItem>
        </MenuPopover>
      </DialogContent>
      <ConfirmDialog
        open={openConfirm.value}
        onClose={openConfirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (!selectedConnection) return;
              dispatch(deleteConnection(selectedConnection.id as number, enqueueSnackbar));
              setSelectedConnection(undefined);
              setApiKey('');
              openConfirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
      {/* <DialogActions>
        <Button disabled={loading || testingApiKey} onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={loading || !apiKey || testingApiKey}
          onClick={async () => {
            setTestingApiKey(true);
            const integrationApi = new IntegrationsApi();
            const valid = await integrationApi.apiIntegrationsTestApiKeyGet(
              apiKey,
              selectedOrgIntegration?.integrationName as string
            );
            setTestingApiKey(false);
            if (valid.data) {
              enqueueSnackbar('Api Key is valid', { variant: 'success' });
            } else {
              enqueueSnackbar('Api Key is invalid', { variant: 'error' });
            }
          }}
        >
          Test Api Key
        </Button>
        <Button disabled={loading || !apiKey || testingApiKey} onClick={handleUpsertApiKey}>
          Save
        </Button>
      </DialogActions> */}
      <IntegrationAuthDialog
        open={oauthIframeDialog.value}
        onClose={oauthIframeDialog.onFalse}
        iframeUri={iframeUri}
      />
    </Dialog>
  );
}
