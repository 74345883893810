import { yupResolver } from '@hookform/resolvers/yup';
import { Button, IconButton, MenuItem, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import FormProvider, { RHSelect } from 'src/components/hook-form';
import PreviousActionsSelect from 'src/components/previous-actions/PreviousActionsSelect';
import { setActionValidated, updateSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import { useForm } from 'react-hook-form';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';
import Iconify from 'src/components/iconify';

export default function AiFlowCombineActionSetup() {
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [selectedSources, setSelectedSources] = useState<string[]>(['']);
  const [selectedCombineWith, setSelectedCombinedWith] = useState<string>('Newline');
  const [selectedSourceType, setSelectedSourceType] = useState<string>('Text');
  const [combineSourcesOptions, setCombineSourcesOptions] = useState([
    { label: 'Single Source', value: 'SingleSource' },
    {
      label: 'Multiple Sources',
      value: 'MultiSource',
      disabled: (selectedItem?.sequence as number) < 3,
    },
  ]);
  const [sourceSelectionMode, setSourceSelectionMode] = useState<string>('SingleSource');
  const [combineWith, setCombineWith] = useState([
    { label: 'Comma', value: 'Comma' },
    { label: 'New Line', value: 'Newline' },
  ]);

  const defaultValues = {
    //   selectedSource: '',
  };

  const AiFlowReconstructSchema: any = Yup.object().shape({
    //  selectedSource: Yup.string().required('Required'),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowReconstructSchema),
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.inputData?.actionInputData?.functionalInputData?.combineInput) {
        setSelectedSources(
          (selectedItem.inputData?.actionInputData?.functionalInputData?.combineInput
            ?.sources as string[]) ?? ['']
        );
        setSourceSelectionMode(
          selectedItem.inputData?.actionInputData?.functionalInputData?.combineInput
            ?.sourceSelectionMode as string
        );
        setSelectedSourceType(
          selectedItem.inputData?.actionInputData?.functionalInputData?.combineInput
            ?.sourceType as string
        );
        // methods.setValue(
        //   'selectedSource',
        //   selectedItem.inputData?.actionInputData?.functionalInputData?.combineInput
        //     ?.source as string
        // );
        methods.trigger();
        setSelectedCombinedWith(
          (selectedItem.inputData?.actionInputData?.functionalInputData?.combineInput
            ?.combineWith as string) ?? 'NewLine'
        );
      }
    }
  }, [selectedItem, methods]);

  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);

  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3} sx={{ mx: 1 }}>
          <RHRadioGroup
            value={sourceSelectionMode}
            onChange={(event) => {
              setSourceSelectionMode(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      combineInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.combineInput,
                        sourceSelectionMode: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
            row
            spacing={4}
            name="sourceSelectionMode"
            options={combineSourcesOptions}
          />
          {selectedSources &&
            selectedSources.map((src, index) => (
              <>
                <Stack
                  direction="row"
                  alignItems="end"
                  display="flex"
                  alignContent="center"
                  sx={{ flexGrow: 1 }}
                >
                  <PreviousActionsSelect
                    disabledSources={selectedSources}
                    flexGrow={1}
                    selectedSourceInput={src}
                    onPreviousActionsSelect={(source: string) => {
                      const updatedSources = [...selectedSources];
                      updatedSources[index] = source;
                      setSelectedSources(updatedSources);
                      setValue('sourceSelectionMode', source);
                      const updatedItem = {
                        ...selectedItem,
                        inputData: {
                          ...selectedItem?.inputData,
                          actionInputData: {
                            ...selectedItem?.inputData?.actionInputData,
                            functionalInputData: {
                              ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                              combineInput: {
                                ...selectedItem?.inputData?.actionInputData?.functionalInputData
                                  ?.combineInput,
                                sources: updatedSources,
                              },
                            },
                          },
                        },
                      };
                      dispatch(updateSelectedAiFlowItem(updatedItem));
                    }}
                  />

                  <IconButton
                    onClick={() => {
                      const updatedSources = [...selectedSources];
                      updatedSources.splice(index, 1);
                      setSelectedSources(updatedSources);
                      const updatedItem = {
                        ...selectedItem,
                        inputData: {
                          ...selectedItem?.inputData,
                          actionInputData: {
                            ...selectedItem?.inputData?.actionInputData,
                            functionalInputData: {
                              ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                              combineInput: {
                                ...selectedItem?.inputData?.actionInputData?.functionalInputData
                                  ?.combineInput,
                                sources: updatedSources,
                              },
                            },
                          },
                        },
                      };
                      dispatch(updateSelectedAiFlowItem(updatedItem));
                    }}
                    disabled={index === 0}
                  >
                    <Iconify icon="material-symbols:close" width={20} height={20} />
                  </IconButton>
                </Stack>
              </>
            ))}
          {sourceSelectionMode === 'MultiSource' && (
            <Button
              disabled={
                selectedSources &&
                selectedSources.length > 0 &&
                (selectedItem?.sequence ?? 1) - 2 < selectedSources.length
              }
              color="inherit"
              sx={{ minWidth: 160 }}
              onClick={() => {
                setSelectedSources([...selectedSources, '']);
              }}
              variant="outlined"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Source
            </Button>
          )}

          <RHSelect
            key="sourceType"
            name="sourceType"
            size="small"
            variant="standard"
            value={selectedSourceType}
            onChange={(e) => {
              setSelectedSourceType(e.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      combineInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.combineInput,
                        sourceType: e.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
            label="Source Type"
            zIndex={2500}
            InputLabelProps={{ shrink: true }}
            sx={{ px: 2 }}
          >
            <MenuItem value="Text">
              <span>Text</span>
            </MenuItem>
            <MenuItem value="Json">
              <span>Json</span>
            </MenuItem>
          </RHSelect>

          {selectedSourceType === 'Text' && (
            <RHSelect
              key="combineWith"
              name="combineWith"
              size="small"
              variant="standard"
              value={selectedCombineWith}
              onChange={(e) => {
                setSelectedCombinedWith(e.target.value);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      functionalInputData: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                        combineInput: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData
                            ?.combineInput,
                          combineWith: e.target.value,
                        },
                      },
                    },
                  },
                };
                dispatch(updateSelectedAiFlowItem(updatedItem));
              }}
              label="Combine With"
              helperText="Combine data into a single unified data."
              zIndex={2500}
              InputLabelProps={{ shrink: true }}
              sx={{ px: 2 }}
            >
              {combineWith.map((item) => (
                <MenuItem value={item.value}>
                  <span>{item.label}</span>
                </MenuItem>
              ))}
            </RHSelect>
          )}
          {selectedCombineWith === 'Json' && (
            <TextField
              key="propertyName"
              name="propertyName"
              size="small"
              variant="standard"
              label="Property Name"
              value={
                selectedItem?.inputData?.actionInputData?.functionalInputData?.combineInput
                  ?.propertyName ?? ''
              }
              onChange={(e) => {
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      functionalInputData: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                        combineInput: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData
                            ?.combineInput,
                          propertyName: e.target.value,
                        },
                      },
                    },
                  },
                };
                dispatch(updateSelectedAiFlowItem(updatedItem));
              }}
              InputLabelProps={{ shrink: true }}
              sx={{ px: 2 }}
            />
          )}
        </Stack>
      </FormProvider>
    </>
  );
}
