import { createSlice } from '@reduxjs/toolkit';
// @types
import { IPromptFeedbackToolbar } from 'src/@types/promptFeedbackToolbar';

// ----------------------------------------------------------------------

const initialState: IPromptFeedbackToolbar = {
  open: undefined,
  error: null,
};

const slice = createSlice({
  name: 'promptFeedbackToolbar',
  initialState,
  reducers: {
    // START LOADING
    setOpenPromptFeedbackToolbar(state, action) {
      state.open = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Reducer
export const { setOpenPromptFeedbackToolbar, hasError } = slice.actions;

// ----------------------------------------------------------------------
