// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import FormProvider, { RHFTextField } from '../hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// assets
// components

// ----------------------------------------------------------------------

export default function CustomVertsCard() {
  const price = 0.005;
  const minimumNumOfVerts = 100;
  const defaultValues = {
    numberOfVerts: minimumNumOfVerts,
  };
  // const [numOfVerts, setNumOfVerts] = useState(10); // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9
  const [totalPrice, setTotalPrice] = useState(0);
  const priceSchema: any = Yup.object().shape({
    numberOfVerts: Yup.number()
      .transform((value, originalValue) => (originalValue.trim() === '' ? 0 : value))
      .required('Required')
      .typeError('Number of Verts must be a number')
      .min(minimumNumOfVerts, `Minimum Required: ${minimumNumOfVerts}`),
  });
  const methods = useForm<any>({
    resolver: yupResolver(priceSchema),
    mode: 'all',
    defaultValues,
  });
  useEffect(() => {
    methods.reset({
      numberOfVerts: 10,
    });
  }, [methods]);

  const numOfVerts = methods.watch('numberOfVerts');

  useEffect(() => {
    methods.setValue('numberOfVerts', numOfVerts);
    if (numOfVerts) {
      setTotalPrice(numOfVerts * price);
    }
  }, [numOfVerts, methods]);

  return (
    <FormProvider methods={methods}>
      <Card
        sx={{
          '&:hover': {
            border: '1px solid',
            borderColor: 'primary.main',
          },
          minHeight: 202,
          borderWidth: 2,
          border: '1px solid',
          p: 2,
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Stack spacing={3}>
          {/* <Typography variant="h3" align="center">
        Custom
        </Typography> */}

          <RHFTextField
            fullWidth
            name="numberOfVerts"
            defaultValue={numOfVerts}
            size="small"
            label="Number of Verts"
            type="number"
          />

          <Typography variant="h4" align="center">
            {totalPrice}$
          </Typography>
          <Button
            disabled={numOfVerts < minimumNumOfVerts}
            variant="outlined"
            color="primary"
            fullWidth
          >
            Buy
          </Button>
        </Stack>
      </Card>
    </FormProvider>
  );
}
