import { useCallback, useEffect, useState } from 'react';
// @mui
import {
  Card,
  Stack,
  Paper,
  Button,
  Typography,
  Box,
  Container,
  TextField,
  InputAdornment,
} from '@mui/material';
// components
// section
import IntegrationAuthDialog from './integration-auth-dialog';
import { useDispatch, useSelector } from 'src/redux/store';
import { authenticate } from 'src/redux/slices/integrations';
import Label from 'src/components/label';
import { useBoolean } from 'src/hooks/use-boolean';
import usePopover from 'src/components/custom-popover/use-popover';
import ManageConnectionDialog from 'src/pages/integrations/ManageConnectionDialog';

import { useSnackbar } from 'src/components/snackbar';
import { Integration, IntegrationGroupLogic } from 'src/api';
import MuiImage from 'src/components/image';
import { Helmet } from 'react-helmet-async';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function IntegrationsPage() {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const [iframeUri, setIframeUri] = useState('');
  const dispatch = useDispatch();
  const connections = useSelector((state) => state.connection.connections);
  const handleOpen = (integration: Integration) => {
    setSelectedIntegration(integration);
    if (integration.integrationType === 'apiKey') {
      openManageConnectionDialog.onTrue();
    } else if (integration.integrationType === 'connection') {
      //  console.log('integration', integration);
    } else if (integration.integrationType === 'oauth') {
      openManageConnectionDialog.onTrue();
    }
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const callbackHandler = (event: any) => {
      if (event && event.data && event.data.code) {
        dispatch(authenticate(event.data.code, event.data.provider));
        handleClose();
      }
    };
    window.addEventListener('message', callbackHandler);
    return () => window.removeEventListener('message', callbackHandler);
  }, [dispatch, handleClose]);

  const openManageConnectionDialog = useBoolean(false);
  const openOptionPopover = usePopover();
  const integrationGroups = useSelector((state) => state.integration.integrationGroups);
  const [selectedConnection, setSelectedIntegration] = useState<Integration>();

  const [filteredIntegrationGroups, setFilteredIntegrationGroups] =
    useState<any[]>([]);

    useEffect(() => {
      if (integrationGroups) {
        setFilteredIntegrationGroups(integrationGroups);
      }
    }, [integrationGroups])

  return (
    <>
      <ManageConnectionDialog
        open={openManageConnectionDialog.value}
        onClose={openManageConnectionDialog.onFalse}
        integration={selectedConnection as Integration}
      />
      <Helmet>
        <title> Integrations</title>
      </Helmet>

      <Container maxWidth={false}>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Typography variant="h4" sx={{ mb: 1, mt: 1 }}>
              {' '}
              Integrations{' '}
            </Typography>
            <TextField
              placeholder="Search Integrations"
              onChange={(e) => {
                setSearchValue(e.target.value);
                if (e.target.value && e.target.value.length > 0) {
                  const filteredGroups = integrationGroups
                    .map((group) => {
                      const filteredIntegrations = group.integrations?.filter((integrationTmp) => {
                        const integrationLabel = integrationTmp.integrationLabel?.toLowerCase();
                        const searchValueTmp = e.target.value.toLowerCase();
                        return integrationLabel?.includes(searchValueTmp);
                      });
                      if (filteredIntegrations && filteredIntegrations.length > 0) {
                        return { ...group, integrations: filteredIntegrations };
                      } else {
                        return null; // Return null if no integrations are left after filtering
                      }
                    })
                    .filter((group) => group !== null); // Filter out the null groups;
                  setFilteredIntegrationGroups(filteredGroups);
                } else {
                  setFilteredIntegrationGroups(integrationGroups);
                }
              }}
              sx={{ maxWidth: 400 }}
              InputProps={{
                //    ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
                  </InputAdornment>
                ),
                endAdornment: searchValue && (
                  <InputAdornment position="end">
                    <Iconify
                      onClick={() => {
                        setFilteredIntegrationGroups(integrationGroups);
                        setSearchValue('');
                      }}
                      icon="eva:close-fill"
                      sx={{ mr: 1, color: 'text.disabled', cursor: 'pointer' }}
                    />
                  </InputAdornment>
                ),
              }}
              value={searchValue}
            />
          </Stack>

          {filteredIntegrationGroups &&
            filteredIntegrationGroups.map(
              (integrationGroup: IntegrationGroupLogic, ind: number) => (
                <Card key={ind} sx={{ p: 3, mb: 3 }}>
                  <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                    <Typography
                      variant="overline"
                      sx={{
                        flexGrow: 1,
                        color: 'text.secondary',
                      }}
                    >
                      {integrationGroup.label}
                    </Typography>
                  </Stack>
                  <Box
                    gap={3}
                    sx={{ height: '100%', width: '100%' }}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(2, 1fr)',
                      md: 'repeat(3, 1fr)',
                    }}
                  >
                    {integrationGroup &&
                      integrationGroup.integrations &&
                      integrationGroup.integrations.map((integration) => (
                        <>
                          {integration?.visible && (
                            <Stack
                              component={Paper}
                              spacing={1.5}
                              variant="outlined"
                              sx={{
                                p: 2.5,
                                mx: 2,
                                position: 'relative',
                              }}
                            >
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                              >
                                <Stack spacing={1}>
                                  <MuiImage
                                    alt="icon"
                                    src={`/assets/images/integrations/${integration.integrationName}.svg`}
                                    sx={{ mb: 1, maxWidth: 40, maxHeight: 42 }}
                                  />

                                  <Typography variant="subtitle2">
                                    {integration.integrationLabel}
                                  </Typography>
                                </Stack>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="center"
                                  sx={{ mt: 3 }}
                                  spacing={2}
                                >
                                  {/* <Button
                            fullWidth
                            disabled={!integration.enabled}
                            sx={{
                              maxWidth: { xs: 100, md: 100, lg: 100, xl: 150 },
                            }}
                            onClick={() => handleOpen(integration)}
                            variant="outlined"
                            size="large"
                          >
                            {integration.integrationType === 'apiKey' && 'Add Api Key'}
                            {integration.integrationType === 'connection' && 'Add Connection'}
                            {integration.integrationType === 'oauth' && 'Connect'}
                          </Button> */}
                                  <Button
                                    fullWidth
                                    disabled={!integration.enabled}
                                    sx={{
                                      maxWidth: { xs: 100, md: 100, lg: 100, xl: 200 },
                                    }}
                                    onClick={() => handleOpen(integration)}
                                    variant="outlined"
                                    size="large"
                                  >
                                    Connection
                                  </Button>
                                  {!integration.enabled && (
                                    <Label
                                      color="success"
                                      sx={{ position: 'absolute', right: '50%', bottom: '5px' }}
                                    >
                                      Soon
                                    </Label>
                                  )}
                                  {connections &&
                                    connections.find(
                                      (c) => c.integrationName === integration.integrationName
                                    ) && (
                                      <Typography
                                        variant="caption"
                                        sx={{ mt: 1, position: 'absolute', bottom: 5, right: 50 }}
                                      >
                                        {
                                          connections.filter(
                                            (c) => c.integrationName === integration.integrationName
                                          ).length
                                        }{' '}
                                        {connections.filter(
                                          (c) => c.integrationName === integration.integrationName
                                        ).length === 1
                                          ? 'connection'
                                          : 'connections'}
                                      </Typography>
                                    )}
                                </Stack>
                              </Stack>
                              {/* {organizationIntegrations &&
                        organizationIntegrations
                          .filter((oi) => oi.integrationName === integration.integrationName)
                          .map((orgIntegration) => (
                            <>
                              <Stack direction="row" alignItems="center">
                                <Paper
                                  variant="outlined"
                                  sx={{
                                    p: 1,
                                    width: 1,
                                    maxHeight: 130,
                                    position: 'relative',
                                  }}
                                >
                                  {orgIntegration.integrationType === 'apiKey' && (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography variant="subtitle2">
                                        {orgIntegration?.apiKeyDetails?.apiKey &&
                                          `${orgIntegration?.apiKeyDetails?.apiKey.substring(
                                            0,
                                            4
                                          )}...${orgIntegration.apiKeyDetails.apiKey.substring(
                                            orgIntegration.apiKeyDetails.apiKey.length - 4
                                          )}`}
                                      </Typography>
                                      <Stack direction="row" alignItems="center" spacing={2}>
                                        {orgIntegration?.apiKeyDetails?.active && (
                                          <Label color="success">Active</Label>
                                        )}
                                        <IconButton
                                          onClick={(event) => {
                                            // setSelectedLlmProvider(llmProvider.type);
                                            // setSelectedLlmProviderId(orgIntegration.id as number);
                                            openOptionPopover.onOpen(event);
                                          }}
                                        >
                                          <Iconify icon="eva:more-vertical-fill" />
                                        </IconButton>
                                      </Stack>
                                    </Stack>
                                  )}
                                </Paper>
                              </Stack>
                            </>
                          ))} */}
                            </Stack>
                          )}
                        </>
                      ))}
                  </Box>
                </Card>
              )
            )}
        </Stack>
        {/* <FileNewFolderDialog open={open} onClose={handleClose} /> */}
        <IntegrationAuthDialog iframeUri={iframeUri} open={open} onClose={handleClose} />
      </Container>
    </>
  );
}
