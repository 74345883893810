import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IExpressionState } from 'src/@types/expressions';
// @types
import { EmailProviderApi, PersonaApi, PersonaLogic } from 'src/api';

// ----------------------------------------------------------------------

const initialState: IExpressionState = {
  personas: [],
  selectedPersona: null,
  brandVoices: [],
  selectedBrandVoice: null,
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'expressions',
  initialState,
  reducers: {
    fetchPersonasSuccess(state, action) {
      state.loading = false;
      state.personas = action.payload;
      state.loaded = true;
    },
    fetchBrandVoicesSuccess(state, action) {
      state.loading = false;
      state.brandVoices = action.payload;
      state.loaded = true;
    },
    updatePersona(state, action) {
      const index = state.personas.findIndex((persona) => persona.id === action.payload.id);
      state.personas[index] = action.payload;
      state.loading = false;
    },
    addPersona(state, action) {
      state.personas.push(action.payload);
      state.loading = false;
    },
    updateBrandVoice(state, action) {
      const index = state.brandVoices.findIndex(
        (brandVoice) => brandVoice.id === action.payload.id
      );
      state.brandVoices[index] = action.payload;
    },
    addBrandVoice(state, action) {
      state.brandVoices.push(action.payload);
    },
    setSelectedPersona(state, action) {
      state.selectedPersona = action.payload;
    },
    deletePersonaSuccess(state, action) {
      state.personas = state.personas.filter((persona) => persona.id !== action.payload);
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

export const { setSelectedPersona } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchPersonas() {
  return async (dispatch: Dispatch) => {
    try {
      const personaApi = new PersonaApi();
      const response = await personaApi.apiPersonaGet();
      dispatch(slice.actions.fetchPersonasSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function savePersona(persona: PersonaLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const personaApi = new PersonaApi();
      dispatch(slice.actions.setLoading(true));
      let response: any;
      if (!persona.id) {
        response = await personaApi.apiPersonaPost({ persona });
        dispatch(slice.actions.addPersona(persona));
      } else {
        response = await personaApi.apiPersonaPut({ persona });
        dispatch(slice.actions.updatePersona(persona));
      }
      enqueueSnackbar('Persona was saved succesfully', { variant: 'success' });
      dispatch(slice.actions.setSelectedPersona(response.data));
    } catch (error) {
      enqueueSnackbar('Error saving persona', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setLoading(false));
    }
  };
}
export function deletePersona(personaId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const personaApi = new PersonaApi();
      const response = await personaApi.apiPersonaDelete({ personaId });
      enqueueSnackbar('Persona was deleted succesfully', { variant: 'success' });
      dispatch(slice.actions.deletePersonaSuccess(personaId));
    } catch (error) {
      enqueueSnackbar('Error deleting segments', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    }
  };
}
