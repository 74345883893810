import { createSlice, Dispatch } from '@reduxjs/toolkit';
import _ from 'lodash';
import { INotificationState } from 'src/@types/notifications';
import { NotificationApi, NotificationLogic, NotificationSettingsLogic } from 'src/api';

// ----------------------------------------------------------------------

const initialState: INotificationState = {
  error: null,
  notifications: [],
  notificationSettings: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetchNotificationsSuccess(state, action) {
      state.notifications = action.payload;
    },
    fetchNotificationSettingsSuccess(state, action) {
      state.notificationSettings = action.payload;
    },
    updateNotificationSettingsSuccess(state, action) {
      state.notificationSettings = action.payload;
    },
    addNotificationSuccess(state, action) {
      const cloned = _.cloneDeep(state.notifications);
      cloned.unshift(action.payload);
      state.notifications = cloned;
    },
    updateNotificationSuccess(state, action) {
      const updatedNotifications = action.payload;
      state.notifications = state.notifications.map((notification) => {
        const exist = updatedNotifications.find(
          (updatedNotification: NotificationLogic) => updatedNotification.id === notification.id
        );
        if (exist) {
          return exist;
        }
        return notification;
      });
    },
    hasError(state, action) {
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { hasError, addNotificationSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function fetchNotifications(skip: number = 0, take: number = 20) {
  return async (dispatch: Dispatch) => {
    try {
      const api = new NotificationApi();
      const notificationResponse = await api.apiNotificationNotificationsGet(skip, take);
      dispatch(slice.actions.fetchNotificationsSuccess(notificationResponse.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchNotificationSettings() {
  return async (dispatch: Dispatch) => {
    try {
      const api = new NotificationApi();
      const notificationResponse = await api.apiNotificationNotificationSettingsGet();
      dispatch(slice.actions.fetchNotificationSettingsSuccess(notificationResponse.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateNotificationSettings(notificationSettings: NotificationSettingsLogic) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.updateNotificationSettingsSuccess(notificationSettings));
      const api = new NotificationApi();
      await api.apiNotificationNotificationSettingsPut(notificationSettings);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchAllNotifications() {
  return async (dispatch: Dispatch) => {
    try {
      const api = new NotificationApi();
      const notificationResponse = await api.apiNotificationAllNotificationsGet();
      dispatch(slice.actions.fetchNotificationsSuccess(notificationResponse.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateNotifications(notifications: NotificationLogic[]) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.updateNotificationSuccess(notifications));
      const api = new NotificationApi();
      await api.apiNotificationNotificationsPut({
        notifications,
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
