import { Email } from 'src/@types/email';
import { CardData } from 'src/@types/ideas';

export const getFullEmailFormattedFromGenerated = (generated: string) => {
  const result: Email = {};
  generated = generated.replace("Sub:", "sub:").replace("Pt:", "pt:").replace("Bd:", "bd:");
  
  if (
    generated &&
    (generated.includes('sub:') || generated.includes('Sub:') )&&
    (generated.includes('pt:') || generated.includes("Pt:")) &&
    (generated.includes('bd:') || generated.includes("Bd:"))
  ) {
    const subject = generated.substring(generated.indexOf('sub:') + 4, generated.indexOf('pt:'));
    const previewText = generated.substring(generated.indexOf('pt:') + 3, generated.indexOf('bd:'));
    const body = generated.substring(generated.indexOf('bd:') + 3);
    const formattedBody = `<p style="line-height: 2">${body.replace(/\n/g, '<br>')}</p>`;
    result.subject = subject;
    result.previewText = previewText;
    result.body = formattedBody;
  } else {
    result.subject = 'Subject';
    result.previewText = 'Preview Text';
    result.body = 'Body';
  }
  return result;
};

export const getFormattedEmailAdditionals = (
  craftedContent: any,
  actionType: string,
  generatedEmail: any,
) => {
  const result = generatedEmail ?? {};
  if (actionType === 'GenerateSubjectLine') {
    result.subject = craftedContent;
  } else if (actionType === 'GeneratePreviewText') {
    result.previewText = craftedContent;
  } else if (actionType === 'GenerateCTA') {
    result.cta = `<p style="line-height: 2">${craftedContent.replace(/\n/g, '<br>')}</p>`;
  }
  return result;
};

export const getIdeasFormattedFromGenerated = (generated: string) => {
  if (!generated){
    return [];
  }
  const ideas = generated.split('\n\n');
  const result: CardData[] = [];
  ideas.forEach((idea, index) => {
    if (idea.includes('Title:') && idea.includes('Desc:')) {
      const tmpIdea: CardData = {
        // take all the string from Title: to Desc:
        title: idea.substring(idea.indexOf('Title:') + 6, idea.indexOf('Desc:')),
        description: idea.substring(idea.indexOf('Desc:') + 5),
      };
      result.push(tmpIdea);
    }
  });
  return result;
};

export const containsVariables = (input: string): boolean => {
  // This regular expression looks for strings that start with '{{{' and end with '}}}'
  const variableRegex = /\{{3}[^{}]*\}{3}/g;
  return variableRegex.test(input);
}