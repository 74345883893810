import { Box, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  ModelType,
  llmModels,
  modelCompanyModelNameMap,
} from 'src/common/constants/llm-models.constants';
import { RHSelect } from 'src/components/hook-form';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  onModelProviderSelect: (mode: string) => void;
  selectedModelInput?: string | null | undefined;
  selectedModelProviderInput?: string | null | undefined;
}

export default function ModelProviderSelect({
  maxWidth,
  onModelProviderSelect,
  selectedModelInput,
  selectedModelProviderInput,
}: Props) {
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const modelProviders = ['OpenAI', 'Gemini', 'Claude'];

  useEffect(() => {
    for (const company in modelCompanyModelNameMap) {
      if (
        selectedModelInput &&
        modelCompanyModelNameMap[company as keyof typeof modelCompanyModelNameMap].includes(
          selectedModelInput ?? ''
        )
      ) {

        //   onModelProviderSelect(company);
        //   setSelectedModelProvider(company);
        break;
      }
    }
  }, [selectedModelInput, onModelProviderSelect]);

  const [selectedModelProvider, setSelectedModelProvider] = useState<string>('OpenAI');
  return (
    <>
      <RHSelect
        key="selectModelProvider"
        name="selectModelProvider"
        disabled={runsMode}
        zIndex={3400}
        size="small"
        sx={{ maxWidth: maxWidth as number }}
        label="Select Model Provider"
        helperText="Select the model provider you want to use."
        value={selectedModelProviderInput ?? selectedModelProvider}
        onChange={(event) => {
          setSelectedModelProvider(event.target.value);
          onModelProviderSelect(event.target.value);
        }}
        InputLabelProps={{ shrink: true }}
        // sx={{ maxWidth: { md: 180 } }}
      >
        {modelProviders.map((modelProvider) => (
          <MenuItem value={modelProvider}>
            <span>{modelProvider}</span>
          </MenuItem>
        ))}
      </RHSelect>
    </>
  );
}
