// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import VertsCard from './VertsCard';
import { CreditPackage } from 'src/@types/credit';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { _faqs } from 'src/_mock/arrays';
import Iconify from '../iconify';
import CustomVertsCard from './CustomVertsCard';
import RenderHTML from 'src/utils/renderHtml';
// _mock
//
// ----------------------------------------------------------------------

const credits = [
  {
    id: 1,
    heading: '1. What are Verts and why do I need them?',
    detail: `Verts are the virtual currency used to execute flow items. By purchasing Verts, you're essentially prepaying for the computational resources to run your AI flows.`,
  },
  {
    id: 2,
    heading: '2. How many Verts do I need to run my AI Flow?',
    detail: `The number of credits needed varies based on the number of actions and items in your flow.`,
  },
  {
    id: 3,
    heading: '3. How long are my purchased Verts valid for?',
    detail: `Your purchased Verts will remain valid for a period of one year from the date of purchase. Any unused Verts after this period will expire. We recommend monitoring your Verts regularly to ensure optimal utilization.`,
  },
  {
    id: 4,
    heading: '4. Can I get a refund for unused Verts?',
    detail: `Verts are non-refundable once purchased. However, they can be used across any AI Flows until they expire. We encourage users to estimate their requirements accurately to ensure they purchase an appropriate number of Verts.`,
  },
  {
    id: 5,
    heading: '5. What happens if my Verts run out during a run?',
    detail: `If you run out of Verts during a flow run, the process will be paused, and you'll be notified immediately. You can then purchase additional Verts to resume and complete the flow. It's always a good practice to keep a buffer of Verts to avoid any interruptions.`,
  },
  {
    id: 6,
    heading: '6. What is the Pay As You Go plan, and how does it work?',
    detail: `The Pay As You Go plan allows you to use our services without purchasing a predefined package of Verts. Instead, you are billed based on the exact number of Verts you consume during your AI Flow runs. This plan offers flexibility and ensures that you only pay for what you use. Charges are calculated at the end of each billing cycle.You can enable it by going to the <a href = '/user' style = 'color:#00AB55'>Billing</a> section of your account.`,
  },
];

const creditsPackages: CreditPackage[] = [
  {
    name: '1,500 Verts',
    price: 50,
  },
  {
    name: '5,000 Verts',
    price: 150,
  },
  {
    name: '35,000 Verts',
    price: 1000,
  },
];

export default function CreditsPage() {
  return (
    <Container
      sx={{
        minHeight: 1,
        p: 3,
      }}
    >
      <Stack
        spacing={3}
        display="flex"
        alignSelf="center"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Box
          gap={{ xs: 3, md: 3 }}
          display="grid"
          alignItems="start" // Align items to the start of the grid cell
          alignContent="start" // Pack grid items from the start
          gridTemplateColumns={{ md: 'repeat(4, 1fr)' }}
          sx={{ mb: 3, width: '100%' }}
        >
          {creditsPackages.map((creditPackage, index) => (
            <VertsCard key={creditPackage.name} creditPackage={creditPackage} index={index} />
          ))}
          <CustomVertsCard />
        </Box>
        <Box>
          {credits.map((accordion) => (
            <Accordion key={accordion.id}>
              <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                <Typography variant="h5">{accordion.heading}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <RenderHTML htmlString={accordion.detail ?? ''} />
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Stack>
    </Container>
  );
}
