import { createRef, useRef } from 'react';
import { SnackbarProvider as NotistackProvider, SnackbarKey } from 'notistack';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Collapse, IconButton, Portal } from '@mui/material';
//
import { useSettingsContext } from '../settings';
import Iconify, { IconifyProps } from '../iconify';
import Smartlook from 'smartlook-client';
import { LicenseInfo } from '@mui/x-license-pro';
//
import StyledNotistack from './styles';
import zIndex from '@mui/material/styles/zIndex';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function SnackbarProvider({ children }: Props) {
  const { themeDirection } = useSettingsContext();

  const isRTL = themeDirection === 'rtl';

  const notistackRef = useRef<any>(null);

  const containerRef = createRef();
 // Smartlook.init("c86e9beab40a477573dc7c2f257819be7d72f6a6");
  if (process.env.NODE_ENV === 'production') {
    // Smartlook.init(process.env.SMARTLOOK_KEY);
    Smartlook.init("c86e9beab40a477573dc7c2f257819be7d72f6a6");
  }

  LicenseInfo.setLicenseKey(
    'f049fef8bf8878fbb63fccbd8a1442b2Tz04ODM4OCxFPTE3NDQ3MjUxMDMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  );

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <StyledNotistack />
      <NotistackProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        //     preventDuplicate
        autoHideDuration={3000}
        //       domRoot={document.body}
        TransitionComponent={isRTL ? Collapse : undefined}
        style={{ zIndex: 9999 }} // This value should be higher than Drawer's zIndex
        variant="success" // Set default variant
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon icon="eva:info-fill" color="info" />,
          success: <SnackbarIcon icon="eva:checkmark-circle-2-fill" color="success" />,
          warning: <SnackbarIcon icon="eva:alert-triangle-fill" color="warning" />,
          error: <SnackbarIcon icon="eva:alert-circle-fill" color="error" />,
        }}
        // With close as default
        action={(key) => (
          <IconButton size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        )}
      >
        {children}
      </NotistackProvider>
    </>
  );
}

// ----------------------------------------------------------------------

type SnackbarIconProps = {
  icon: IconifyProps;
  color: 'info' | 'success' | 'warning' | 'error';
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} />
    </Box>
  );
}
