import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { PromptLogic, PromptRepositoryLogic } from 'src/api';
import { RHSelect } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import PromptEditor from 'src/components/prompt-editor/PromptEditor';
import { useSnackbar } from 'src/components/snackbar';
import { fetchPrompts } from 'src/redux/slices/prompts';
import { useDispatch, useSelector } from 'src/redux/store';

interface Props {
  onClose: () => void;
  open: boolean;
  onLoadPrompt: (selectedPrompt: string) => void;
}

export default function PreviewLoadPromptDialog({ onClose, open, onLoadPrompt }: Props) {
  const [userPrompt, setUserPrompt] = useState<string>('');
  const [selectedPrompt, setSelectedPrompt] = useState<PromptLogic>();
  const [selectedPromptRepository, setSelectedPromptRepository] = useState<PromptRepositoryLogic>();
  const [prompts, setPrompts] = useState<PromptLogic[]>([]);
  const allPrompts = useSelector((state) => state.prompts.prompts);
  const promptRepositories = useSelector((state) => state.prompts.promptRepositories);
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setSelectedPromptRepository(undefined);
    setSelectedPrompt(undefined);
    onClose();
  };

  const dispatch = useDispatch();


  useEffect(() => {
    if (!selectedPromptRepository && promptRepositories.length > 0) {
    setSelectedPromptRepository(promptRepositories[0]);
    dispatch(fetchPrompts(promptRepositories[0].id as number));
    }
  }, [selectedPromptRepository, promptRepositories, dispatch]);

  useEffect(() => {
    if (allPrompts.length > 0) {
      setPrompts(allPrompts.filter((p) => p.promptRepositoryId === selectedPromptRepository?.id));
    }
  }, [allPrompts, selectedPromptRepository]);

  console.log('allPrompts', allPrompts);


  return (
    <Dialog sx={{ zIndex: 4400 }} fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span>Load Prompt</span>
          <IconButton onClick={handleClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent >
      <Box sx={{ p: 0, width: '100%', overflow: 'hidden' }}>
        <Stack  spacing={3}
              justifyContent="center"
              display="flex"
              alignSelf="center"
              alignItems="center"
              width="100%" >
          <RHSelect
            name="selectPromptRepo"
            value={selectedPromptRepository?.id}
            label="Select Prompt Repository"
            zIndex={4500}
            onChange={(e) => {
              setSelectedPromptRepository(
                promptRepositories.find((p) => p.id === Number(e.target.value))
              );
              dispatch(fetchPrompts(Number(e.target.value) ));
            }}
            size="small"
            InputLabelProps={{ shrink: true }}
          >
            {promptRepositories.map((promptRepo, index) => (
              <MenuItem key={index} value={promptRepo.id as number}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <span>{promptRepo?.name}</span>
                </Stack>
              </MenuItem>
            ))}
          </RHSelect>
          <RHSelect
          sx = {{marginTop: 1}}
            name="selectPrompt"
            label="Select Prompt"
            zIndex={4500}
            onChange={(e) => {
              setSelectedPrompt(allPrompts.find((p) => p.id === Number(e.target.value)));
            }}
            size="small"
            InputLabelProps={{ shrink: true }}
          >
            {prompts.map((prompt, index) => (
              <MenuItem key={index} value={prompt.id as number}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <span>{prompt?.name}</span>
                </Stack>
              </MenuItem>
            ))}
          </RHSelect>
          {selectedPrompt && (
            <Paper
              key=""
              variant="outlined"
              sx={{
                py: 1.5,
                mx: 1,
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                flexGrow: 1,
                height: '100%',
                width: '100%',
              }}
            >
              <Stack direction="row" justifyContent="center" sx={{ px: 1 }}>
                <div> </div>
                <Typography variant="subtitle2" sx={{ textAlign: 'center', paddingBottom: 1 }}>
                  User Prompt
                </Typography>
              </Stack>
              <Divider sx={{ mb: 1 }} />

              <PromptEditor
                promptInput={selectedPrompt?.userPrompt as string}
                readonly
                setPromptInput={(prompt: string) => {
                  // setUserPrompt(prompt);
                }}
              />
            </Paper>
          )}
        </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={!selectedPrompt}
          onClick={() => {
            if (selectedPrompt) {
              onLoadPrompt(selectedPrompt?.userPrompt as string);
            }
            handleClose();
          }}
        >
          Load Prompt
        </Button>
      </DialogActions>
    </Dialog>
  );
}
