import React, { useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Draggable from './Draggable';
import SortableItem from './SortableItem';

export default function DndTest() {
  const [testItems, setItems] = useState(['Item 1', 'Item 2', 'Item 3']);
  const [activeId, setActiveId] = useState<any>(null);
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && testItems.includes(over.id)) {
      const overIndex = testItems.indexOf(over.id);
      const activeIndex = testItems.indexOf(active.id);

      if (activeIndex !== -1) {
        setItems((items) => arrayMove(items, activeIndex, overIndex));
      } else {
        const newItems = [...testItems];
        newItems.splice(overIndex, 0, active.id);
        setItems(newItems);
      }
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={({ active }) => setActiveId(active.id)}
      onDragEnd={handleDragEnd}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '50px' }}>
          {/* Non-sortable list */}
          <Draggable id="action1">Action 1</Draggable>
          <Draggable id="action2">Action 2</Draggable>
        </div>

        {/* Sortable list */}
        <SortableContext items={testItems} strategy={rectSortingStrategy}>
          {testItems.map((item, index) => (
            <SortableItem key={index} id={item} />
          ))}
        </SortableContext>
      </div>
    </DndContext>
  );
}
