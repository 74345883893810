// @mui
import { Stack, Button, Typography, Box } from '@mui/material';
// auth
// locales
import { useLocales } from '../../../locales';
// routes
import { PATH_DOCS } from '../../../routes/paths';
import { useBoolean } from 'src/hooks/use-boolean';
import ContactDialog from 'src/sections/contact/ContactDialog';

// ----------------------------------------------------------------------

export default function NavDocs() {
  const openContactUs = useBoolean(false);

  const { translate } = useLocales();

  return (
    <Stack
      spacing={1}
      sx={{
        px: 5,
        pb: 5,
        mt: 1,
        width: 1,
        display: 'block',
        textAlign: 'center',
      }}
    >
      {/* <div>

        <Typography variant="subtitle1" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
         Need help?
        </Typography>
      </div> */}

      <Button onClick={openContactUs.onTrue} variant="outlined">
        Contact Us
      </Button>

      {/* <Button variant="outlined">
          Request Customizations
        </Button> */}

      <ContactDialog open={openContactUs.value} onClose={openContactUs.onFalse} />
    </Stack>
  );
}
