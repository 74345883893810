import { m } from 'framer-motion';
// @mui
import { Button, Typography, TextField, Stack, Divider } from '@mui/material';
// components
import { MotionViewport, varFade } from '../../components/animate';
import Image from '../../components/image';
import { useState } from 'react';
import { AccountApi } from 'src/api';
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------

export default function ContactForm() {
  const [submitted, setSubmitted] = useState(false);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  return (
    <Stack component={MotionViewport} spacing={5}>
      {/* <m.div variants={varFade().inUp}>
        <Typography variant="h3">
          Feel free to contact us. <br />
          We&apos;ll be glad to hear from you, buddy.
        </Typography>
      </m.div> */}

      <Stack spacing={3}>
        {/* <m.div variants={varFade().inUp}>
          <TextField fullWidth label="Name" />
        </m.div>

        <m.div variants={varFade().inUp}>
          <TextField fullWidth label="Email" />
        </m.div> */}

        <m.div variants={varFade().inUp}>
          <TextField
            value={subject}
            onChange={(event) => {
              setSubject(event.target.value);
            }}
            fullWidth
            label="Subject"
          />
        </m.div>

        <m.div variants={varFade().inUp}>
          <TextField
            value={content}
            onChange={(event) => {
              setContent(event.target.value);
            }}
            fullWidth
            label="Enter your message here."
            multiline
            rows={4}
          />
        </m.div>
      </Stack>

      <m.div variants={varFade().inUp}>
        <LoadingButton
          onClick={async () => {
            const accountApi = new AccountApi();
            await accountApi.apiAccountContactUsPost({
              subject,
              content,
            });
            setSubmitted(true);
            setSubject('');
            setContent('');
          }}
          size="large"
          variant="outlined"
        >
          Submit
        </LoadingButton>
      </m.div>
      {submitted && (
          <Typography variant="h6">Thank you for contacting us! We will
            get back to you as soon as possible.
          </Typography>
      )}
      <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        OR
      </Divider>
      <Stack
        alignContent="center"
        alignSelf="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <m.div variants={varFade().inUp}>
          <Typography variant="h3">Join our Discord Channel</Typography>
        </m.div>
        <Image
          onClick={() => window.open('https://discord.gg/rfYEwYkW', '_blank')}
          sx={{ width: 150, borderRadius: 1, cursor: 'pointer' }}
          src="/assets/icons/buttons/discord.jpg"
        />
      </Stack>
      {/* <image
          href="/assets/icons/buttons/discord.png"
          height="300"
          x="300"
          y="30"
        /> */}
    </Stack>
  );
}
