// signalRConnection.ts
import { HubConnectionBuilder, IHttpConnectionOptions, HubConnection, HttpTransportType } from "@microsoft/signalr";

let connection: HubConnection | null = null;

export const getSignalRConnection = async (hubUrl: string, options?: IHttpConnectionOptions) => {
  if (connection) {
    return connection;
  }

  connection = new HubConnectionBuilder()
  .withUrl(hubUrl,{
  //  skipNegotiation: true,
    accessTokenFactory: () => localStorage.getItem('accessToken') || '',
    transport: HttpTransportType.WebSockets
  })
  .withAutomaticReconnect()
  .build();

  try {
    await connection.start();
  } catch (err) {
    console.log("Error while starting SignalR connection:", err);
  }

  return connection;
};