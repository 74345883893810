import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { IUserState } from 'src/@types/user';
import { AccountApi, AccountOnboardingApi, AccountOnBoardingLogic, UserProfileLogic } from 'src/api';

const initialState: IUserState = {
  error: null,
  onboarding: null,
  loading: false,
  loaded: false,
  user: {},
  profile: null,
}; // test
const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserSuccess(state, action) {
      state.user = action.payload;
    },
    setLoading(state, action){
      state.loading = action.payload
    },
    fetchOnboardingSuccess(state, action) {
      state.onboarding = action.payload;
      state.loaded = true;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    setProfileSuccess(state, action) {
      state.profile = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setUserSuccess } = slice.actions;

export function fetchUserProfile() {
  return async (dispatch: Dispatch) => {
    try {
      const accountApi = new AccountApi();
      const response = await accountApi.apiAccountProfileGet();
      dispatch(slice.actions.setProfileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserProfile(profile: UserProfileLogic,enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const accountApi = new AccountApi();
      const response = await accountApi.apiAccountProfilePost(profile);
      dispatch(slice.actions.setProfileSuccess(response.data));
      enqueueSnackbar('Profile updated successfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchOnboarding() {
  return async (dispatch: Dispatch) => {
    try {
      const onboardingApi = new AccountOnboardingApi();
       const response = await onboardingApi.apiAccountOnboardingOnboardingGet();
       dispatch(slice.actions.fetchOnboardingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addOnboarding(onBoarding: AccountOnBoardingLogic) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setLoading(true));
      const onboardingApi = new AccountOnboardingApi();
       const response = await onboardingApi.apiAccountOnboardingOnboardingPost(onBoarding);
       dispatch(slice.actions.fetchOnboardingSuccess(response.data));
       dispatch(slice.actions.setLoading(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setLoading(false));
    }
  };
}