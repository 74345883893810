import { MenuItem, Avatar, ListSubheader } from '@mui/material';
import { Stack } from '@mui/system';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { EmailProviderApi } from 'src/api';
import { RHSelect } from 'src/components/hook-form';
import { RHAutocomplete } from 'src/components/hook-form/RHFAutocomplete';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';
import { updateSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';

export interface Props {
  handleSelectMethod: (provider: string) => void;
  selectedListInput?: string;
  maxWidth?: number;
  disabled?: boolean;
  connectionId: number;
}

export default function MailchimpListSelect({
  handleSelectMethod,
  maxWidth,
  connectionId,
  disabled,
  selectedListInput,
}: Props) {
  const [selectedListId, setSelectedListId] = useState<any>({ value: '', label: '' });
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [loadinglists, setLoadinglists] = useState<boolean>(false);
  const [listOptions, setCampaignOptions] = useState<any[]>([]);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  useEffect(() => {
    if (selectedItem) {
      const getlists = async () => {
        setLoadinglists(true);
        const emailProviderApi = new EmailProviderApi();
        const lists = await emailProviderApi.apiEmailProviderListsGet('Mailchimp', connectionId);
        const listOptionsTmp = lists.data.map((list: any) => ({
          value: list.id,
          label: list?.name
            ? `List Name: ${list.name} - List Id: ${list.id}`
            : `List Id: ${list.id}`,
        }));
        setCampaignOptions(listOptionsTmp);
        const list = listOptionsTmp.find(
          (ls) => ls.value === selectedListInput
        );
        if (selectedListInput) {
          setSelectedListId(list);
        }
        setLoadinglists(false);
      };
      getlists();
    }
  }, [selectedItem, selectedListInput,connectionId]);

  return (
    <>
      <RHAutocomplete
        zIndex={5000}
        disabled={disabled || loadinglists || runsMode}
        loading={loadinglists && !!connectionId}
        name="lists"
        size="small"
        label="Select List"
        groupBy={(option) => option.category}
        options={listOptions}
        sx={{ minWidth: { md: 200 } }}
        value={selectedListId}
        onChange={async (event: any, newValue: any) => {
          setSelectedListId(newValue);
          handleSelectMethod(newValue.value);
        }}
        getOptionLabel={(option: any) => option.label}
      />
    </>
  );
}
