import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Stack,
  Box,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'src/components/snackbar';
import { useDispatch } from 'src/redux/store';
import RenderHTML from 'src/utils/renderHtml';

export interface Props {
  onClose: () => void;
  open: boolean;
  snippetHtml: string;
}

export default function SaveSnippetDialog({ onClose, open, snippetHtml }: Props) {
  const dispatch = useDispatch();
  const [newSnippetName, setNewSnippetName] = useState<string>();
  const [savingSnippet, setSavingSnippet] = useState<boolean>(false);
  const decodeHTMLEntities = (text: string) => {
    const textArea = document.createElement('textarea');
    console.log('TextArea', textArea);
    textArea.innerHTML = text;
    return textArea.value
      .replace(/&nbsp;/g, ' ')
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<[^>]+>/g, '');
  };
  const { enqueueSnackbar } = useSnackbar();
  const [snippetContent, setSnippetContent] = useState<string>(decodeHTMLEntities(snippetHtml));
  const handleSaveSnippet = async () => {
   // dispatch(saveNewContentSnippet(newSnippetName as string, snippetHtml));
    enqueueSnackbar('Save snippet success!');
    onClose();
  };

  useEffect(() => {
    setSnippetContent(decodeHTMLEntities(snippetHtml));
  }, [snippetHtml]);

  return (
    <Dialog open={open} onClose={onClose} sx = {{zIndex: 4500}}>
      <DialogTitle>Save Content Snippet</DialogTitle>
      <DialogContent sx = {{zIndex: 4500}}>
        <Stack direction="column" alignItems="center" spacing={3}>
          <TextField
            autoFocus
            margin="dense"
            sx={{ maxWidth: 250 }}
            id="name"
            onChange={(e) => setNewSnippetName(e.target.value)}
            label="Snippet Name"
            type="email"
            fullWidth
            variant="standard"
          />
          {/* <TextField
            sx={{ minWidth: 400 }}
            id="snippetContent"
            name="snippetContent"
            label="Content"
            value={snippetContent}
            onChange={(e) => setSnippetContent(e.target.value)}
            multiline
            minRows="10"
            maxRows="16"
          /> */}
          <Typography
            variant="subtitle1"
            sx={{ color: 'text.secondary', fontWeight: 'fontWeightBold' }}
            noWrap
          >
            Content
          </Typography>
          <Box sx={{ border: '1px dashed', p: 2 }}>
            <RenderHTML htmlString={snippetHtml} />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={savingSnippet} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={savingSnippet || !newSnippetName} onClick={handleSaveSnippet}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
