import { Box, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { FilesRepoLogic } from 'src/api';
import { RHSelect } from 'src/components/hook-form';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  onFilesRepoSelect: (kbl: FilesRepoLogic) => void;
  selectedFilesRepoInput?: string | null | undefined;
}

export default function FilesRepoSelect({
  maxWidth,
  onFilesRepoSelect,
  selectedFilesRepoInput,
}: Props) {
  const filesRepos = useSelector((state) => state.filesRepo.filesRepos);

  const [selectedFilesRepo, setSelectedFilesRepo] = useState<string>(
    filesRepos[0].name ?? 'My Files Repo'
  );
  return (
    <>
      <Box sx={{ maxWidth, px: 1 }}>
        <RHSelect
          key="selectFilesRepo"
          name="selectFilesRepo"
          zIndex={3400}
          size="small"
          sx={{ maxWidth: maxWidth as number }}
          label="Select Files Repo"
          value={selectedFilesRepoInput ?? selectedFilesRepo}
          onChange={(event) => {
            setSelectedFilesRepo(event.target.value);
            const tmpKb = filesRepos.find((kb) => kb.name === event.target.value);
            onFilesRepoSelect(tmpKb as FilesRepoLogic);
          }}
          InputLabelProps={{ shrink: true }}
          // sx={{ maxWidth: { md: 180 } }}
        >
          {filesRepos.map((filesRepo) => (
            <MenuItem value={filesRepo.name as string}>
              <span>{filesRepo.name}</span>
            </MenuItem>
          ))}
        </RHSelect>
      </Box>
    </>
  );
}
