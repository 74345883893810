import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider, { RHSelect } from 'src/components/hook-form';
import PreviousActionsSelect from 'src/components/previous-actions/PreviousActionsSelect';
import { setActionValidated, updateSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';

export default function AiFlowLocatorActionSetup() {
  const [selectedSource, setSelectedSource] = useState<string>(''); // TODO: [AI-1234
  const [selectedFileType, setSelectedFileType] = useState<string>('Csv'); // TODO: [AI-1234
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [fileTypes, setFileTypes] = useState([{ label: 'Html', value: 'Html' }]);
  const [locateByOptions, setLocateByOptions] = useState([
    { label: 'Element Id', value: 'elementId' },
  ]);
  const [locatorTypes, setLocatorTypes] = useState([
    { label: 'Link', value: 'Link' },
    { label: 'Image', value: 'Image' },
  ]);
  const [selectedLocatorType, setSelectedLocatorType] = useState<string>('Link');
  const [selectedLocateBy, setSelectedLocateBy] = useState<string>('elementId');
  const [elementsSource, setElementsSource] = useState([
    { label: 'Source', value: 'Source' },
    { label: 'Input', value: 'Input' },
  ]);
  const [selectedElementsSourceType, setSelectedElementsSourceType] = useState<string>('Source'); // TODO: [AI-1234
  const [selectedElementsSource, setSelectedElementsSource] = useState<string>('');
  const defaultValues = {
    fileSource: '',
    elementsSource: '',
  };

  const AiFlowLocatorSchema: any = Yup.object().shape({
    fileSource: Yup.string().required('Required'),
    elementsSource: Yup.string().required('Required'),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowLocatorSchema),
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.inputData?.actionInputData?.functionalInputData?.locatorInput) {
        setSelectedSource(
          selectedItem.inputData?.actionInputData?.functionalInputData?.locatorInput
            ?.fileSource as string
        );
        methods.setValue(
          'fileSource',
          selectedItem.inputData?.actionInputData?.functionalInputData?.locatorInput
            ?.fileSource as string
        );

        setSelectedFileType(
          selectedItem.inputData?.actionInputData?.functionalInputData?.locatorInput
            ?.fileType as string
        );
        setSelectedLocatorType(
          selectedItem.inputData?.actionInputData?.functionalInputData?.locatorInput
            ?.locatorType as string
        );
        setSelectedLocateBy(
          selectedItem.inputData?.actionInputData?.functionalInputData?.locatorInput
            ?.locateBy as string
        );
        setSelectedElementsSource(
          selectedItem.inputData?.actionInputData?.functionalInputData?.locatorInput
            ?.selectedElementsSource as string
        );
        setSelectedElementsSourceType(
          selectedItem.inputData?.actionInputData?.functionalInputData?.locatorInput
            ?.selectedElementsSourceType as string
        );
        methods.setValue(
          'elementsSource',
          selectedItem.inputData?.actionInputData?.functionalInputData?.locatorInput
            ?.selectedElementsSource as string
        );
        methods.trigger();
      }
    }
  }, [selectedItem, methods]);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);
  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3} sx={{ mt: 2, mx: 1 }}>
          <PreviousActionsSelect
            name="fileSource"
            selectedSourceInput={selectedSource}
            onPreviousActionsSelect={(source: string) => {
              setSelectedSource(source);
              setValue('fileSource', source);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      locatorInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.locatorInput,
                        fileSource: source,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
          />

          <RHSelect
            key="selectFileType"
            name="selectFileType"
            size="small"
            label="Select File Type"
            zIndex={3400}
            value={selectedFileType}
            sx={{ px: 1 }}
            onChange={(event) => {
              setSelectedFileType(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      locatorInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.locatorInput,
                        fileType: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
            InputLabelProps={{ shrink: true }}
          >
            {fileTypes.map((fileType) => (
              <MenuItem value={fileType.value}>
                <span>{fileType.label}</span>
              </MenuItem>
            ))}
          </RHSelect>
          <RHSelect
            key="locatorType"
            name="locatorType"
            size="small"
            label="Locator Type"
            zIndex={3400}
            value={selectedLocatorType}
            sx={{ px: 1 }}
            onChange={(event) => {
              setSelectedLocatorType(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      locatorInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.locatorInput,
                        locatorType: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
            InputLabelProps={{ shrink: true }}
          >
            {locatorTypes.map((lt) => (
              <MenuItem value={lt.value}>
                <span>{lt.label}</span>
              </MenuItem>
            ))}
          </RHSelect>
          <RHSelect
            key="locateBy"
            name="locateBy"
            size="small"
            label="Locate By"
            zIndex={3400}
            value={selectedLocateBy}
            sx={{ px: 1 }}
            onChange={(event) => {
              setSelectedLocateBy(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      locatorInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.locatorInput,
                        locateBy: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
            InputLabelProps={{ shrink: true }}
          >
            {locateByOptions.map((lt) => (
              <MenuItem value={lt.value}>
                <span>{lt.label}</span>
              </MenuItem>
            ))}
          </RHSelect>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Elements
          </Typography>
          <RHRadioGroup
            value={selectedElementsSourceType}
            onChange={(event) => {
              setSelectedElementsSourceType(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      locatorInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.locatorInput,
                        selectedElementsSourceType: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
            row
            spacing={4}
            name="espImportTypes"
            options={elementsSource}
          />
          {selectedElementsSourceType === 'Source' && (
            <PreviousActionsSelect
              name="elementsSource"
              selectedSourceInput={selectedElementsSource}
              onPreviousActionsSelect={(source: string) => {
                setSelectedElementsSource(source);
                setValue('elementsSource', source);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      functionalInputData: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                        locatorInput: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData
                            ?.locatorInput,
                          selectedElementsSource: source,
                        },
                      },
                    },
                  },
                };
                dispatch(updateSelectedAiFlowItem(updatedItem));
              }}
            />
          )}
        </Stack>
      </FormProvider>
    </>
  );
}
