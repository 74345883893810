import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
// @types
import { IIntegrationState } from 'src/@types/integration';
import { IntegrationsApi } from 'src/api';
import { saveConnectionsSuccess } from './connections';

// ----------------------------------------------------------------------

const initialState: IIntegrationState = {
  error: null,
  loading: false,
  callbackProcessed: {},
  integrationGroups: [],
  integrations: [],
};

const slice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    updateCallbackProcessed(state, action) {
      state.callbackProcessed[action.payload] = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    // // GET LABELS
    // authenticationSuccess(state, action) {
    //   state.isAuthenticating = false;
    //   const integrations = JSON.parse(JSON.stringify(state.syncedIntegrations));
    //   integrations.push(action.payload);
    //   state.syncedIntegrations = integrations;
    // },
    fetchIntegrationGroupsSuccess(state, action) {
      state.integrationGroups = action.payload;
      state.integrations = action.payload.flatMap((group: any) => group.integrations);
      state.loading = false;
    },
  },
});

// Actions
export const { setLoading, startLoading, hasError, fetchIntegrationGroupsSuccess, updateCallbackProcessed } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function authenticate(code: string, provider: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const integrationsApi = new IntegrationsApi();
      const response = await integrationsApi.apiIntegrationsPost(code, provider);
      dispatch(saveConnectionsSuccess(response.data));
      //  const response = await axios.post(`/api/Integrations/?code=${code}&provider=${provider}`);
      //   dispatch(slice.actions.authenticationSuccess(provider));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchIntegrationGroups() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const integrationsApi = new IntegrationsApi();
      const response = await integrationsApi.apiIntegrationsIntegrationGroupsGet();
      dispatch(slice.actions.fetchIntegrationGroupsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function testApiKey(apiKey: string, provider: string, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const integrationApi = new IntegrationsApi();
      const response = await integrationApi.apiIntegrationsTestApiKeyGet(apiKey, provider);
      if (response.data) {
        enqueueSnackbar('Api Key is valid', { variant: 'success' });
      } else {
        enqueueSnackbar('Api Key is invalid', { variant: 'error' });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}
