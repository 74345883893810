import { Box } from "@mui/system";
import { forwardRef } from "react";

interface Params{
    sx?: any;
    fill?: string;
    width: number;
    height: number;
}
export const LogoBox = forwardRef((props: Params, ref: React.Ref<HTMLDivElement>) => {
    const { sx, fill, width, height, ...other } = props;
    return (
        <Box
        ref={ref}
        component="div"
        sx={{
          width: height,
          height: width,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg
          id="Layer_2"
          data-name="Layer 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 230.32 230.62"
        >
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <polygon
                className="cls-1"
                style={{ fill: fill ?? '#45ce4e' }}
                points="226.75 0 189.58 39.62 120.39 113.4 87.77 148.2 65.23 172.22 32.61 206.99 22.29 196 10.07 182.96 0 172.22 32.61 137.44 55.16 113.4 87.77 78.64 161.52 0 226.75 0"
              />
              <polygon
                className="cls-1"
                style={{ fill: fill ?? '#45ce4e' }}
                points="120.08 0 67.06 56.54 34.43 91.33 25.38 81.65 1.81 56.54 34.43 21.77 54.85 0 120.08 0"
              />
              <polygon
                className="cls-1"
                style={{ fill: fill ?? '#45ce4e' }}
                points="230.32 230.62 165.08 230.62 110.07 171.97 127.82 153.02 142.69 137.18 230.32 230.62"
              />
              <polygon
                className="cls-1"
                style={{ fill: fill ?? '#45ce4e' }}
                points="120.02 230.62 55.04 230.62 72.67 211.84 87.54 195.98 88.24 196.74 120.02 230.62"
              />
            </g>
          </g>
        </svg>
      </Box>
    )
})