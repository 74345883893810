import { Box, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { FineTuneModelType, ModelType, fineTuneLlmModels, llmModels } from 'src/common/constants/llm-models.constants';
import { RHSelect } from 'src/components/hook-form';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  onModeSelect: (mode: string) => void;
  selectedModelInput?: string | null | undefined;
}

export default function FinetuneModelSelect({ maxWidth, onModeSelect, selectedModelInput }: Props) {
  const [modelCategory, setModelCategory] = useState<string>('Generate');
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);

  useEffect(() => {
    
  }, [selectedItem]);

  const models = Object.entries(fineTuneLlmModels).flatMap(([company, modelTypes]) =>
    modelTypes.map((modelType) => {
      const label = `${company} ${modelType}`;
      const modelObject: { label: string; value: FineTuneModelType; disabled?: boolean } = {
        label,
        value: modelType,
      };

      return modelObject;
    })
  );

  const [selectedModel, setSelectedModel] = useState<string>(ModelType.gpt_4_o_mini);
  return (
    <>
      <Box sx={{ maxWidth, px: 1 }}>
        <RHSelect
          key="selectFineTuneModel"
          name="selectFineTuneModel"
          zIndex={3400}
          size="small"
          sx={{ maxWidth: maxWidth as number }}
          label="Select Finetune Model"
          helperText="Select the model you want to use."
          value={selectedModelInput ?? selectedModel}
          onChange={(event) => {
            setSelectedModel(event.target.value);
            onModeSelect(event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          // sx={{ maxWidth: { md: 180 } }}
        >
          {models.map((model) => (
            <MenuItem disabled={model.disabled} value={model.value}>
              <span>{model.label}</span>
            </MenuItem>
          ))}
        </RHSelect>
      </Box>
    </>
  );
}
