import React from 'react';
import Box from '@mui/material/Box';

interface Props{
    htmlString: string;
}

export default function RenderHTML ({ htmlString }: Props) {
  return (
    <Box component="div" dangerouslySetInnerHTML={{ __html: htmlString as string }} />
  );
};