// utils

import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IProvidersState } from 'src/@types/providers';
import { EmailProviderApi } from 'src/api';
import { EmailProviderList } from 'src/pages/marketing/campaign/models/email-provider-list';

// ----------------------------------------------------------------------

const initialState: IProvidersState = {
  error: null,
  audienceData: {},
  audienceLists: [],
  audienceLoaded: false,
  accountInfo: {},
};

const slice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    getAccountInfoSuccess(state, action) {
      state.accountInfo = action.payload;
    },
    getAudienceListsSuccess(state, action) {
      state.audienceLists = action.payload;
      state.audienceLoaded = true;
    },
    getAudienceDataSuccess(state, action) {
      state.audienceData[action.payload.providerName] = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getAudienceListsSuccess, getAudienceDataSuccess, hasError } = slice.actions;

// ----------------------------------------------------------------------

export function getProviderAccountInfo(providerName: string) {
  return async (dispatch: Dispatch) => {
    try {
      const api = new EmailProviderApi();
      // const accountInfo = await api.apiEmailProviderAccountInfoGet(providerName);
      // dispatch(slice.actions.getAccountInfoSuccess(accountInfo.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProviderAudienceLists(providerName: string) {
  return async (dispatch: Dispatch) => {
    try {
      const api = new EmailProviderApi();
      // const lists = (await api.apiEmailProviderListsGet(providerName)).data as EmailProviderList[];
      // dispatch(slice.actions.getAudienceListsSuccess(lists));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchProviderAudienceData(providerName: string, listId: string) {
  return async (dispatch: Dispatch) => {
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderAudienceDataGet(providerName, listId);
      // dispatch(slice.actions.getAudienceDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
