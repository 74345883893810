// utils

import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { ISignalRState } from 'src/@types/signalR';
import { getSignalRConnection } from 'src/utils/signalR-new-connection';
import { GeneratedContentData } from 'src/@types/content';
import { fetchAiFlowsRunOutputs, fetchAiFlowsRuns, updateRun, updateRunOutput } from './aiFlow-runs';
import { useSnackbar } from 'src/components/snackbar';
import { updateCreditStatus } from './credits-status';
import { addNotificationSuccess } from './notifications';
import { fetchBasicBillingInfo } from './subscription';

// ----------------------------------------------------------------------

const initialState: ISignalRState = {
  error: null,
  connected: false,
};

const slice = createSlice({
  name: 'signalR',
  initialState,
  reducers: {
    setupSignalRDone(state, action) {
      state.connected = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setupSignalRDone } = slice.actions;

export function setupSignalR(enqueueSnackbar: any){
  return async (dispatch: any) => {
    const connection = await getSignalRConnection(
      `${process.env.REACT_APP_PROXY_WEBAPI_URL}/gptHub`
    );
    dispatch(slice.actions.setupSignalRDone(true));
    // connection.on("contentGeneration", (generatedData: GeneratedContentData) => {
    // //    dispatch(setSetupGeneratedContent(generatedData))
    // });

    connection.on('taskUpdated', (data: any) => {
      
      dispatch(updateRun(data));
    });

    connection.on('reloadRuns', (data: any) => {
      dispatch(fetchAiFlowsRuns());
    });

    connection.on('runUpdated', (data: any) => {
      
      if (data.run){
        dispatch(updateRun(data.run));
      }
      if (data.runOutput){
        dispatch(updateRunOutput(data.runOutput));
      }
      if (data.creditStatus){
        dispatch(updateCreditStatus(data.creditStatus));
      }
      if (data.notification){
        dispatch(addNotificationSuccess(data.notification));
      }
   //   enqueueSnackbar('Run sequence updated', { variant: 'success' });
   //   dispatch(fetchAiFlowsRunsOutputs(data.id));
    });

    connection.on('subscriptionCreated', (data: any) => {
      dispatch(fetchBasicBillingInfo());
    });

    // Add more listeners for different events as required
  };
}
