import { Tooltip, Card, Typography, Stack, Box, Button } from '@mui/material';
import { ActionInfo } from 'src/api';
import { ACTION_ICONS } from 'src/common/constants/icons.constants';
import { BiggerStyledIcon, SmallActionIcon } from 'src/components/nav-section/mini/styles';
import { useDispatch, useSelector } from 'src/redux/store';
import { useDraggable } from '@dnd-kit/core';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { addAiFlowItemBeforeSequence, setSidebarPopoverOpen } from 'src/redux/slices/aiflows';

interface Params {
  actionInfo?: ActionInfo;
  sequence: string;
  type: string;
}
export default function AiFlowSidebarItem({ actionInfo, sequence, type }: Params) {
  // const { isDragging, attributes, listeners, setNodeRef, transform } = useDraggable({
  //   id: sequence,
  //   data: {
  //     actionInfo,
  //     type,
  //   },
  // });
  const isHovered = useBoolean(false);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const dispatch = useDispatch();

  return (
    <>
      <Tooltip title={actionInfo?.description} placement="top">
        <Card
          elevation={3}
          onClick={() => {
            dispatch(addAiFlowItemBeforeSequence({
                header: actionInfo?.header as string,
                sequence: Number(sequence),
                aiFlowId: selectedAiFlow?.id as number,
            }))
            dispatch(setSidebarPopoverOpen(null));
          }}
          sx={{
            p: 2,
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            display: 'flex',
            borderRadius: 1,
            borderColor: 'primary.main',
            cursor: 'pointer',
            ...(!actionInfo?.disabled && {
              '&:hover': {
                border: '1px solid',
                borderColor: 'primary.main',
              },
            }),

            ...(actionInfo?.disabled && {
              '::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(245, 245, 245, 0.6)',
                zIndex: 2,
                cursor: 'not-allowed',
              },
              p: 3,
              cursor: 'pointer',
              width: 1,
              minHeight: 110,
              position: 'relative',
            }),
            borderWidth: 2,
            border: '1px solid',
            //         ...style,
          }}
          onMouseEnter={isHovered.onTrue} // set hover state to true when mouse enters
          onMouseLeave={isHovered.onFalse} // set hover state to false when mouse leaves
         // {...(!actionInfo?.disabled ? { ...listeners, ...attributes } : {})}
        >
          {/* {isHovered.value && actionInfo?.custom && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0,0,0,0.01)', // semi-transparent black background
                zIndex: 1, // ensure overlay is on top of other content
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => {
                    // dispatch(runAiFlow(aiFlow?.id as number, enqueueSnackbar));
                    // popover.onClose();
                  }}
                  sx = {{width:20}}
                >
                  <Stack direction="row" alignItems="center">
                    <Iconify icon="eva:more-vertical-fill" />
                  </Stack>
                </Button>
              </Stack>
            </Box>
          )} */}
          <Stack alignItems="center">
            {type === 'Action' && (
              <>
                <BiggerStyledIcon>{ACTION_ICONS[actionInfo?.icon as string]}</BiggerStyledIcon>

                <Typography sx={{ ml: 1 }} variant="subtitle2">
                  {actionInfo?.actionLabel}
                </Typography>
              </>
            )}
            {type === 'Validator' && (
              <>
                <BiggerStyledIcon>{ACTION_ICONS.validation}</BiggerStyledIcon>

                <Typography sx={{ ml: 1 }} variant="subtitle2">
                  Validator
                </Typography>
              </>
            )}
            {type === 'Delay' && (
              <>
                <Iconify width={30} height={30} icon="mdi:clock-outline" />

                <Typography sx={{ ml: 1 }} variant="subtitle2">
                  Delay
                </Typography>
              </>
            )}
          </Stack>
        </Card>
      </Tooltip>
    </>
  );
}
