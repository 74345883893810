import {
  Button,
  Card,
  Drawer,
  IconButton,
  List,
  MenuItem,
  Skeleton,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { Box, Container, Stack, useTheme } from '@mui/system';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { IContact } from 'src/@types/contact';
import { VoteState } from 'src/@types/content';
import ConfirmDialog from 'src/components/confirm-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover';
import { useSettingsContext } from 'src/components/settings';
import { useSnackbar } from 'src/components/snackbar';
import { fetchGenerated } from 'src/redux/slices/craft';
import { useDispatch, useSelector } from 'src/redux/store';
import { NAV } from 'src/config-global';
import Scrollbar from 'src/components/scrollbar';
import { SkeletonMailNavItem } from 'src/components/skeleton';
import DrawerNavItem from 'src/widgets/smart-editor/settings/DrawerNavItem';
import useResponsive from 'src/hooks/useResponsive';
import { ContentType } from 'src/common/enums/content-type.enum';

interface Props {
  dialogMode?: boolean;
  onEdit?: (id: number) => void;
}

export default function OutputsPageOld({ dialogMode, onEdit }: Props) {
  const dispatch = useDispatch();
  const generatedContents = useSelector((state) => state.craft.crafted);
  const userGeneratedContentsChanged = useSelector(
    (state) => state.craft.crafted
  );
  const [filteredUserGeneratedContents, setFilteredUserGeneratedContents] = useState<
    any[]
  >([]);
  const loading = useSelector((state) => state.craft.loading);
  const [selectedProvider, setSelectedProvider] = useState<string>();
  const [selectedList, setSelectedList] = useState<string>();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedGeneratedContentsIds, setSelectedGeneratedContentsIds] = useState<number[]>([]);
  const [selectedGeneratedContentId, setSelectedGeneratedContentId] = useState<number>();
  const [selectedIndustry, setSelectedIndustry] = useState<string>('all');
  const [selectedEmailType, setSelectedEmailType] = useState<string>('all');
  const [selectedSection, setSelectedSection] = useState<string>('all');

  const applyFilters = useCallback(async () => {
    const filteredGeneratedContents = generatedContents;
    
    // if (selectedIndustry !== 'all') {
    //   filteredGeneratedContents = filteredGeneratedContents.filter(
    //     (c) => c.additionalData.emailTypeGroupOption === selectedIndustry
    //   );
    // }
    // if (selectedEmailType !== 'all') {
    //   filteredGeneratedContents = filteredGeneratedContents.filter(
    //     (c) => c.emailType === selectedEmailType
    //   );
    // }
    // if (selectedSection !== 'all') {
    //   filteredGeneratedContents = filteredGeneratedContents.filter(
    //     (c) => c.emailTypeSection === selectedSection
    //   );
    // }
    setFilteredUserGeneratedContents(filteredGeneratedContents[ContentType.Generated]);
  },[generatedContents])// [selectedIndustry, selectedEmailType, selectedSection, userGeneratedContents]);

  useEffect(() => {
    applyFilters();
  }, [generatedContents, applyFilters]);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };
  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  useEffect(() => {
    if (userGeneratedContentsChanged) {
      dispatch(fetchGenerated());
    }
  }, [dispatch, userGeneratedContentsChanged]);

  const reloadGeneratedContents = () => {
    dispatch(fetchGenerated());
  };

  // useEffect(() => {
  //   dispatch(fetchContacts('Mailchimp', '5890db36e7'));
  // }, [dispatch]);

  const renderCell = (params: any) => {
    if (params.value === null || params.value === undefined || params.value === '') {
      // If value is null or empty, return italicized placeholder
      return <Box sx={{ fontStyle: 'italic' }}>N/A</Box>;
    } else {
      // Otherwise, return the value normally
      return (
        <Tooltip title={params.value}>
          <Box>{params.value}</Box>
        </Tooltip>
      );
    }
  };

  const renderGeneratedContentCell = (params: any) => {
    if (params.value === null || params.value === undefined || params.value === '') {
      // If value is null or empty, return italicized placeholder
      return <Box sx={{ fontStyle: 'italic' }}>N/A</Box>;
    } else {
      // Otherwise, return the value normally
      const generatedContent = (params.value as string).replace(/<br><br>/g, '');
      console.log('generatedContent: ', generatedContent);
      return <Box>{generatedContent}</Box>;
    }
  };

  const renderVote = (params: any) => {
    if (params.value === null || params.value === undefined || params.value === 0) {
      // If value is null or empty, return italicized placeholder
      return <Box sx={{ fontStyle: 'italic' }}>N/A</Box>;
    } else {
      // Otherwise, return the value normally
      const vote = params.value === VoteState.ThumbsUp ? '👍' : '👎';
      return <Box>{vote}</Box>;
    }
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const isDesktop = useResponsive('up', 'md');
  const [selectedSettingGroup, setSelectedSettingGroup] = useState<string>('Email Content');
  const items: any = [
    {
      id: 'content',
      type: 'content',
      name: 'Email Content',
      color: 'primary',
    },
    {
      id: 'Subjects',
      type: 'subjects',
      name: 'Subjects',
      color: 'primary',
    },
    {
      id: 'previewText',
      type: 'previewText',
      name: 'Preview Text',
      color: 'primary',
    },
    {
      id: 'cta',
      type: 'cta',
      name: 'Call To Actions',
      color: 'primary',
    },
    {
      id: 'dripCampaigns',
      type: 'dripCampaigns',
      name: 'Drip Campaigns',
      color: 'primary',
    },
    {
      id: 'rephrased',
      type: 'rephrased',
      name: 'Rephrased',
      color: 'primary',
    },
    {
      id: 'summarized',
      type: 'summarized',
      name: 'Summarized',
      color: 'primary',
    },
    {
      id: 'expanded',
      type: 'expanded',
      name: 'Expanded',
      color: 'primary',
    },
    {
      id: 'images',
      type: 'images',
      name: 'Images',
      color: 'primary',
    },
    {
      id: 'ideas',
      type: 'ideas',
      name: 'Ideas',
      color: 'primary',
    },
  ];

  const renderGeneratedContentNavMenu = (
    <>
      <Scrollbar>
        <List disablePadding sx={{ minHeight: 300 }}>
          {items.map((label: any, index: number) =>
            label ? (
              <DrawerNavItem
                key={label.id}
                item={label}
                selectedLabel={selectedSettingGroup}
                setSelectedLabel={setSelectedSettingGroup}
              />
            ) : (
              <SkeletonMailNavItem key={index} />
            )
          )}
        </List>
      </Scrollbar>
    </>
  );

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));

  let gridHeight = `${window.innerHeight * 0.5}px`; // default for small screens
  if (matchesMD) gridHeight = `${window.innerHeight * 0.67}px`; // md screens
  if (matchesXL) gridHeight = `${window.innerHeight * 0.67}px`; // xl screens

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: '',
      width: 20,
      renderCell: (params: any) => (
        <>
          <IconButton
            onClick={(event) => {
              console.log('click params', params);
              setSelectedGeneratedContentId(params.id);
              handleOpenPopover(event);
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </>
      ),
    },
    {
      field: 'emailTypeGroupOption',
      headerName: 'Industry',
      width: 160,
      resizable: true,
      renderCell,
    },
    { field: 'emailType', headerName: 'Email Type', width: 120, resizable: true, renderCell },
    { field: 'emailTypeSection', headerName: 'Section', width: 220, resizable: true, renderCell },
    {
      field: 'generatedContent',
      headerName: 'Generated Content',
      width: 300,
      resizable: true,
      renderCell: renderGeneratedContentCell,
    },
    // { field: 'vote', headerName: 'vote', width: 90, resizable: true, renderCell: renderVote },
    {
      field: 'feedback',
      headerName: 'Last Feedback Prompt',
      width: 200,
      resizable: true,
      renderCell,
    },

    {
      field: 'generatedDate',
      headerName: 'Date Generated',
      width: 160,
      resizable: true,
      renderCell: (params) => {
        const formattedDate = format(new Date(params.value), 'dd/MM/yyyy HH:mm:ss');
        return (
          <Tooltip title={formattedDate}>
            <div>{formattedDate}</div>
          </Tooltip>
        );
      },
    },
  ];
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  //  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const { themeStretch } = useSettingsContext();

  const generatedContentdd = (
    <>
      <Card>
        {/* <GeneratedContentTableToolbar
          selectedEmailType={selectedEmailType}
          setSelectedEmailType={setSelectedEmailType}
          selectedIndustry={selectedIndustry}
          setSelectedIndustry={setSelectedIndustry}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          applyFilters={applyFilters}
          selectedRowsId={selectedGeneratedContentsIds}
          reloadGeneratedContents={reloadGeneratedContents}
        /> */}
        {loading ? (
          <Box
            sx={{ height: { sm: 400, xl: 600 }, maxHeight: { sm: 400, xl: 600 }, width: '100%' }}
          >
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </Box>
        ) : (
          <DataGrid
            style={{ height: gridHeight }}
            checkboxSelection
            onCellClick={(params, event) => {
              event.stopPropagation();
            }}
            rows={filteredUserGeneratedContents}
            columns={columns}
            pagination
            // components={{
            //   Pagination: GridPagination,
            // }}
            onRowModesModelChange={(newModel: any) => {
              const newSelectionModeStrArr = newModel as string[];
              setSelectedGeneratedContentsIds(
                newSelectionModeStrArr.map((item) => parseInt(item, 10))
              );
            }}
            // onSelectionModelChange={(newSelectionModel) => {
            //   const newSelectionModeStrArr = newSelectionModel as string[];
            //   setSelectedGeneratedContentsIds(
            //     newSelectionModeStrArr.map((item) => parseInt(item, 10))
            //   );
            // //  setSelectionModel(newSelectionModel);
            // }}
            // components={{
            //   Toolbar: GridToolbar,
            // }}
          />
        )}
      </Card>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 170 }}
      >
        <MenuItem
          onClick={() => {
            //   handleOpenConfirm();
            //   handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            // navigate(
            //   PATH_DASHBOARD.emailMarketing.editGeneratedContent(
            //     selectedGeneratedContentId as number
            //   )
            // );
            if (dialogMode && onEdit) {
              onEdit(selectedGeneratedContentId as number);
            } else {
              navigate(`edit/${selectedGeneratedContentId as number}`);
            }
            handleClosePopover();
            //   if (onEditRow) {
            //     onEditRow();
            //     handleClosePopover();
            //   }
          }}
        >
          <Iconify icon="carbon:view-filled" />
          View
        </MenuItem>
      </MenuPopover>
      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={() => {}}>
            Delete
          </Button>
        }
      />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Outputs</title>
      </Helmet>
      <Container maxWidth={false}>
        {!dialogMode && <CustomBreadcrumbs heading="Outputs" links={[{ name: '' }]} />}

        {isDesktop ? (
          <>
            <Stack direction="row" spacing={2}>
              <Drawer
                variant="permanent"
                PaperProps={{
                  sx: {
                    width: NAV.W_BASE,
                    position: 'relative',
                  },
                }}
              >
                {renderGeneratedContentNavMenu}
              </Drawer>
              {selectedSettingGroup === 'Email Content' && generatedContentdd}
            </Stack>
          </>
        ) : (
          <Drawer
            open
            ModalProps={{ keepMounted: true }}
            PaperProps={{
              sx: {
                width: NAV.W_BASE,
              },
            }}
          >
            {renderGeneratedContentNavMenu}
          </Drawer>
        )}
      </Container>
    </>
  );
}
function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
}: {
  inputData: IContact[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string[];
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const campaign = comparator(a[0], b[0]);
    if (campaign !== 0) return campaign;
    return a[1] - b[1];
  });

  const filteredData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    // filteredData = filteredData.filter(
    //   (campaign) => campaign.campaignName?.toLowerCase().includes(filterName.toLowerCase())
    // );
  }

  if (filterStatus && filterStatus.length) {
    //   filteredData = filteredData.filter((campaign) => filterStatus.includes(campaign.inventoryType));
  }

  return filteredData;
}
