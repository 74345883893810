import { FollowUpLogic } from 'src/api';
import { ContentType } from 'src/common/enums/content-type.enum';

export enum VoteState {
  NoVote,
  ThumbsUp,
  ThumbsDown,
}
export interface GeneratedContentData {
  content: string;
  type: string;
}

export type IContentState = {
 // lastGenerated?: ContentGeneration;
 // generatedContents: GeneratedLogic[];
 // lastTransformed?: ContentTransformation;
 // transformedContents: TransformedLogic[];
  lastCrafted?: CraftGeneration;
  crafted: {[contentType: number]: any[]};
  followUps: FollowUpLogic[];
  loading: boolean;
  error: Error | string | null;
};

export interface CraftGeneration {
  craftedContent: {[contentType: number]: { [actionType: string]: string }};
  contentType: ContentType;
  actionType?: string;
  finishedCrafting: boolean;
  isCrafting: boolean;
  contentId: ContentIds;
}

export type GeneratedContentFormProps = {
  emailTypeGroupOption: string;
  emailType: string;
  emailTypeSection: string;
  generatedContent: string;
  vote: VoteState;
};

export interface ContentGeneration {
  generatedContent: { [actionType: string]: string };
  lastWord: string;
  buffer: string;
  actionType?: string;
  finishedGenerating: boolean;
  isGenerating: boolean;
  contentId: ContentIds;
}

export interface ContentTransformation {
  transformedContent: { [actionType: string]: string };
  actionType?: string;
  finishedTransforming: boolean;
  isTransforming: boolean;
  contentId: ContentIds;
}

export interface ContentIds {
  [contentType: number]: { [actionType: string]: number };
}

export enum ContentGenerationType {
  Rephrase,
  Summarize,
  Expand,
}
export interface ContentTag {
  id: number;
  emailTypeGroupOption: string;
  emailType: string;
  section: string;
  tag: string;
  description: string;
  settingsId?: number;
}
