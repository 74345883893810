import {
  Grid,
  Card,
  Button,
  TextField,
  Skeleton,
  useMediaQuery,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { ConfigurationLogic } from 'src/api';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { PATH_MARKETING } from 'src/routes/paths';
import { ACTION_ICONS } from 'src/common/constants/icons.constants';
import { SmallActionIcon } from 'src/components/nav-section/mini/styles';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import TransformButton from 'src/components/generate/TransformButton';
import { useSelector } from 'src/redux/store';
import Scrollbar from 'src/components/scrollbar';
import RenderHTML from 'src/utils/renderHtml';
import ConfigurationButton from 'src/components/configurations/ConfigurationsButton';
import ConfigurationDialog from 'src/components/configurations/ConfigurationDialog';
import { ContentType } from 'src/common/enums/content-type.enum';

interface Props {
  selectedRefineActionProp?: string;
  selectedEditorContent?: string;
}

export default function RefinePage({ selectedRefineActionProp, selectedEditorContent }: Props) {
  const [originalContent, setOriginalContent] = useState<string>(selectedEditorContent ?? '');
  const [selectedRefineAction, setSelectedRefineAction] = useState<string>(
    selectedRefineActionProp ?? 'Rephrase'
  );
  const { enqueueSnackbar } = useSnackbar();
  const [preservedKeywordOptions, setPreservedKeywordOptions] = useState<string[]>([]);
  const [openRefineConfigurationsDialog, setOpenRefineConfigurationsDialog] =
    useState<boolean>(false);
  const crafted = useSelector((state) => state.craft?.lastCrafted);
  const [refineOptions, setRefineOptions] = useState([
    { label: 'Rephrase', value: 'Rephrase' },
    { label: 'Shorten', value: 'Shorten' },
    { label: 'Expand', value: 'Expand' },
    { label: 'Summarize', value: 'Summarize' },
  ]);
  const [transformedContent, setTransformedContent] = useState<string>();
  const isMobile = useMediaQuery('(max-width:600px)');
  const isLaptop = useMediaQuery('(min-width:601px) and (max-width:1440px)');
  const [configuration, setConfiguration] = useState<ConfigurationLogic>();
  const handleChangeRefineAction = (event: React.MouseEvent<HTMLElement>, refineAction: string) => {
    if (refineAction) {
      setSelectedRefineAction(refineAction);
    }
  };
  const nav = useNavigate();
  const onBackStep = () => {
    nav(-1);
  };
  useEffect(() => {
    if (crafted?.craftedContent && crafted?.craftedContent[ContentType.Transformed] &&  crafted.finishedCrafting) {
      const formattedText = `<p style="line-height: 2">${crafted?.craftedContent[
        ContentType.Transformed
      ][selectedRefineAction].replace(/\n/g, '<br>')}</p>`;
      setTransformedContent(formattedText);
    }
  }, [crafted, selectedRefineAction]);
  return (
    <>
      <Helmet>
        <title>Refine Content</title>
      </Helmet>

      <Container maxWidth={false}>
        {!selectedRefineActionProp && (
          <CustomBreadcrumbs
            heading="Refine Content"
            links={[
              { name: 'Actions', href: PATH_MARKETING.pages.actions },
              { name: 'Refine' },
            ]}
          />
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3, minHeight: 200 }}>
              <Stack spacing={3}>
                {/* <Button
                  size="small"
                  color="inherit"
                  sx={{ maxWidth: 100, justifyContent: 'flex-start' }}
                  onClick={onBackStep}
                  startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
                >
                  Back
                </Button> */}
                <ToggleButtonGroup
                  onChange={handleChangeRefineAction}
                  exclusive
                  sx={{ maxWidth: 345 }}
                  value={selectedRefineAction}
                  aria-label="device"
                >
                  <ToggleButton value="Rephrase" aria-label="Rephrase">
                    <Tooltip title="Rephrase - crafts clear, engaging, and professional sentences, eliminating redundancy and elevating clarity in a snap.">
                      <SmallActionIcon>{ACTION_ICONS.rephrase}</SmallActionIcon>
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value="Shorten" aria-label="Shorten">
                    <Tooltip title="Shorten - ">
                      <SmallActionIcon>{ACTION_ICONS.shorten}</SmallActionIcon>
                    </Tooltip>
                  </ToggleButton>

                  <ToggleButton value="Expand" aria-label="Expand">
                    <Tooltip title="Expand - uses AI to elaborate on core ideas, ensuring richer context and a comprehensive understanding. Turn brief points into full-fledged insights.">
                      <SmallActionIcon>{ACTION_ICONS.expand}</SmallActionIcon>
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value="Summarize" aria-label="Summarize">
                    <Tooltip title="Summarize - captures the essence, delivering clear and concise overviews without sacrificing key information. ">
                      <SmallActionIcon>{ACTION_ICONS.summarize}</SmallActionIcon>
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton disabled value="imageEnhance" aria-label="imageEnhance">
                    <Tooltip title="Empower your visuals with AI-driven image enhancement.">
                      <SmallActionIcon>{ACTION_ICONS.enhanceImg}</SmallActionIcon>
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
                {/* <RHRadioGroup
                  value={selectedRefineAction}
                  onChange={(event) => {
                    setSelectedRefineAction(event.target.value);
                  }}
                  row
                  spacing={4}
                  name="complexityOptions"
                  options={refineOptions}
                /> */}
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Stack sx={{ minWidth: '50%' }} spacing={3}>
                    <TextField
                      onChange={(event) => {
                        setOriginalContent(event.target.value);
                        const uniqueWordsSet = new Set(
                          event.target.value.replace(/[.,?![\]]/g, '').split(' ')
                        );
                        console.log("event.target.value.split(' ')", event.target.value.split(' '));
                        setPreservedKeywordOptions(Array.from(uniqueWordsSet));
                      }}
                      fullWidth
                      defaultValue={selectedEditorContent ?? ''}
                      id="originalContent"
                      name="originalContent"
                      label="Original Content"
                      multiline
                      minRows={isLaptop ? '8' : '20'}
                      maxRows={isLaptop ? '8' : '20'}
                    />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center">
                      {/* <TransformButton
                        header={selectedRefineAction}
                        sourceContent={originalContent}
                        configurations={configuration}
                      /> */}
                      <ConfigurationButton type={selectedRefineAction} />
                    </Stack>
                  </Stack>
                  {crafted?.isCrafting ? (
                    <Box
                      sx={{ mx: 3, width: '100%', overflow: 'auto', height: isLaptop ? 215 : 500 }}
                    >
                      <Skeleton variant="rectangular" width="100%" height="100%" />
                    </Box>
                  ) : (
                    <>
                      <Stack sx={{ minWidth: '50%' }} spacing={3}>
                        <Card sx={{ p: 3, minHeight: 495 }}>
                          <Stack
                            flexGrow={1}
                            sx={{
                              width: 1,
                              minWidth: 0,
                              borderRadius: 1.5,
                              bgcolor: 'background.default',
                            }}
                          >
                            <Box sx={{ overflow: 'hidden', flexGrow: 1 }}>
                              <Scrollbar sx={{ height: { md: 430 } }}>
                                <RenderHTML htmlString={transformedContent ?? ''} />
                              </Scrollbar>
                            </Box>
                          </Stack>
                        </Card>
                        <Button
                          fullWidth
                          startIcon={<Iconify icon="ph:copy" />}
                          variant="outlined"
                          color="inherit"
                          disabled={!transformedContent}
                          size="large"
                          onClick={() => {
                            enqueueSnackbar('Transformed content was copied to clipboard', {
                              variant: 'success',
                            });
                            if (crafted?.craftedContent && crafted?.craftedContent[ContentType.Transformed] &&  crafted.finishedCrafting) {
                              navigator.clipboard.writeText(
                                crafted?.craftedContent[ContentType.Transformed][selectedRefineAction] as string
                              );
                            }
                          }}
                          sx={{ maxWidth: { md: 200 }, maxHeight: { md: 40 } }}
                        >
                          Copy
                        </Button>
                      </Stack>
                    </>
                  )}
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
        <ConfigurationDialog
          onConfirmConfiguration={(config: ConfigurationLogic) => {
            setConfiguration(config);
          }}
          //       refineAction={selectedRefineAction}
          open={openRefineConfigurationsDialog}
          // setConfigurations={(refineSettings) => {
          //   setSettings(refineSettings);
          // }}
          onClose={() => setOpenRefineConfigurationsDialog(false)}
          //    preservedKeywordOptions={preservedKeywordOptions}
        />
      </Container>
    </>
  );
}
