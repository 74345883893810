import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { IConnectionState } from 'src/@types/connection';
import { ConnectionApi, ConnectionLogic, IntegrationsApi } from 'src/api';

// ----------------------------------------------------------------------

const initialState: IConnectionState = {
  connections: [],
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    // START LOADING
    setLoading(state, action) {
      state.loading = action.payload;
    },
    fetchConnectionsSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.connections = action.payload;
    },
    saveConnectionsSuccess(state, action) {
      if (!action.payload){
        return;
      }
      state.loading = false;
      const exist = state.connections.find((connection) => connection.id === action.payload.id);
      if (exist) {
        const index = state.connections.indexOf(exist);
        state.connections[index] = action.payload;
      } else {
        state.connections.push(action.payload);
      }

      if (action.payload.default) {
        const otherIntegrationsWithDefault = state.connections.filter(
          (i) =>
            i.id !== action.payload.id &&
            i.integrationName === action.payload.integrationName &&
            i.default
        );
        if (otherIntegrationsWithDefault.length) {
          otherIntegrationsWithDefault.forEach((i) => {
            i.default = false;
          });
        }
      }
    },
    deleteConnectionsSuccess(state, action) {
      state.connections = state.connections.filter(
        (connection) => connection.id !== action.payload
      );
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

// Actions
export const { setLoading, saveConnectionsSuccess } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchConnections() {
  return async (dispatch: Dispatch) => {
    try {
      const api = new ConnectionApi();
      const connections = (await api.apiConnectionConnectionsGet()).data;
      dispatch(slice.actions.fetchConnectionsSuccess(connections));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function upsertConnection(connection: ConnectionLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const integrationApi = new IntegrationsApi();
      const testApiKeyResponse = await integrationApi.apiIntegrationsTestApiKeyGet(
        connection.apiKeyDetails?.apiKey || '',
        connection.integrationName || ''
      );
      if (!testApiKeyResponse.data) {
        enqueueSnackbar('Invalid Api Key', { variant: 'error' });
        throw new Error('Upsert connection failed');
      }
      const api = new ConnectionApi();
      if (!connection.id) {
        const response = await api.apiConnectionConnectionPost({
          connection,
        });
        connection.id = response.data.id;
      } else {
        const response = await api.apiConnectionConnectionPut({
          connection,
        });
      }
      dispatch(slice.actions.saveConnectionsSuccess(connection));
      enqueueSnackbar('Save Connection Success!', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}

export function deleteConnection(connectionId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const api = new ConnectionApi();
      await api.apiConnectionConnectionIdDelete(connectionId);
      dispatch(slice.actions.deleteConnectionsSuccess(connectionId));
      enqueueSnackbar('Delete Connection Success!', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
