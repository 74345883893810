// @mui
import { useTheme } from '@mui/material/styles';
import {
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  FormControlLabel,
  Switch,
  Box,
  Button,
} from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
//
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import UsersHeaderButton from './UsersHeaderButton';
import NotificationsPopover from './NotificationsPopover';
import { useSelector } from 'src/redux/store';
import Label from 'src/components/label';
import { useState } from 'react';
import PricingDialog from 'src/components/pricing/PricingDialog';
import CreditsDialog from 'src/components/credits/CreditsDialog';
import { useBoolean } from 'src/hooks/use-boolean';
import { SUBSCRIPTION_ICONS } from 'src/common/constants/icons.constants';
import CreditStatus from './CreditStatus';
import { useQuery } from 'src/utils/query';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const [darkMode, setDarkMode] = useState<boolean>(false);

  const pricingOpen = useBoolean(false);

  const basicBillingInfo = useSelector((state) => state.subscription.basicBillingInfo);

  const { themeLayout, themeMode, onToggleMode } = useSettingsContext();

  const editorFullScreen = useSelector((state) => state.editor.fullScreen);

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={{ xs: 0.5, sm: 2 }}
      >
        <Label color="info">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box sx={{ width: 24, height: 24, color: 'info' }}>
              {
                SUBSCRIPTION_ICONS[
                  (basicBillingInfo?.productName?.toLocaleLowerCase() ??
                    'starter') as keyof typeof SUBSCRIPTION_ICONS
                ]
              }
            </Box>
            <span>
              {basicBillingInfo?.subscriptionStatus === 'trialing' ? '(Trial)' : ''}
              {basicBillingInfo?.productName ?? 'Starter(Free)'} Plan
            </span>
            <IconButton onClick={pricingOpen.onTrue} sx={{ ml: 1 }}>
              <Iconify icon="grommet-icons:upgrade" />
            </IconButton>
          </Stack>
        </Label>
        {(!basicBillingInfo?.productName || basicBillingInfo?.productName === 'Starter') && (
          <CreditStatus />
        )}
        {/* <Label color="info">
          0 / 500 Verts
          <IconButton onClick={creditsOpen.onTrue} sx={{ ml: 1 }}>
            <Iconify icon="grommet-icons:upgrade" />
          </IconButton>
        </Label>

        {/* <Label color="info">
          Remaining Quota: 100
          <IconButton onClick={onOpenNav} sx={{ ml: 1 }}>
            <Iconify icon="icon-park-outline:add" />
          </IconButton>
        </Label> */}
      </Stack>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* <SettingsButton /> */}
        {/* <RHFSwitch
                  name="darkMode"
                  label="Dark Mode"
                  onClick={() => {setDarkMode(!darkMode)}}
                  checked={darkMode}
                  labelPlacement="start"
                  sx={{ mx: 0, width: 1,color: "black", justifyContent: 'start' }}
                /> */}
        <FormControlLabel
          labelPlacement="start"
          control={<Switch checked={themeMode === 'dark'} onChange={onToggleMode} />}
          label="Dark Mode"
          sx={{ color: '#637381' }}
        />
        <LanguagePopover />

        <NotificationsPopover />

        {/* <UsersHeaderButton /> */}

        <AccountPopover />
      </Stack>
      <PricingDialog open={pricingOpen.value} onClose={pricingOpen.onFalse} />
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: editorFullScreen ? 0 : 1005,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
