import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { EmailProviderApi } from 'src/api';
import { IContactState } from 'src/@types/contact';

// ----------------------------------------------------------------------

const initialState: IContactState = {
  contacts: {},
  error: null,
  loading: false,
  loaded: false
};

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    // START LOADING
    startFetching(state) {
      state.loading = true;
    },
    fetchContactsSuccess(state, action) {
      state.loading = false;
      if (!state.contacts[action.payload.provider]) {
        state.contacts[action.payload.provider] = {};
      }
      if (!state.contacts[action.payload.provider][action.payload.listId]) {
        state.contacts[action.payload.provider][action.payload.listId] = [];
      }
      state.contacts[action.payload.provider][action.payload.listId] = action.payload.contacts;
      state.loaded = true;
    },
    deleteContact(state, action) {
      state.contacts[action.payload.provider][action.payload.listId] = state.contacts[
        action.payload.provider
      ][action.payload.listId].filter((contact) => contact.emailAddress !== action.payload);
    },
    deleteContactsSuccess(state, action) {
      
      state.contacts[action.payload.provider][action.payload.listId] = state.contacts[
        action.payload.provider
      ][action.payload.listId].filter(
        (contact) => !action.payload.contactsId.includes(contact.id)
      );
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchContacts(provider: string, listId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetching());
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderContactsGet(provider, listId);
      // dispatch(slice.actions.fetchContactsSuccess({ provider, listId, contacts: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteContact(provider: string, listId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetching());
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderContactsGet(provider, listId);
      // dispatch(slice.actions.fetchContactsSuccess({ provider, listId, contacts: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteContacts(
  contactsId: string[],
  provider: string,
  listId: string,
  enqueueSnackbar: any
) {
  return async (dispatch: Dispatch) => {
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderContactsDelete({
      //   provider,
      //   listId,
      //   contactsId,
      // });
      enqueueSnackbar('Contacts were deleted succesfully', { variant: 'success' });
      dispatch(slice.actions.deleteContactsSuccess({ contactsId, provider, listId }));
    } catch (error) {
      enqueueSnackbar('Error deleting contacts', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    }
  };
}
