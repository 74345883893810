import { CircularProgress, CircularProgressProps, circularProgressClasses } from '@mui/material';

export default function ItemProcessingSpinner(props: CircularProgressProps) {
  return (
    <CircularProgress
      variant="indeterminate"
      disableShrink
      sx={{
        color: (theme) => (theme.palette.mode === 'light' ? '#00AB55' : '#00AB55'),
        animationDuration: '550ms',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }}
      size={24}
      thickness={4}
    />
  );
}
