import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { EmailProviderApi } from 'src/api';
import { IListState } from 'src/@types/lists';

// ----------------------------------------------------------------------

const initialState: IListState = {
  lists: {},
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    // START LOADING
    startFetching(state) {
      state.loading = true;
    },
    fetchListSuccess(state, action) {
      state.loading = false;
      if (!state.lists[action.payload.provider]) {
        state.lists[action.payload.provider] = [];
      }
      state.lists[action.payload.provider] = action.payload.lists;
      state.loaded = true;
    },
    deleteList(state, action) {
      state.lists[action.payload.provider] = state.lists[action.payload.provider].filter(
        (list: any) => list.id !== action.payload
      );
    },
    deleteListsSuccess(state, action) {
      
      state.lists[action.payload.provider] = state.lists[action.payload.provider].filter(
        (list: any) => !action.payload.listsId.includes(list.id)
      );
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchLists(provider: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetching());
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderListsGet(provider);
      // dispatch(slice.actions.fetchListSuccess({ provider, lists: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// export function deleteLists(
//   listsIds: number[],
//   provider: string,
//   listId: string,
//   enqueueSnackbar: any
// ) {
//   return async (dispatch: Dispatch) => {
//     try {
//       const emailProviderApi = new EmailProviderApi();
//       const response = await emailProviderApi.apiEmailProviderListsDelete({
//         providerName: provider,
//         listId,
//         listsIds,
//       });
//       enqueueSnackbar('Lists were deleted succesfully', { variant: 'success' });
//       dispatch(slice.actions.deleteListsSuccess({ listsIds, provider, listId }));
//     } catch (error) {
//       enqueueSnackbar('Error deleting lists', { variant: 'error' });
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
