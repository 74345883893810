/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// import { store, useSelector } from 'src/redux/store';
import { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import _ from 'lodash';
import { PubSubContext, usePubSub } from 'src/components/pub-sub/PubSubProvider';
import PubSubService from 'src/components/pub-sub/PubSubService';

export const BASE_PATH = `${process.env.REACT_APP_WEBAPI_URL}`.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;
  static contextType = PubSubContext; // Using contextType to access the context
  context!: React.ContextType<typeof PubSubContext>; // Asserting that this.context will not be null
  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios
  ) {
    if (!configuration) {
      configuration = {
        accessToken: localStorage.getItem('accessToken') as string,
        basePath: BASE_PATH,
        isJsonMime: (mime: string) => /json/i.test(mime),
      };
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
      this.setupInterceptors();
    }
  }

  componentDidMount() {
    const pubSub: any = this.context; // Use the context here
    pubSub.subscribe('login_success', (newToken: string) => {
      this.handleStoreUpdate(newToken);
    });
  }

  private handleStoreUpdate(newToken?: string) {
    this.configuration = {
      accessToken: newToken,
      basePath: BASE_PATH,
      isJsonMime: (mime: string) => /json/i.test(mime),
    };
    this.setupInterceptors();
  }

  private setupInterceptors() {
    this.axios.interceptors.request.use(
      (config) => {
        const token = this.configuration?.accessToken;
        if (token && config.headers) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name: 'RequiredError' = 'RequiredError';
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}
