import { MenuItem, Avatar, ListSubheader } from '@mui/material';
import { Stack } from '@mui/system';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { EmailProviderApi } from 'src/api';
import { RHSelect } from 'src/components/hook-form';
import { RHAutocomplete } from 'src/components/hook-form/RHFAutocomplete';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';
import { updateSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';

export interface Props {
  handleSelectMethod: (provider: string) => void;
  selectedCampaignInput?: string;
  maxWidth?: number;
  disabled?: boolean;
}

export default function MailchimpCampaignSelect({
  handleSelectMethod,
  maxWidth,
  disabled,
  selectedCampaignInput,
}: Props) {
  const [selectedCampaignId, setSelectedCampaignId] = useState<any>({ value: '', label: '' });
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const conId = selectedItem?.inputData?.actionInputData?.inputData?.providerData?.connectionId;
  const [connectionId, setConnectionId] = useState<number | undefined>();
  const [loadingCampaigns, setLoadingCampaigns] = useState<boolean>(false);
  const [campaignOptions, setCampaignOptions] = useState<any[]>([]);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  useEffect(() => {
    if (selectedItem) {
        if (!conId) {
          return;
        }
      setConnectionId(conId);
      const getCampaigns = async () => {
        setLoadingCampaigns(true);
        const emailProviderApi = new EmailProviderApi();
        const campaigns = await emailProviderApi.apiEmailProviderCampaignsGet('Mailchimp', conId);
        const campaignOptionsTmp = campaigns.data.map((campaign: any) => ({
          value: campaign.id,
          label: campaign?.name
            ? `Campaign Name: ${campaign.name} - Campaign Id: ${campaign.id}`
            : `Campaign Id: ${campaign.id}`,
        }));
        setCampaignOptions(campaignOptionsTmp);
        const camp = campaignOptionsTmp.find(
          (campaign) => campaign.value === selectedCampaignInput
        );
        if (selectedCampaignInput) {
          setSelectedCampaignId(camp);
        }
        setLoadingCampaigns(false);
      };
      getCampaigns();
    }
  }, [selectedItem, selectedCampaignInput,conId]);

  return (
    <>
      <RHAutocomplete
        zIndex={5000}
        disabled={disabled || loadingCampaigns || runsMode}
        loading={loadingCampaigns && !!connectionId}
        name="campaigns"
        size="small"
        label="Select Campaign"
        groupBy={(option) => option.category}
        options={campaignOptions}
        sx={{ minWidth: { md: 200 } }}
        value={selectedCampaignId}
        onChange={async (event: any, newValue: any) => {
          setSelectedCampaignId(newValue);
          handleSelectMethod(newValue.value);
        }}
        getOptionLabel={(option: any) => option.label}
      />
    </>
  );
}
