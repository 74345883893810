import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { ConfigurationLogic, ConfigurationsApi } from 'src/api';
import { IConfigurationsState } from 'src/@types/configurations';

// ----------------------------------------------------------------------

const initialState: IConfigurationsState = {
  configurations: [],
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    // START LOADING
    setLoading(state, action) {
      state.loading = action.payload
    },
    fetchConfigurationsSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.configurations = action.payload;
    },
    saveConfigurationsSuccess(state, action) {
      state.loading = false;
      const exist = state.configurations.find(
        (configuration) => configuration.id === action.payload.id
      );
      if (exist) {
        const index = state.configurations.indexOf(exist);
        state.configurations[index] = action.payload;
      } else {
        state.configurations.push(action.payload);
      }
    },
    deleteConfigurationsSuccess(state, action) {
      state.configurations = state.configurations.filter(
        (configuration) => !action.payload.configurationsIds.includes(configuration.id)
      );
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchConfigurations() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const configurationsApi = new ConfigurationsApi();
      const response = await configurationsApi.apiConfigurationsGet();
      dispatch(slice.actions.fetchConfigurationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function upsertConfiguration(configuration: ConfigurationLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const configurationsApi = new ConfigurationsApi();
      let result;
      dispatch(slice.actions.setLoading(true));
      if (!configuration.id) {
        const response = await configurationsApi.apiConfigurationsPost({ configuration });
        result = response.data;
      } else {
        const response = await configurationsApi.apiConfigurationsPut({ configuration });
        result = configuration;
      }
      dispatch(slice.actions.saveConfigurationsSuccess(result));
      enqueueSnackbar('Save Configurations success!');
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteConfiguration(configurationsIds: number[]) {
  return async (dispatch: Dispatch) => {
    try {
      const configurationsApi = new ConfigurationsApi();
      await configurationsApi.apiConfigurationsDelete({ configurationsIds });
      dispatch(slice.actions.deleteConfigurationsSuccess(configurationsIds));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
