import { useEffect, useRef, useState } from 'react';
// @mui
import { Dialog, DialogProps, DialogContent } from '@mui/material';
import { useDispatch, useSelector } from 'src/redux/store';
import { authenticate, updateCallbackProcessed } from 'src/redux/slices/integrations';
import { useBoolean } from 'src/hooks/use-boolean';
import { useSnackbar } from 'src/components/snackbar';
import { fetchConnections } from 'src/redux/slices/connections';
// components

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  onClose: VoidFunction;
  iframeUri: string;
}

export default function IntegrationAuthDialog({ onClose, iframeUri, ...other }: Props) {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [authenticating, setAuthenticating] = useState(false);
  const authenticatingRef = useRef(authenticating);
  const dispatch = useDispatch();
  const [eventListeners, setEventListeners] = useState<any>({});
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };
  const refState = useRef(null);
  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  const nowClose = useBoolean(false);

  const callbackProcessed = useSelector((state) => state.integration.callbackProcessed);
  function test(state: any) {
    refState.current = state;
  }

  useEffect(() => {
    authenticatingRef.current = authenticating;
  }, [authenticating]);

  // useEffect(() => {
  //   const callbackHandler = (event: any) => {
  //     if (event && event.data && event.data.code && !localStorage.getItem('callbackHandlerAdded')) {
  //       
  //       // if (callbackProcessed[event.data.provider]) {
  //       //   return;
  //       // }
  //       setTimeout(() => {
  //       localStorage.setItem('callbackHandlerAdded', 'true');
  //       }, 100);

  //           dispatch(updateCallbackProcessed(event.data.provider));

  //       dispatch(authenticate(event.data.code, event.data.provider));
  //       // nowClose.onTrue();
  //      // onClose();

  //       
  //       // setTimeout(() => {
  //       // onClose();
  //       // },200);
  //     }
  //   };
  //   // if (
  //   //   window && // check if event listener exist)
  //   //   window.addEventListener
  //   // ) {
  //   //   window.addEventListener('message', callbackHandler);
  //   // }

  //   if (!eventListeners.message && sessionStorage.getItem('callbackHandlerAdded')) {
  //     // Add the event listener to the window
  //     eventListeners.message = { callbackHandler };
  //     window.addEventListener('message', callbackHandler);

  //     // Record that the event listener has been added
  //   } else {
  //     onClose();
  //   }
  //   return () => {
  //     window.removeEventListener('message', callbackHandler);
  //   };
  // }, [dispatch, onClose, eventListeners, callbackProcessed]);

  // // useEffect(() => {
  // //   if (nowClose.value === true){
  // //     onClose();
  // //   }
  // // }, [nowClose, onClose]);

  // useEffect(() => {
  //   if (localStorage.getItem('callbackHandlerAdded') || callbackProcessed.Mailchimp) {
  //     localStorage.removeItem('callbackHandlerAdded');
  //     onClose();
  //   }
  // }, [onClose, callbackProcessed]);

  // useEffect(() => {
  //   // This function could be what you want to check every 100 ms
  //   const checkSomething = () => {
  //     // Implement your check logic here
  //     // if (sessionStorage.getItem('callbackHandlerAdded')){
  //     //   sessionStorage.removeItem('callbackHandlerAdded');
  //     //   clearInterval(intervalId)
  //     //   onClose();
  //     // }
  //     if (localStorage.getItem('callbackHandlerAdded') || callbackProcessed.Mailchimp) {
  //       localStorage.removeItem('callbackHandlerAdded');
  //       onClose();
  //     }
  //   };

  //   // Set up the interval
  //   const intervalId = setInterval(checkSomething, 200);

  //   // Clean up the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [onClose, callbackProcessed]); // Empty dependency array means this runs once on mount and once on unmount
  const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
    const callbackHandler = (event: any) => {

      if (event && event.data && event.data.code && event.data.provider){
        dispatch(authenticate(event.data.code,event.data.provider));
        enqueueSnackbar('Authentication successful', { variant: 'success'  });
        setTimeout(() => {
        dispatch(fetchConnections());
        },2000);
        onClose();
      }
    };
    window.addEventListener('message', callbackHandler);
    return () => window.removeEventListener('message', callbackHandler);
  }, [dispatch, onClose, enqueueSnackbar]);

  const [state, setState] = useState<any | null>(null);

  return (
    <>
      <Dialog sx={{ overflow: 'unset', width: 1600, height: 900 }} onClose={onClose} {...other}>
        {/* <DialogTitle> Integrate your mailchimp account </DialogTitle> */}

        <DialogContent sx={{ overflow: 'unset', height: 850 }}>
          <iframe
            title="Mailchimp"
            src={iframeUri}
            allow="autoplay; fullscreen"
            height={800}
            width={500}
            frameBorder="0"
            allowFullScreen
          />
        </DialogContent>

        {/* <DialogActions>
          <Button color="inherit" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
}
