import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem, Stack, TextField } from '@mui/material';
import _ from 'lodash';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider, { RHSelect } from 'src/components/hook-form';
import PreviousActionsSelect from 'src/components/previous-actions/PreviousActionsSelect';
import { setActionValidated, updateSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';

export default function AiFlowSegmentActionSetup() {
  const [previousActions, setPreviousActions] = useState<string[]>([]);
  const [segmentBy, setSegmentBy] = useState<string>('Newline');
  const [take, setTake] = useState<string>('All');
  const [customTake, setCustomTake] = useState<number>(1);
  const [skip, setSkip] = useState<string>('None');
  const [customSkip, setCustomSkip] = useState<number>(1);
  const [segmentsPerBlock, setSegmentsPerBlock] = useState<number>(1);
  const [selectedSource, setSelectedSource] = useState<string>('');
  const [selectedSourceType, setSelectedSourceType] = useState<string>('Text');
  const [selectedFileType, setSelectedFileType] = useState<string>('Csv');
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const [fileTypes, setFileTypes] = useState([
    { label: 'Csv', value: 'Csv' },
    { label: 'Excel', value: 'Xlsx' },
    { label: 'Txt', value: 'Txt' },
    { label: 'Json', value: 'Json' },
  ]);

  const [sourceTypes, setSourceTypes] = useState([
    { label: 'Text', value: 'Text' },
    { label: 'File', value: 'File' },
  ]);

  const [segmentByCsv, setSegmentByCsv] = useState([
    { label: 'Comma', value: 'Comma' },
    { label: 'New Line', value: 'Newline' },
  ]);

  const [segmentByExcel, setSegmentByExcel] = useState([
    { label: 'Cells', value: 'Cells' },
    { label: 'Rows', value: 'Rows' },
    { label: 'Sheets', value: 'Sheets' },
  ]);

  const [segmentByJson, setSegmentByJson] = useState([
    { label: 'Item', value: 'Item' },
    { label: 'Property', value: 'Property' },
  ]);

  const defaultValues = {
    selectedSource: '',
  };

  const AiFlowExtractorSchema: any = Yup.object().shape({
    selectedSource: Yup.string().required('Required'),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowExtractorSchema),
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.inputData?.actionInputData?.functionalInputData?.segmentInput) {
        setSelectedSource(
          selectedItem.inputData?.actionInputData?.functionalInputData?.segmentInput
            ?.source as string
        );
        methods.setValue(
          'selectedSource',
          selectedItem.inputData?.actionInputData?.functionalInputData?.segmentInput
            ?.source as string
        );
        methods.trigger();
        setSelectedSourceType(
          selectedItem.inputData?.actionInputData?.functionalInputData?.segmentInput
            ?.sourceType as string
        );
        setSelectedFileType(
          selectedItem.inputData?.actionInputData?.functionalInputData?.segmentInput
            ?.fileType as string
        );
        setSegmentBy(
          (selectedItem.inputData?.actionInputData?.functionalInputData?.segmentInput
            ?.segmentBy as string) ?? 'Newline'
        );
        setTake(
          (selectedItem.inputData?.actionInputData?.functionalInputData?.segmentInput
            ?.take as string) ?? 'All'
        );
        setCustomTake(
          selectedItem.inputData?.actionInputData?.functionalInputData?.segmentInput
            ?.takeCustom as number
        );
        setSkip(
          (selectedItem.inputData?.actionInputData?.functionalInputData?.segmentInput
            ?.skip as string) ?? 'None'
        );
        setCustomSkip(
          selectedItem.inputData?.actionInputData?.functionalInputData?.segmentInput
            ?.skipCustom as number
        );
      }
    }
  }, [selectedItem, methods]);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);
  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3} sx={{ mt: 2, mx: 1 }}>
          <PreviousActionsSelect
          disabled={runsMode}
            selectedSourceInput={selectedSource}
            onPreviousActionsSelect={(source: string) => {
              setSelectedSource(source);
              setValue('selectedSource', source);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      segmentInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.segmentInput,
                        source,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
          />

          <RHSelect
            key="selectSourceType"
            name="selectSourceType"
            size="small"
            disabled={runsMode}
            label="Select Source Type"
            zIndex={3400}
            value={selectedSourceType}
            sx={{ px: 1 }}
            onChange={(event) => {
              setSelectedSourceType(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      segmentInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.segmentInput,
                        sourceType: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
            InputLabelProps={{ shrink: true }}
          >
            {sourceTypes.map((sourceType) => (
              <MenuItem value={sourceType.value}>
                <span>{sourceType.label}</span>
              </MenuItem>
            ))}
          </RHSelect>
          {selectedSourceType === 'File' && (
            <RHSelect
            disabled={runsMode}
              key="selectFileType"
              name="selectFileType"
              size="small"
              label="Select File Type"
              zIndex={3400}
              value={selectedFileType}
              sx={{ px: 1 }}
              onChange={(event) => {
                setSelectedFileType(event.target.value);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      functionalInputData: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                        segmentInput: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData
                            ?.segmentInput,
                          fileType: event.target.value,
                        },
                      },
                    },
                  },
                };
                dispatch(updateSelectedAiFlowItem(updatedItem));
              }}
              InputLabelProps={{ shrink: true }}
            >
              {fileTypes.map((fileType) => (
                <MenuItem value={fileType.value}>
                  <span>{fileType.label}</span>
                </MenuItem>
              ))}
            </RHSelect>
          )}
          {selectedSourceType !== 'Text' && (
            <>
              <RHSelect
               disabled={runsMode}
                key="segmentBy"
                name="segmentBy"
                size="small"
                label="Segment By"
                zIndex={3400}
                value={segmentBy}
                sx={{ px: 1 }}
                onChange={(event) => {
                  setSegmentBy(event.target.value);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        functionalInputData: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                          segmentInput: {
                            ...selectedItem?.inputData?.actionInputData?.functionalInputData
                              ?.segmentInput,
                            segmentBy: event.target.value,
                          },
                        },
                      },
                    },
                  };
                  dispatch(updateSelectedAiFlowItem(updatedItem));
                }}
                InputLabelProps={{ shrink: true }}
              >
                {selectedFileType === 'Csv' &&
                  segmentByCsv.map((sb) => (
                    <MenuItem value={sb.value}>
                      <span>{sb.label}</span>
                    </MenuItem>
                  ))}
                {selectedFileType === 'Xlsx' &&
                  segmentByExcel.map((sb) => (
                    <MenuItem value={sb.value}>
                      <span>{sb.label}</span>
                    </MenuItem>
                  ))}
                {selectedFileType === 'Json' &&
                  segmentByJson.map((sb) => (
                    <MenuItem value={sb.value}>
                      <span>{sb.label}</span>
                    </MenuItem>
                  ))}
              </RHSelect>
              <Stack direction="row" spacing={2} alignItems="center">
                <RHSelect
                 disabled={runsMode}
                  key="take"
                  name="take"
                  size="small"
                  label="Take"
                  zIndex={3400}
                  value={take}
                  sx={{ px: 1 }}
                  onChange={(event) => {
                    setTake(event.target.value);
                    const updatedItem = {
                      ...selectedItem,
                      inputData: {
                        ...selectedItem?.inputData,
                        actionInputData: {
                          ...selectedItem?.inputData?.actionInputData,
                          functionalInputData: {
                            ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                            segmentInput: {
                              ...selectedItem?.inputData?.actionInputData?.functionalInputData
                                ?.segmentInput,
                              take: event.target.value,
                            },
                          },
                        },
                      },
                    };
                    dispatch(updateSelectedAiFlowItem(updatedItem));
                  }}
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="All">
                    <span>All</span>
                  </MenuItem>
                  <MenuItem value="Custom">
                    <span>Custom</span>
                  </MenuItem>
                </RHSelect>
                {take === 'Custom' && (
                  <TextField
                  disabled={runsMode}
                    onChange={(e) => {
                      setCustomTake(Number(e.target.value));
                      const updatedItem = {
                        ...selectedItem,
                        inputData: {
                          ...selectedItem?.inputData,
                          actionInputData: {
                            ...selectedItem?.inputData?.actionInputData,
                            functionalInputData: {
                              ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                              segmentInput: {
                                ...selectedItem?.inputData?.actionInputData?.functionalInputData
                                  ?.segmentInput,
                                takeCustom: Number(e.target.value),
                              },
                            },
                          },
                        },
                      };
                      dispatch(updateSelectedAiFlowItem(updatedItem));
                    }}
                    fullWidth
                    name="customTake"
                    defaultValue={customTake}
                    size="small"
                    label="Custom Take"
                    type="number"
                  />
                )}
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <RHSelect
                 disabled={runsMode}
                  key="skip"
                  name="skip"
                  size="small"
                  label="Skip"
                  zIndex={3400}
                  value={skip}
                  sx={{ px: 1 }}
                  onChange={(event) => {
                    setSkip(event.target.value);
                    const updatedItem = {
                      ...selectedItem,
                      inputData: {
                        ...selectedItem?.inputData,
                        actionInputData: {
                          ...selectedItem?.inputData?.actionInputData,
                          functionalInputData: {
                            ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                            segmentInput: {
                              ...selectedItem?.inputData?.actionInputData?.functionalInputData
                                ?.segmentInput,
                              skip: event.target.value,
                            },
                          },
                        },
                      },
                    };
                    dispatch(updateSelectedAiFlowItem(updatedItem));
                  }}
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="None">
                    <span>None</span>
                  </MenuItem>
                  <MenuItem value="Custom">
                    <span>Custom</span>
                  </MenuItem>
                </RHSelect>
                {skip === 'Custom' && (
                  <TextField
                  disabled={runsMode}
                    onChange={(e) => {
                      setCustomSkip(Number(e.target.value));
                      const updatedItem = {
                        ...selectedItem,
                        inputData: {
                          ...selectedItem?.inputData,
                          actionInputData: {
                            ...selectedItem?.inputData?.actionInputData,
                            functionalInputData: {
                              ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                              segmentInput: {
                                ...selectedItem?.inputData?.actionInputData?.functionalInputData
                                  ?.segmentInput,
                                skipCustom: Number(e.target.value),
                              },
                            },
                          },
                        },
                      };
                      dispatch(updateSelectedAiFlowItem(updatedItem));
                    }}
                    fullWidth
                    name="customSkip"
                    defaultValue={customSkip}
                    size="small"
                    label="Custom Skip"
                    type="number"
                  />
                )}
              </Stack>
            </>
          )}
          {selectedSourceType === 'Text' && (
            <TextField
            disabled={runsMode}
              autoFocus
              margin="dense"
              id="name"
              value={segmentBy}
              onChange={(e) => {
                setSegmentBy(e.target.value);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      functionalInputData: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                        segmentInput: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData
                            ?.segmentInput,
                          segmentBy: e.target.value,
                        },
                      },
                    },
                  },
                };
                dispatch(updateSelectedAiFlowItem(updatedItem));
              }}
              label="Segment By"
              helperText="Segmentation seperator token/word (e.g. , | ;)"
              fullWidth
              sx={{ px: 1 }}
              variant="standard"
            />
          )}
          {/* <TextField
            autoFocus
            margin="dense"
            disabled
            value={segmentsPerBlock}
            onChange={(e) => setSegmentsPerBlock(Number(e.target.value))}
            id="version"
            label="Segments Per Block"
            helperText="Number of segments per block"
            type="number"
            fullWidth
            variant="standard"
          /> */}
        </Stack>
      </FormProvider>
    </>
  );
}
