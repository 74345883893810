import { Container } from '@mui/system';
import { Helmet } from 'react-helmet-async';
import AiFlowPage from './AiFlowPage';
import { useDispatch, useSelector } from 'src/redux/store';
import { useEffect, useState } from 'react';
import { AiFlowLogic } from 'src/api';
import { useParams } from 'react-router';
import { selectAiFlow } from 'src/redux/slices/aiflows';
import { useQuery } from 'src/utils/query';

export default function EditAiFlowPage() {
  const { id } = useParams();
  const query = useQuery();
  const version = query.get('version');
  const aiFlows = useSelector((state) => state.aiFlows.aiFlows);
  const [initiated, setInitiated] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (aiFlows && id && !initiated) {
      const selWf = aiFlows.find((w) => w.id === parseInt(id as string, 10));
      dispatch(selectAiFlow({aiFlow: selWf, version: version as string}));
      if (selWf) {
        setInitiated(true);
      }
    }
  }, [id, aiFlows, dispatch, initiated,version]);

  return (
    <>
      <Helmet>
        <title>Edit Flow</title>
      </Helmet>

      <Container maxWidth={false}>
        <AiFlowPage edit />
      </Container>
    </>
  );
}
