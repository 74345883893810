import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { ISubscriptionState } from 'src/@types/subscription';
import { StripeApi, SubscriptionApi } from 'src/api';

// ----------------------------------------------------------------------

const initialState: ISubscriptionState = {
  basicBillingInfo: null,
  detailedBillingInfo :null,
  tiers: [],
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    // START LOADING
    setLoading(state, action) {
      state.loading = action.payload;
    },
    fetchBasicBillingInfoSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.basicBillingInfo = action.payload;
    },
    fetchDetailedBillingInfoSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.detailedBillingInfo = action.payload;
    },
    fetchTiersSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.tiers = action.payload;
    },
    updateSubscriptionPlanSuccess(state, action) {
      state.basicBillingInfo!.productName = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function updateSubscriptionPlan(productName: string, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const stripeApi = new StripeApi();
      dispatch(slice.actions.setLoading(true));
      await stripeApi.apiStripeUpdateSubscriptionPlanPost({
        productName,
      });
      dispatch(slice.actions.updateSubscriptionPlanSuccess(productName));
      enqueueSnackbar('Update Subscription Plan Success!', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchBasicBillingInfo() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const subscriptionApi = new SubscriptionApi();
      const response = await subscriptionApi.apiSubscriptionBillingInfoBasicGet();
      dispatch(slice.actions.fetchBasicBillingInfoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchDetailedBillingInfo() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const subscriptionApi = new SubscriptionApi();
      const response = await subscriptionApi.apiSubscriptionBillingInfoDetailedGet();
      dispatch(slice.actions.fetchDetailedBillingInfoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchTiers() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const subscriptionApi = new SubscriptionApi();
      const response = await subscriptionApi.apiSubscriptionTiersGet();
      dispatch(slice.actions.fetchTiersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cancelSubscription(enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const stripeApi = new StripeApi();
      await stripeApi.apiStripeCancelSubscriptionPost();
      enqueueSnackbar('Cancel Subscription Success.', { variant: 'success' });
      dispatch(slice.actions.fetchBasicBillingInfoSuccess(null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reactivateSubscription(enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const stripeApi = new StripeApi();
      await stripeApi.apiStripeReactivateSubscriptionPost();
      enqueueSnackbar('Reactivate Subscription Success.', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
