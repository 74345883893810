import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
// utils
import { fData } from '../../../utils/formatNumber';
//
import Iconify from '../../iconify';
import { varFade } from '../../animate';
import FileThumbnail, { fileData } from '../../file-thumbnail';
//
import { UploadProps } from '../types';
import RHFTextField from 'src/components/hook-form/RHFTextField';
import { useState, useEffect, useCallback } from 'react';
import { setFileMapName } from 'src/redux/slices/upload';
import { useDispatch } from 'src/redux/store';

// ----------------------------------------------------------------------

export default function MultiFilePreview({
  thumbnail,
  files,
  onRemove,
  onDownload,
  onView,
  sx,
  save,
  disabled,
}: UploadProps) {
  const dispatch = useDispatch();
  const mapFilesToSavedTemplatesNames = useCallback(() => {
    const map = files?.reduce(
      (acc, file) => ({
        ...acc,
        [fileData(file).key as string]: fileData(file).name,
      }),
      {}
    );
    return map;
  }, [files]);

  const [savedTemplatesNames, setSavedTemplatesNames] = useState<
    { [key: string]: string } | undefined
  >(mapFilesToSavedTemplatesNames());

  useEffect(() => {
    setSavedTemplatesNames(mapFilesToSavedTemplatesNames());
    dispatch(setFileMapName(mapFilesToSavedTemplatesNames()));
  }, [files, mapFilesToSavedTemplatesNames, dispatch]);

  if (!files?.length) {
    return null;
  }

  //     return {
  //       name: fileData(file).name as string,
  //       key: fileData(file).key as string,
  //     };
  //   })
  // );

  const handleChange = (key: string, name: string) => {
    const fileMap = {
      ...savedTemplatesNames,
      [key]: name,
    };
    setSavedTemplatesNames(fileMap);

    dispatch(setFileMapName(fileMap));
  };

  return (
    <AnimatePresence initial={false}>
      {files.map((file) => {
        const { key, name = '', size = 0 } = fileData(file);

        const isNotFormatFile = typeof file === 'string';

        if (thumbnail) {
          return (
            <Stack
              key={key}
              component={m.div}
              {...varFade().inUp}
              alignItems="center"
              display="inline-flex"
              justifyContent="center"
              sx={{
                m: 0.5,
                width: 80,
                height: 80,
                borderRadius: 1.25,
                overflow: 'hidden',
                position: 'relative',
                border: (theme) => `solid 1px ${theme.palette.divider}`,
                ...sx,
              }}
            >
              <FileThumbnail
                tooltip
                imageView
                file={file}
                sx={{ position: 'absolute' }}
                imgSx={{ position: 'absolute' }}
              />

              {onRemove && (
                <IconButton
                  size="small"
                  onClick={() => onRemove(file)}
                  sx={{
                    top: 4,
                    right: 4,
                    p: '1px',
                    position: 'absolute',
                    color: (theme) => alpha(theme.palette.common.white, 0.72),
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                    '&:hover': {
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                    },
                  }}
                >
                  <Iconify icon="eva:close-fill" width={16} />
                </IconButton>
              )}
            </Stack>
          );
        }

        return (
          <Box sx={{ mt: '18px !important' }}>
            <Stack
              key={key}
              component={m.div}
              {...varFade().inUp}
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                px: 1,
                py: 0.75,
                borderRadius: 0.75,
                border: (theme) => `solid 1px ${theme.palette.divider}`,
                ...sx,
              }}
            >
              <FileThumbnail file={file} />

              <Stack flexGrow={1} sx={{ minWidth: 0 }}>
                <Typography variant="subtitle2" noWrap>
                  {isNotFormatFile ? file : name}
                </Typography>

                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {isNotFormatFile ? '' : fData(size)}
                </Typography>
              </Stack>
              {

              }

              {onView &&(
                <IconButton
                  disabled={disabled}
                  edge="end"
                  size="small"
                  onClick={() => onView(file)}
                >
                  <Iconify icon="eva:eye-fill" />
                </IconButton>
              )}

              {onDownload && (
                <IconButton
                  disabled={disabled}
                  edge="end"
                  size="small"
                  onClick={() => onDownload(file)}
                >
                  <Iconify icon="material-symbols:download" />
                </IconButton>
              )}

              {onRemove && (
                <IconButton
                  disabled={disabled}
                  edge="end"
                  size="small"
                  onClick={() => onRemove(file)}
                >
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              )}
            </Stack>
          </Box>
        );
      })}
    </AnimatePresence>
  );
}
