import { useNavigate } from 'react-router-dom';
// @mui
import { Typography, ListItemText, ListItemButton, IconButton, MenuItem } from '@mui/material';
import useActiveLink from 'src/hooks/useActiveLink';
import { PATH_MARKETING } from 'src/routes/paths';
import Iconify, { IconifyProps } from 'src/components/iconify';
import { ICON } from 'src/config-global';
import MenuPopover from 'src/components/menu-popover';
import { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

const LABEL_ICONS = {
  all: 'eva:email-fill',
  inbox: 'eva:inbox-fill',
  trash: 'eva:trash-2-outline',
  drafts: 'eva:file-fill',
  spam: 'ic:round-report',
  sent: 'ic:round-send',
  starred: 'eva:star-fill',
  important: 'ic:round-label-important',
  id_social: 'ic:round-label',
  id_promotions: 'ic:round-label',
  id_forums: 'ic:round-label',
};

// ----------------------------------------------------------------------

export type INavItem = {
  id: string;
  type: string;
  icon?: string;
  name: string;
  unreadCount?: number;
  color?: string;
  count?: number;
  actions?: boolean;
  actionsMenuItems?: any[];
};

type Props = {
  item: INavItem;

  selectedLabel: string;
  setSelectedLabel: (label: string) => void;
  onClick?: VoidFunction;
};

export default function DrawerNavItem({
  item,
  selectedLabel,
  setSelectedLabel,
  onClick,
  ...other
}: Props) {
  const active = selectedLabel === item.name;

  const handleClick = () => {
    setSelectedLabel(item.name);
    if (onClick) {
      onClick();
    }
    // navigate(linkTo(label));
  };

  const isUnread = item.unreadCount !== 0;
  const popover = usePopover();
  const labelIcon = (LABEL_ICONS as Record<string, IconifyProps>)[item.id];

  return (
    <ListItemButton
      onClick={handleClick}
      sx={{
        paddingLeft: 3,
        height: 48,
        typography: 'body2',
        color: 'text.secondary',
        textTransform: 'capitalize',
        ...(active && {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightMedium',
        }),
      }}
      {...other}
    >
      {item.icon && (
        <Iconify
          icon={item.icon}
          sx={{
            mr: 2,
            width: ICON.NAV_ITEM,
            height: ICON.NAV_ITEM,
            color: item.color,
          }}
        />
      )}
      <ListItemText
        disableTypography
        primary={item.count ? `${item.name} (${item.count})` : item.name}
      />

      {isUnread && <Typography variant="caption">{item.unreadCount}</Typography>}
      {item.actions && (
        <>
          <IconButton
            onClick={(event) => {
              //   event.stopPropagation();
              if (popover) {
                popover.onOpen(event);
              }
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
          <MenuPopover
            open={popover.open}
            onClose={() => {
              popover.onClose();
            }}
          >
            {item.actionsMenuItems?.map((menuItem) => (
              <MenuItem
                sx={menuItem.sx}
                onClick={() => {
                  popover.onClose();
                  menuItem.onClick();
                }}
              >
                <Iconify icon={menuItem.icon} />
                {menuItem.name}
              </MenuItem>
            ))}
          </MenuPopover>
        </>
      )}
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

const linkTo = (label: INavItem) => {
  const baseUrl = PATH_MARKETING.pages.abtesting;

  if (label.type === 'system') {
    return `${baseUrl}/${label.id}`;
  }

  if (label.type === 'custom') {
    return `${baseUrl}/label/${label.name}`;
  }

  return baseUrl;
};
