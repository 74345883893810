// ----------------------------------------------------------------------

import { Drawer, List, Tab, Tabs, Typography } from '@mui/material';
import { Box, Stack, minHeight } from '@mui/system';
import Scrollbar from 'src/components/scrollbar';
import { SkeletonMailNavItem } from 'src/components/skeleton';
import { NAV } from 'src/config-global';
import useResponsive from 'src/hooks/useResponsive';
import DrawerNavItem from './DrawerNavItem';
import { useState } from 'react';
import TabList from '@mui/lab/TabList/TabList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0, mt: 3 }}>{children}</Box>}
    </div>
  );
}

export default function ContentGenerationSettingsNav() {
  const [contentGroupSettingsTabIndex, setContentGroupSettingsTabIndex] = useState(0);
  const [audienceGroupSettingsTabIndex, setAudienceGroupSettingsTabIndex] = useState(0);
  const [brandGroupSettingsTabIndex, setBrandGroupSettingsTabIndex] = useState(0);
  const isDesktop = useResponsive('up', 'md');
  const [selectedSettingGroup, setSelectedSettingGroup] = useState<string>('Content');
  const items: any = [
    {
      id: 'content',
      type: 'content',
      name: 'Content',
      unreadCount: 0,
      color: 'primary',
    },
    {
      id: 'feedback',
      type: 'feedback',
      name: 'Feedback Prompt',
      unreadCount: 0,
      color: 'primary',
    },
    {
      id: 'style',
      type: 'style',
      name: 'Style',
      unreadCount: 0,
      color: 'primary',
    },
    {
      id: 'company',
      type: 'company',
      name: 'Company',
      unreadCount: 0,
      color: 'primary',
    },
    {
      id: 'product',
      type: 'product',
      name: 'Product',
      unreadCount: 0,
      color: 'primary',
    },
    {
      id: 'audience',
      type: 'audience',
      name: 'Audience',
      unreadCount: 0,
      color: 'primary',
    },
    {
      id: 'engagement',
      type: 'engagement',
      name: 'Personalization & Engagement',
      unreadCount: 0,
      color: 'primary',
    },
    {
      id: 'image',
      type: 'image',
      name: 'Image',
      unreadCount: 0,
      color: 'primary',
    },
    {
      id: 'legalCompliance',
      type: 'legalCompliance',
      name: 'Compliance & Legal',
      unreadCount: 0,
      color: 'primary',
    },
    {
      id: 'integrations',
      type: 'integrations',
      name: 'Integrations',
      unreadCount: 0,
      color: 'primary',
    },
    {
      id: 'signature',
      type: 'signature',
      name: 'Signature',
      unreadCount: 0,
      color: 'primary',
    },
  ];

  const renderContentGenerationSetting = (
    <>
      <Scrollbar>
        <List disablePadding sx={{ minHeight: 300 }}>
          {items.map((label: any, index: number) =>
            label ? (
              <DrawerNavItem
                key={label.id}
                item={label}
                selectedLabel={selectedSettingGroup}
                setSelectedLabel={setSelectedSettingGroup}
              />
            ) : (
              <SkeletonMailNavItem key={index} />
            )
          )}
        </List>
      </Scrollbar>
    </>
  );

  const contentGroupSettings = (
    <>
      <Box sx={{ p: 2, width: '100%' }}>
        <Tabs
          variant="fullWidth"
          value={contentGroupSettingsTabIndex}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setContentGroupSettingsTabIndex(newValue);
          }}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="General" />
          <Tab label="Tone" />
          {/* <Tab label="SEO" /> */}
          <Tab label="Rephrase" />
          <Tab label="Summarize" />
          <Tab label="Expand" />
          <Tab label="Call To Action" />
          <Tab label="Exclusion" />
        </Tabs>
        <TabPanel value={contentGroupSettingsTabIndex} index={0}>
          <Typography variant="h6" gutterBottom>
            Content General
          </Typography>
        </TabPanel>
      </Box>
    </>
  );

  const styleGroupSettings = (
    <>
      <Box sx={{ p: 2, width: '100%' }}>
        <Tabs
          variant="fullWidth"
          value={contentGroupSettingsTabIndex}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setContentGroupSettingsTabIndex(newValue);
          }}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Typography" />
          <Tab label="Background" />
          <Tab label="Spacing" />
          <Tab label="Buttons & Links" />
          <Tab label="Accessibility" />
        </Tabs>
        <TabPanel value={contentGroupSettingsTabIndex} index={0}>
          <Typography variant="h6" gutterBottom>
            Content General
          </Typography>
        </TabPanel>
      </Box>
    </>
  );

  const audienceGroupSettings = (
    <>
      <Box sx={{ p: 2, width: '100%' }}>
        <Tabs
          variant="fullWidth"
          value={audienceGroupSettingsTabIndex}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setAudienceGroupSettingsTabIndex(newValue);
          }}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Customer Profile" />
          <Tab label="Region" />
          <Tab label="Customer Relationship" />
        </Tabs>
        <TabPanel value={audienceGroupSettingsTabIndex} index={0}>
          <Typography variant="h6" gutterBottom>
            Audience General
          </Typography>
        </TabPanel>
      </Box>
    </>
  );

  const feedbackGroupSettings = (
    <>
      <Box sx={{ p: 2, width: '100%' }}>
        <Tabs
          variant="fullWidth"
          value={audienceGroupSettingsTabIndex}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setAudienceGroupSettingsTabIndex(newValue);
          }}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Feedback Prompt" />
        </Tabs>
        <TabPanel value={audienceGroupSettingsTabIndex} index={0}>
          <Typography variant="h6" gutterBottom>
          Feedback Prompt General
          </Typography>
        </TabPanel>
      </Box>
    </>
  );

  const imageGroupSettings = (
    <>
      <Box sx={{ p: 2, width: '100%' }}>
        <Tabs
          variant="fullWidth"
          value={audienceGroupSettingsTabIndex}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setAudienceGroupSettingsTabIndex(newValue);
          }}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Image Generation" />
          <Tab label="Image Enhancer" />
        </Tabs>
        <TabPanel value={audienceGroupSettingsTabIndex} index={0}>
          <Typography variant="h6" gutterBottom>
            Audience General
          </Typography>
        </TabPanel>
      </Box>
    </>
  );

  const complianceGroupSettings = (
    <>
      <Box sx={{ p: 2, width: '100%' }}>
        <Tabs
          variant="fullWidth"
          value={audienceGroupSettingsTabIndex}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setAudienceGroupSettingsTabIndex(newValue);
          }}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Legal" />
        </Tabs>
        <TabPanel value={audienceGroupSettingsTabIndex} index={0}>
          <Typography variant="h6" gutterBottom>
            Audience General
          </Typography>
        </TabPanel>
      </Box>
    </>
  );

  const companyGroupSettings = (
    <>
      <Box sx={{ p: 2, width: '100%' }}>
        <Tabs
          variant="fullWidth"
          value={brandGroupSettingsTabIndex}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setBrandGroupSettingsTabIndex(newValue);
          }}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Brand" />
          <Tab label="Company Info" />
        </Tabs>
        <TabPanel value={audienceGroupSettingsTabIndex} index={0}>
          <Typography variant="h6" gutterBottom>
            Brand General
          </Typography>
        </TabPanel>
      </Box>
    </>
  );
  const productGroupSettings = (
    <>
      <Box sx={{ p: 2, width: '100%' }}>
        <Tabs
          variant="fullWidth"
          value={brandGroupSettingsTabIndex}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setBrandGroupSettingsTabIndex(newValue);
          }}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Product/Service Info" />
        </Tabs>
        <TabPanel value={audienceGroupSettingsTabIndex} index={0}>
          <Typography variant="h6" gutterBottom>
            Product Info
          </Typography>
        </TabPanel>
      </Box>
    </>
  );

  const engangementGroupSettings = (
    <>
      <Box sx={{ p: 2, width: '100%' }}>
        <Tabs
          variant="fullWidth"
          value={brandGroupSettingsTabIndex}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setBrandGroupSettingsTabIndex(newValue);
          }}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Personalization" />
          <Tab label="Promotional Content" />
          <Tab label="Testimonial/Reviews" />
        </Tabs>
        <TabPanel value={audienceGroupSettingsTabIndex} index={0}>
          <Typography variant="h6" gutterBottom>
            Engangement & Personalization General
          </Typography>
        </TabPanel>
      </Box>
    </>
  );

  return isDesktop ? (
    <>
      <Stack direction="row" spacing={2}>
        <Drawer
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV.W_BASE,
              position: 'relative',
            },
          }}
        >
          {renderContentGenerationSetting}
        </Drawer>
        {selectedSettingGroup === 'Content' && contentGroupSettings}
        {selectedSettingGroup === 'Style' && styleGroupSettings}
        {selectedSettingGroup === 'Company' && companyGroupSettings}
        {selectedSettingGroup === 'Product' && productGroupSettings}

        {selectedSettingGroup === 'Audience' && audienceGroupSettings}
        {selectedSettingGroup === 'Feedback' && feedbackGroupSettings}
        {selectedSettingGroup === 'Personalization & Engagement' && engangementGroupSettings}
        {selectedSettingGroup === 'Image' && imageGroupSettings}
        {selectedSettingGroup === 'Compliance & Legal' && complianceGroupSettings}
      </Stack>
    </>
  ) : (
    <Drawer
      open
      ModalProps={{ keepMounted: true }}
      PaperProps={{
        sx: {
          width: NAV.W_BASE,
        },
      }}
    >
      {renderContentGenerationSetting}
    </Drawer>
  );
}
