// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import './utils/mapboxgl';
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// redux
import { store, persistor } from './redux/store';
// routes
import Routes from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { StyledChart } from './components/chart';
import SnackbarProvider from './components/snackbar';
import ScrollToTop from './components/scroll-to-top';
import { MotionLazyContainer } from './components/animate';
import { ThemeSettings, SettingsProvider } from './components/settings';
import SignalRProvider from './components/signalR/SignalRProvider';
import { PubSubProvider } from './components/pub-sub/PubSubProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import WelcomeDialog from './pages/welcome/WelcomeDialog';

// Check our docs
// https://docs.minimals.cc/authentication/ts-version

// import { AuthProvider } from './auth/Auth0Context';
// import { AuthProvider } from './auth/FirebaseContext';
// import { AuthProvider } from './auth/AwsCognitoContext';

// ----------------------------------------------------------------------

export default function App() {
  return (
    // <AuthProvider>
    <PubSubProvider>
      <GoogleOAuthProvider clientId="711251491837-9lhaoi7jmc17fhiof312vjcqhlsgatn4.apps.googleusercontent.com">
        <HelmetProvider>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider>
                  <BrowserRouter>
                    <ScrollToTop />
                    <MotionLazyContainer>
                      <ThemeProvider>
                        <SnackbarProvider>
                          <ThemeSettings>
                            <ThemeLocalization>
                              <SignalRProvider />
                              <StyledChart />
                              <WelcomeDialog />

                              <Routes />
                            </ThemeLocalization>
                          </ThemeSettings>
                        </SnackbarProvider>
                      </ThemeProvider>
                    </MotionLazyContainer>
                  </BrowserRouter>
                </SettingsProvider>
              </LocalizationProvider>
            </PersistGate>
          </ReduxProvider>
        </HelmetProvider>
      </GoogleOAuthProvider>
    </PubSubProvider>
    //  </AuthProvider>
  );
}
