import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IFilesRepoState } from 'src/@types/files-repo';
// @types
import {
  FilesRepoApi,
  FilesRepoFileLogic,
  FilesRepoLogic,
} from 'src/api';

// ----------------------------------------------------------------------

const initialState: IFilesRepoState = {
  filesRepos: [],
  filesRepoFiles: [],
  selectedFilesRepo: null,
  selectedFilesRepoFile: null,
  error: null,
  filesRepoFilesLoading: false,
  filesReposLoading: false,
  filesReposLoaded: false,
  filesRepoFilesLoaded: false,
};

const slice = createSlice({
  name: 'expressions',
  initialState,
  reducers: {
    fethFilesRepoSuccess(state, action) {
      state.filesReposLoading = false;
      state.filesReposLoaded = true;
      state.filesRepos = action.payload;
    },

    updateFilesReposSuccess(state, action) {
      state.filesReposLoading = false;
      state.filesReposLoaded = true;
      state.filesRepos = state.filesRepos.map((filesRepo) => {
        if (filesRepo.id === action.payload.id) {
          return action.payload;
        }
        return filesRepo;
      });
    },

    deleteFilesRepoSuccess(state, action) {
      state.filesReposLoading = false;
      state.filesReposLoaded = true;
      state.filesRepos = state.filesRepos.filter(
        (filesRepo) => filesRepo.id !== action.payload
      );
    },
    setSelectedFilesRepo(state,action){
        state.selectedFilesRepo = action.payload;
    },
    setSelectedFilesRepoFile(state,action){
        state.selectedFilesRepoFile = action.payload;
    },
    createFilesRepoSuccess(state, action) {
      state.filesReposLoading = false;
      state.filesReposLoaded = true;
      state.filesRepos = [...state.filesRepos, action.payload];
    },
    ///
    fethFilesRepoFilesSuccess(state, action) {
      state.filesRepoFilesLoading = false;
      state.filesRepoFilesLoaded = true;
      state.filesRepoFiles = action.payload;
    },

    updateFilesRepoFileSuccess(state, action) {
      state.filesRepoFilesLoading = false;
      state.filesRepoFilesLoaded = true;
      state.filesRepoFiles = state.filesRepoFiles.map((filesRepoFile) => {
        if (filesRepoFile.id === action.payload.id) {
          return action.payload;
        }
        return filesRepoFile;
      });
    },

    deleteFilesRepoFileSuccess(state, action) {
      state.filesRepoFilesLoading = false;
      state.filesRepoFilesLoaded = true;
      state.filesRepoFiles = state.filesRepoFiles.filter(
        (filesRepoFile) => filesRepoFile.id !== action.payload
      );
    },

    createFilesRepoFileSuccess(state, action) {
      state.filesRepoFilesLoading = false;
      state.filesRepoFilesLoaded = true;
      state.filesRepoFiles = [...state.filesRepoFiles, action.payload];
    },

    // HAS ERROR
    hasError(state, action) {
      state.filesReposLoading = false;
      state.filesReposLoaded = false;
      state.error = action.payload;
    },
  },
});

// Actions
export const {
   setSelectedFilesRepoFile,
   setSelectedFilesRepo,
    hasError,
  } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchFilesRepos() {
  return async (dispatch: Dispatch) => {
    try {
      const filesRepoApi = new FilesRepoApi();
      const response = await filesRepoApi.apiFilesRepoGet();
      dispatch(slice.actions.fethFilesRepoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// finish crud
export function createFilesRepo(filesRepo: FilesRepoLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const filesRepoApi = new FilesRepoApi();
      const response = await filesRepoApi.apiFilesRepoPost(filesRepo);
      enqueueSnackbar('Files Repo created successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      dispatch(slice.actions.createFilesRepoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error creating Files Repo', { variant: 'error' });
    }
  };
}

export function updateFilesRepo(filesRepo: FilesRepoLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const filesRepoApi = new FilesRepoApi();
      const response = await filesRepoApi.apiFilesRepoPut(filesRepo)
      dispatch(slice.actions.updateFilesReposSuccess(filesRepo));
      enqueueSnackbar('Files Repo updated successfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error updating Files Repo', { variant: 'error' });
    }
  };
}

export function deleteFilesRepo(filesRepoId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
        const filesRepoApi = new FilesRepoApi();
      await filesRepoApi.apiFilesRepoFilesRepoIdDelete(filesRepoId);
      dispatch(slice.actions.deleteFilesRepoSuccess(filesRepoId));
      enqueueSnackbar('Files Repo deleted successfully', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Error deleting Files Repo', { variant: 'error' });
    }
  };
}

export function fetchFilesRepoFiles(filesRepoId: number) {
    return async (dispatch: Dispatch) => {
      try {
        const filesRepoApi = new FilesRepoApi();
        const response = await filesRepoApi.apiFilesRepoFilesFilesRepoIdGet(filesRepoId);
        dispatch(slice.actions.fethFilesRepoFilesSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  // finish crud
  export function createFilesRepoFile(filesRepoFile: FilesRepoFileLogic, enqueueSnackbar: any) {
    return async (dispatch: Dispatch) => {
      try {
        const filesRepoApi = new FilesRepoApi();
        const response = await filesRepoApi.apiFilesRepoFilePost(filesRepoFile);
        enqueueSnackbar('File added successfully', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        dispatch(slice.actions.createFilesRepoSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        enqueueSnackbar('Error adding file', { variant: 'error' });
      }
    };
  }
  
  export function updateFilesRepoFile(filesRepoFile: FilesRepoFileLogic, enqueueSnackbar: any) {
    return async (dispatch: Dispatch) => {
      try {
        const filesRepoApi = new FilesRepoApi();
        const response = await filesRepoApi.apiFilesRepoFilePut(filesRepoFile)
        dispatch(slice.actions.updateFilesRepoFileSuccess(filesRepoFile));
        enqueueSnackbar('File updated successfully', { variant: 'success' });
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        enqueueSnackbar('Error updating File', { variant: 'error' });
      }
    };
  }
  
  export function deleteFilesRepoFile(filesRepoFileId: number, enqueueSnackbar: any) {
    return async (dispatch: Dispatch) => {
      try {
          const filesRepoApi = new FilesRepoApi();
        await filesRepoApi.apiFilesRepoFileFilesRepoFileIdDelete(filesRepoFileId);
        dispatch(slice.actions.deleteFilesRepoFileSuccess(filesRepoFileId));
        enqueueSnackbar('File deleted successfully', { variant: 'success' });
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        enqueueSnackbar('Error deleting File', { variant: 'error' });
      }
    };
  }
  


