import { Dialog, DialogTitle, DialogContent, IconButton, Stack } from '@mui/material';
import { useRef } from 'react';
import { HomePricingPlans } from 'src/sections/home';
import PricingPage from './PricingPage';
import Iconify from '../iconify';

//
interface Props {
  onClose: () => void;
  open: boolean;
}
export default function PricingDialog({ open, onClose }: Props) {
  const handleClose = () => {
    onClose();
  };
  const dialogRootRef = useRef();
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={() => {
          handleClose();
        }}
        ref={dialogRootRef as any}
      >
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <span> Kuverto&lsquo;s Pricing</span>
            <IconButton onClick={onClose}>
              <Iconify icon="material-symbols:close" width={20} height={20} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <PricingPage />
          {/* <HomePricingPlans /> */}
        </DialogContent>
      </Dialog>
    </>
  );
}
