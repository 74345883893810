import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { GoogleLogin } from '@react-oauth/google';

// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import RouterLink from 'src/components/router/router-link';
import { PATH_AUTH, PATH_MARKETING } from 'src/routes/paths';
import authService from 'src/auth/AuthorizeService';
import { getReturnUrl, setSession } from 'src/auth/utils';
import { AccountApi } from 'src/api';
import { Alert, Divider } from '@mui/material';
import { useDispatch } from 'src/redux/store';
import { loginCallbackSuccess } from 'src/redux/slices/auth';
import { InitStore } from 'src/utils/storeUtils';
import { useNavigate } from 'react-router';
import { usePubSub } from 'src/components/pub-sub/PubSubProvider';
import { BaseAPI } from 'src/api/base';
import { useState } from 'react';
import { LoginResult } from 'src/common/enums/login-result.enum';

// ----------------------------------------------------------------------

export default function ModernLoginView() {
  const password = useBoolean();
  const [logginIn, setLoggingIn] = useState<boolean>(false);
  const pubSub = usePubSub();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    afterSubmit: null,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    // try {
    //   await new Promise((resolve) => setTimeout(resolve, 500));
    //   console.info('DATA', data);
    // } catch (error) {
    //   console.error(error);
    // }
    try {
      //  await login(data.email, data.password);
      const authServ = authService;
      const redirectUrl = getReturnUrl();
      const result = await authServ.signIn(redirectUrl);
      setLoggingIn(true);
      const accountApi = new AccountApi();
      const response = await accountApi.apiAccountLoginPost(
        {
          email: data.email,
          password: data.password,
        },
        { withCredentials: true }
      );
      try {
        if (response.data === LoginResult.EmailNotVerified) {
          
          setLoggingIn(false);
          window.location.href = `${process.env.REACT_APP_WEBAPP_URL}/auth/verify?email=${data.email}`;
          return;
        } else if (response.data === LoginResult.LoginSuccess) {
          window.location.href = result.state;
        }
      } catch (err) {
        setLoggingIn(false);
      }
    } catch (error) {
      console.error(error);
      reset();
      setLoggingIn(false);
      setError('afterSubmit', {
        ...error,
        message: error.response.data,
      });
    }
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">Sign in to Kuverto</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2">New user?</Typography>

        <Link component={RouterLink} href={PATH_AUTH.register} variant="subtitle2">
          Create an account
        </Link>
      </Stack>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      <RHFTextField name="email" label="Email address" />
      <RHFTextField
        name="password"
        label="Password"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Link
        component={RouterLink}
        href={PATH_AUTH.forgotPassword}
        variant="body2"
        color="inherit"
        underline="always"
        sx={{ alignSelf: 'flex-end' }}
      >
        Forgot password?
      </Link>
      <LoadingButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="outlined"
        loading={isSubmitting || logginIn}
        endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
      >
        Login
      </LoadingButton>
      <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        OR
      </Divider>
      <Stack alignItems="center" alignSelf="center" spacing={2}>
        <GoogleLogin
          auto_select
          onSuccess={async (credentialResponse) => {
            setLoggingIn(true);
            // Extract the ID token from the Google response
            const idToken = credentialResponse.credential;
            const accountApi = new AccountApi();
            const result: any = await accountApi.apiAccountGooglePost({
              token: idToken,
            });

            setSession(result.data.access_token);
            window.location.href = `${process.env.REACT_APP_WEBAPP_URL}`;
            // const api = BaseAPI.getInstance();
            // api.handleTokenUpdate(result.data.access_token);
            // InitStore(dispatch);

            //    pubSub.publish('login_success', result.data.access_token);
            // setTimeout(() => {
            // window.location.href = "https://localhost:8080/dashboard" as string;
            // }, 0);
            //   navigate(PATH_MARKETING.root, { replace: true });
          }}
          onError={() => {
            setLoggingIn(false);
          }}
          useOneTap
        />
      </Stack>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}
    </FormProvider>
  );
}
