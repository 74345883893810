import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { EmailProviderApi } from 'src/api';
import { ISegmentState } from 'src/@types/segments';

// ----------------------------------------------------------------------

const initialState: ISegmentState = {
  segments: {},
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'segments',
  initialState,
  reducers: {
    // START LOADING
    startFetching(state) {
      state.loading = true;
    },
    fetchSegmentSuccess(state, action) {
      state.loading = false;
      if (!state.segments[action.payload.provider]) {
        state.segments[action.payload.provider] = {};
      }
      if (!state.segments[action.payload.provider][action.payload.listId]) {
        state.segments[action.payload.provider][action.payload.listId] = [];
      }
      state.segments[action.payload.provider][action.payload.listId] = action.payload.segments;
      state.loaded = true;
    },
    deleteSegment(state, action) {
      state.segments[action.payload.provider][action.payload.listId] = state.segments[
        action.payload.provider
      ][action.payload.listId].filter((segment) => segment.id !== action.payload);
    },
    deleteSegmentsSuccess(state, action) {
      
      state.segments[action.payload.provider][action.payload.listId] = state.segments[
        action.payload.provider
      ][action.payload.listId].filter((segment) => !action.payload.segmentsId.includes(segment.id));
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchSegments(provider: string, listId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetching());
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderGetSegmentsGet(provider, listId);
      // dispatch(slice.actions.fetchSegmentSuccess({ provider, listId, segments: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteSegments(
  segmentsIds: number[],
  provider: string,
  listId: string,
  enqueueSnackbar: any
) {
  return async (dispatch: Dispatch) => {
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderSegmentsDelete({
      //   providerName: provider,
      //   listId,
      //   segmentsIds,
      // });
      enqueueSnackbar('Segments were deleted succesfully', { variant: 'success' });
      dispatch(slice.actions.deleteSegmentsSuccess({ segmentsIds, provider, listId }));
    } catch (error) {
      enqueueSnackbar('Error deleting segments', { variant: 'error' });
      dispatch(slice.actions.hasError(error));
    }
  };
}
