export class PubSubService {
    
    subscribers: { [key: string]: any[] };

    constructor() {
        this.subscribers = {};
    }

    subscribe(event: string | number, callback: any) {
        if (!this.subscribers[event]) {
            this.subscribers[event] = [];
        }
        const index = this.subscribers[event].push(callback) - 1;

        // Return an unsubscribe function
        return () => {
            this.subscribers[event].splice(index, 1);
        };
    }
  
    publish(event: string, data: any) {
      if (this.subscribers[event]) {
        this.subscribers[event].forEach(callback => {
          callback(data);
        });
      }
    }
  }
  
  // Create a singleton instance
  const pubSub = new PubSubService();
  export default pubSub;