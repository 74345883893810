import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {  useDispatch, useSelector } from 'src/redux/store';
import { EmailTemplate, TemplateType } from 'src/@types/email-template';
import { EmailTemplateApi, TemplateApi } from 'src/api';
import { useSnackbar } from 'src/components/snackbar';
import { updateEmailTemplate } from 'src/redux/slices/emailTemplates';

interface Props {
  onClose: () => void;
  open: boolean;
}

export default function RenameTemplateDialog({ onClose, open }: Props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const templates = useSelector((state) => state.emailTemplates.templates);
  const storeSelectedTemplateId = useSelector((state) => state.campaignForms.selectedTemplate?.id);
  const [renamingTemplate, setRenamingTemplate] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState<EmailTemplate | undefined>(
    storeSelectedTemplateId
      ? templates.find((template) => template.id === storeSelectedTemplateId)
      : undefined
  );

  const renameTemplate = async () => {
    if (!selectedTemplate) {
      return;
    }
    if (selectedTemplate.type === TemplateType.Saved) {
      const api = new EmailTemplateApi();
      setRenamingTemplate(true);
      await api.apiEmailTemplateRenameTemplatePut({
        templateId: selectedTemplate?.id,
        name: selectedTemplate?.name as string,
      });
    }
    dispatch(updateEmailTemplate({ ...selectedTemplate }));
    enqueueSnackbar('Rename template success!');
    setRenamingTemplate(false);
    onClose();
  };

  React.useEffect(() => {
    const selTemplate = storeSelectedTemplateId
      ? templates.find((template) => template.id === storeSelectedTemplateId)
      : undefined;
    setSelectedTemplate(selTemplate);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Save Template</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          value={selectedTemplate?.name}
          onChange={(e) => setSelectedTemplate({ ...selectedTemplate, name: e.target.value })}
          label="Template Name"
          type="email"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={renamingTemplate} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={renamingTemplate} onClick={renameTemplate}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
