// utils

import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ISettingsState } from 'src/@types/settings';
import { AccountOnboardingApi } from 'src/api';

// ----------------------------------------------------------------------

const initialState: ISettingsState = {
  error: null,
  isOpen: false,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // Toggle Settings
    toggleSettings(state, action) {
      state.isOpen = action.payload.isOpen;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { toggleSettings } = slice.actions;


