import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { CraftApi, FolderApi, FolderLogic, LanguageApi } from 'src/api';
import { ILanguageState } from 'src/@types/language';
import { IFolderState } from 'src/@types/folders';
import _ from 'lodash';

// ----------------------------------------------------------------------

const initialState: IFolderState = {
  folders: [],
  editMode: false,
  selectedFolder: null,
  loaded: false,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    // START LOADING
    fetchFoldersSuccess(state, action) {
      state.folders = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    setSelectedFolder(state, action) {
      state.selectedFolder = action.payload;
    },
    setSelectedFolderByName(state, action) {
      state.selectedFolder = state.folders.find((folder) => folder.name === action.payload) ?? null;
    },
    saveFolderSuccess(state, action) {
      const exist = state.folders.find((folder) => folder.id === action.payload.id);
      if (exist) {
        const index = state.folders.indexOf(exist);
        state.folders[index] = action.payload;
      } else {
        state.folders.push(action.payload);
      }
      state.loaded = true;
      state.loading = false;
    },
    setEditMode(state, action) {
      state.editMode = action.payload;
    },
    deleteFolderSuccess(state, action) {
      state.folders = state.folders.filter((folder) => folder.id !== action.payload);
      state.loaded = true;
      state.loading = false;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedFolder, setSelectedFolderByName, setEditMode } = slice.actions;

// ----------------------------------------------------------------------

export function fetchFolders() {
  return async (dispatch: Dispatch) => {
    try {
      const folderApi = new FolderApi();
      dispatch(slice.actions.setLoading(true));
      const response = await folderApi.apiFolderFoldersGet();
      dispatch(slice.actions.fetchFoldersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createFolder(folder: FolderLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const folderApi = new FolderApi();
      dispatch(slice.actions.setLoading(true));
      const response = await folderApi.apiFolderFolderPost({ folder });
      dispatch(slice.actions.saveFolderSuccess(response.data));
      enqueueSnackbar('Create Folder Success!', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Create Folder Failed!', { variant: 'error' });
    }
  };
}

export function updateFolder(folder: FolderLogic, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const folderApi = new FolderApi();
      dispatch(slice.actions.setLoading(true));
      const response = await folderApi.apiFolderFolderPut({ folder });
      dispatch(slice.actions.saveFolderSuccess(folder));
      enqueueSnackbar('Update Folder Success!', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Update Folder Failed!', { variant: 'error' });
    }
  };
}

export function deleteFolder(folderId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const folderApi = new FolderApi();
      dispatch(slice.actions.setLoading(true));
      const response = await folderApi.apiFolderFolderIdDelete(folderId);
      dispatch(slice.actions.deleteFolderSuccess(folderId));
      enqueueSnackbar('Delete Folder Success!', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar('Delete Folder Failed!', { variant: 'error' });
    }
  };
}
