import _ from 'lodash';

interface Props {
  url?: string;
}

export default function OfficeFileViewer({ url }: Props) {
  console.log('urlaaa', url);
  return (
      <iframe
        title='file'
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          url as string // url as string
        )}`}
        width="100%"
        height="600px"
        frameBorder="0"
      />
      
  );
}
