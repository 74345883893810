import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Stack,
  Box,
  Typography,
  Paper,
} from '@mui/material';
import { useEffect, useState } from 'react';
import PromptEditor from 'src/components/prompt-editor/PromptEditor';
import { useSnackbar } from 'src/components/snackbar';
import { useDispatch } from 'src/redux/store';
import RenderHTML from 'src/utils/renderHtml';

export interface Props {
  onClose: () => void;
  onConfirm: (content: string) => void;
  open: boolean;
  content: string;
}

export default function CodeEditorDialog({ onClose, open, content, onConfirm }: Props) {
  const [htmlContent, setHtmlContent] = useState<string>();

  useEffect(() => {
    setHtmlContent(content);
  },[content]);

  const handleCodeEditorConfirm = () => {
    
    onConfirm(htmlContent as string);
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} sx={{ zIndex: 4500 }} maxWidth="lg" fullWidth>
      <DialogTitle>Code Editor</DialogTitle>
      <DialogContent sx={{ zIndex: 4500 }}>
        <Stack direction="column" alignItems="center" spacing={3}>
          <Paper
            key=""
            variant="outlined"
            sx={{
              py: 1.5,
              mx: 1,
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              flexGrow: 1,
              height: '100%',
            }}
          >
            <PromptEditor
              height={390}
              htmlCodeEditor
              width={1000}
              promptInput={htmlContent ?? ''}
              setPromptInput={(newContent: any) => {
                setHtmlContent(newContent);
              }}
            />
          </Paper>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button  onClick={handleCodeEditorConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
