import React, { useState, useEffect, useRef } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import packageInfo from 'pdfjs-dist/package.json';

 interface Props {
    pdfUrl: string;
}

export default function PdfViewer({pdfUrl}: Props) {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const pdfjsVersion = packageInfo.version; // Get the version dynamically
    const pdfViewerRef = useRef<any>(null); // Reference to the PDF viewer container

  //  useEffect(() => {
     //   if (pdfUrl && pdfViewerRef.current) {
            // Trigger full-screen mode
      //      if (pdfViewerRef.current.requestFullscreen) {
      //          pdfViewerRef.current.requestFullscreen();
     //       } else if (pdfViewerRef.current.mozRequestFullScreen) { /* Firefox */
    //            pdfViewerRef.current.mozRequestFullScreen();
    //        } else if (pdfViewerRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    //            pdfViewerRef.current.webkitRequestFullscreen();
   //         } else if (pdfViewerRef.current.msRequestFullscreen) { /* IE/Edge */
   //             pdfViewerRef.current.msRequestFullscreen();
    //        }
    //    }
   // }, [pdfUrl]);

    
    return (
        <div ref={pdfViewerRef} style={{ height: '100%', width: '100%' }}>
            {pdfUrl ? (
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
                    <Viewer  initialPage={0} defaultScale={1} fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
                </Worker>
            ) : (
                <p>No PDF file selected</p>
            )}
        </div>
    );
}